import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {logEvent, logException, logTiming } from '../analytics';


const AnalizeContext = createContext(null);

export const useAnalize = () => useContext(AnalizeContext);

export const AnalizeProvider = ({ children }) => {
    const [predefinedToken,setpredefinedToken] = useState("KurukHillAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
    const [statistics, setStatistics] = useState(null);

    const trackEvent = (User,Action,Target) => {
        logEvent(User, Action, Target, 1);
      };
    
      const trackException = (desc) => {
        logException(desc, true);
      };
    
      const trackTiming = (Target,Method,Type,start,end) => {
        logTiming(Target, Method, end - start, Type);
      };

    // Function to fetch statistics data for the current month
    const fetchStatistics = async (startDate, endDate) => {
      try {
          const response = await axios.get('https://kurukhill.hu:41808/admin/statistics', {
              params: { startDate, endDate },
              headers: {
                Authorization: `Bearer ${predefinedToken}`,
                'Content-Type': 'application/json'
              }
          });
          setStatistics(response.data);
          console.log(response.data);
      } catch (error) {
          trackException('Failed to fetch statistics: ' + error.message);
      }
    };

    // Function to update statistics for a specific field and day
    const updateStatistics = async (fieldName) => {
      try {
          const today = new Date();
          const utcDay = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())).toISOString().slice(0, 10);
          
          await axios.get('https://kurukhill.hu:41808/admin/statisticsUpdate', {
              params: { fieldName, day: utcDay },
              headers: {
                Authorization: `Bearer ${predefinedToken}`,
                'Content-Type': 'application/json'
              }
          });
          // Optionally, refetch the statistics after update
          const startDate = new Date(utcDay).toISOString().slice(0, 10);
          const endDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())).toISOString().slice(0, 10); // Current date in UTC as end date
          //fetchStatistics(startDate, endDate);
      } catch (error) {
          trackException('Failed to update statistics: ' + error.message);
      }
  };

    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().slice(0, 10);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().slice(0, 10);

        fetchStatistics(firstDayOfMonth, lastDayOfMonth);
    }, []);


    // Context value that will be passed to consumers
    const contextValue = {
        trackEvent,trackException,trackTiming,statistics,fetchStatistics,updateStatistics
    };

    return <AnalizeContext.Provider value={contextValue}>{children}</AnalizeContext.Provider>;
};