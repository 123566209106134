import React, { useEffect, useRef } from "react";
import "./style1.css";
import { useNavigate } from "react-router-dom";
import { useBase } from "../../contexts/BaseContext";
import { useLanguage } from "../../contexts/LanguageContext";
import LanguageDropdown from "../../components/LangDropdown";
import NavLinks from "../../components/NavLinks";
import LeafMap from "../../components/LeafMap";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from "../../img2/maintop.jpg";

export const GpesResolution = () => {
    const navigate = useNavigate();
    const {setIm,openPopup2,toGal,toKap,setToKap,setToGal,setToPri,setToASZF,loading} = useBase();
    const {
           LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,LangMainMerul1,LangMainMerul2,LangBookNow,LangOurHouses,LangHouseDescription,LangOurHousesDetailed,LangOurDream,LangExperience,LangGallery,LangContact,LangPhone,LangTermsAndConditions,LangPrivacyPolicy,LangImprint,LangAllRightsReserved
          ,LangCryPKitchen,LangCryPAirConditioning,LangCryPJacuzzi,LangCryPParking,LangCryPBreakfast,LangCryPWiFi,LangCryPTV} = useLanguage();
    const galeriaRef = useRef(null);
    const kapRef = useRef(null);
    const fogRef = useRef(null);
    const {updateStatistics} = useAnalize();
    let loaded = false;
    

    const containerRef = useRef(null);
  const galeriaSlideRef = useRef(null);
  const kapcsSlideRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry], observer) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target); // Unobserve after animation
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    if (galeriaSlideRef.current) {
      observer.observe(galeriaSlideRef.current);
    }
    if (kapcsSlideRef.current) {
      observer.observe(kapcsSlideRef.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      if (galeriaRef.current) {
        observer.unobserve(galeriaRef.current);
      }
      if (kapcsSlideRef.current) {
        observer.unobserve(kapcsSlideRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      document.querySelector('.herocontentwrap').classList.add('animate');
    }
  }, [loading]);
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp1");loaded = true}
    }, []);
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const {trackEvent,trackTiming} = useAnalize();
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'Main-PC', 'Initial Page Load',start,end);
      });
    }, []);

    const changeSlug = (slug) => {
        if (slug === "/") {navigate('/');trackEvent("PC","Navigated","MainPage")}
        else if(slug === "/info") {navigate('/információ');trackEvent("PC","Navigated","InfoPage")} 
        else if(slug === "/gp") {navigate('/greenpassion');trackEvent("PC","Navigated","GreenPassion")} 
        else if(slug === "/cp") {navigate('/crystalpower');trackEvent("PC","Navigated","CrystalPower")} 
        else if(slug === "/mm") {navigate('/magicmango');trackEvent("PC","Navigated","MagicMango")} 
        else if(slug === "/fog") {fogRef.current.scrollIntoView({ behavior: "smooth" });trackEvent("PC","Navigated","MainPage-Reservation")}
        else if(slug === "/gal") {galeriaRef.current.scrollIntoView({ behavior: "smooth" });trackEvent("PC","Navigated","MainPage-Galery")}
        else if(slug === "/kap") {kapRef.current.scrollIntoView({ behavior: "smooth" });trackEvent("PC","Navigated","MainPage-Contact")}
        else if(slug === "/pri") {navigate('/információ');setTimeout(() => {setToPri(true);}, 200);trackEvent("PC","Navigated","InfoPage-Prices")}
        else if(slug === "/ASZF") {navigate('/információ');setTimeout(() => {setToASZF(true);}, 50);trackEvent("PC","Navigated","InfoPage-ASZF")}    
    };
    const openIm = (n) => {
      setIm(n);
      openPopup2();
      trackEvent("Galery","Opened","Image");
      updateStatistics("imageviewed");
    }
    useEffect(() => {
      if (toGal) {galeriaRef.current.scrollIntoView({ behavior: "smooth" }); setToGal(false)}
    }, [toGal,setToGal]);
    useEffect(() => {
      if (toKap) {kapRef.current.scrollIntoView({ behavior: "smooth" }); setToKap(false)}
    }, [toKap,setToKap]);
    useEffect(() => {
      const intervalId = setInterval(() => {
        updateStatistics("pageTimep1");
      }, 30000);
      
      return () => {
        clearInterval(intervalId); // Clear interval on component unmount
      };
    }, []);
  return ( <>
    <Helmet>
      <title>KurukHill - Főoldal</title>
      <meta name="description" content="Kuruk Hill - Luxus a szőlőben." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Főoldal" />
      <meta name="twitter:description" content="Kuruk Hill - Luxus a szőlőben." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu" />
    </Helmet>
    <div className="gpes-resolution">
      <div className="div">
        <div className="hero">
          <div className="overlap-group">
          <img /*loading="lazy"*/ alt="Loading" src={mainkep} className="Change-Image-Info" style={{height:'100vh',width:"100vw",backgroundColor:"black"}}/>
            <div className="herocontentwrap">
              <div className="kuruk-hill-head">Kuruk Hill</div>
              <div className="text-wrapper">{LangMainLux()}</div>
              <p className="mer-ljetek-el-a-k">
                {LangMainMerul1()}
                <br />
                {LangMainMerul2()}
              </p>
              <div className="folaljmostgomb" onClick={() => changeSlug("/fog")}>
                <div className="text-wrapper-2">{LangBookNow()}</div>
              </div>
            </div>
            <div className="navcont">
              <img loading="lazy" src="/img3/klogo3.png" alt="Logo" className="logo" />
              <NavLinks
                changeSlug={changeSlug}
                LangMainTitStart={LangMainTitStart}
                LangMainTitGal={LangMainTitGal}
                LangMainTitCont={LangMainTitCont}
                LangMainTitPri={LangMainTitPri}
                LangMainTitRes={LangMainTitRes}
                LangMainTitInf={LangMainTitInf}
                sts={[true,false,false,false,false,false]}
              />
              <LanguageDropdown/>
            </div>
          </div>
        </div>
        <div className="hzaink">
          <div className="headingwrap">
            <div ref={containerRef} className="overlap-group-2">
              <div className="vonal" />
              <div className="h-zaink-head">{LangOurHouses()}</div>
            </div>
          </div>
          <div className="frame">
            <p className="szoveg">
              {LangHouseDescription()}
            </p>
            <p className="p">
              {LangOurHousesDetailed()}
            </p>
            <p className="p">
              {LangOurDream()}
            </p>
            <p className="p">
              {LangExperience()}
            </p>
            <div className="szolodisz">
              <div className="szolovonal" />
              <div className="szolo" />
              <div className="szolovonal" />
            </div>
          </div>
          <div className="frame-2">
            <div className="div-3" ref={fogRef}>
              <div className="cystal">
                <div className="crystalkep" onClick={() => changeSlug("/cp")}/>
                <div className="div-4">
                  <div className="crystal-power-head">Crystal Power</div>
                  <div className="ferohely">
                    <div className="text-wrapper-5">2</div>
                    <img loading="lazy" className="icon-user" alt="Icon user" src="/img/icon-user-1.png" />
                  </div>
                </div>
                <div className="frame-3">
                <div className="szimbolumok">
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Konyhaikon" src="/img/konyhaikon.svg" />
                      <div className="tooltip">{LangCryPKitchen()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Legkondiikon" src="/img/legkondiikon.svg" />
                      <div className="tooltip">{LangCryPAirConditioning()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Jacuzziikon" src="/img/jacuzziikon.svg" />
                      <div className="tooltip">{LangCryPJacuzzi()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Parkolasikon" src="/img/parkolasikon.svg" />
                      <div className="tooltip">{LangCryPParking()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Reggeliikon" src="/img/reggeliikon.svg" />
                      <div className="tooltip">{LangCryPBreakfast()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Wifiikon" src="/img/wifiikon.svg" />
                      <div className="tooltip">{LangCryPWiFi()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Tvikon" src="/img/tvikon.svg" />
                      <div className="tooltip">{LangCryPTV()}</div>
                    </div>
                  </div>
                  <img loading="lazy" className="frame-4" alt="Frame" src="/img/frame-3.svg" onClick={() => changeSlug("/cp")}/>
                </div>
              </div>
              <div className="cystal">
                <div className="crystalkep-2" onClick={() => changeSlug("/mm")}/>
                <div className="div-4">
                  <div className="crystal-power-head">Magic Mango</div>
                  <div className="ferohely">
                    <div className="text-wrapper-5">2</div>
                    <img loading="lazy" className="icon-user" alt="Icon user" src="/img/icon-user-1.png" />
                  </div>
                </div>
                <div className="frame-3">
                <div className="szimbolumok">
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Konyhaikon" src="/img/konyhaikon.svg" />
                      <div className="tooltip">{LangCryPKitchen()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Legkondiikon" src="/img/legkondiikon.svg" />
                      <div className="tooltip">{LangCryPAirConditioning()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Jacuzziikon" src="/img/jacuzziikon.svg" />
                      <div className="tooltip">{LangCryPJacuzzi()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Parkolasikon" src="/img/parkolasikon.svg" />
                      <div className="tooltip">{LangCryPParking()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Reggeliikon" src="/img/reggeliikon.svg" />
                      <div className="tooltip">{LangCryPBreakfast()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Wifiikon" src="/img/wifiikon.svg" />
                      <div className="tooltip">{LangCryPWiFi()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Tvikon" src="/img/tvikon.svg" />
                      <div className="tooltip">{LangCryPTV()}</div>
                    </div>
                  </div>
                  <img loading="lazy" className="frame-5" alt="Frame" src="/img/frame-3-1.svg" onClick={() => changeSlug("/mm")}/>
                </div>
              </div>
            </div>
            <div className="frame-wrapper">
              <div className="cystal-wrapper">
                <div className="cystal">
                  <div className="crystalkep-3" onClick={() => changeSlug("/gp")}/>
                  <div className="div-4">
                    <div className="crystal-power-head-2">Green Passion</div>
                    <div className="ferohely">
                      <div className="text-wrapper-5">2</div>
                      <img loading="lazy" className="icon-user" alt="Icon user" src="/img/icon-user-2.png" />
                    </div>
                  </div>
                  <div className="frame-3">
                  <div className="szimbolumok">
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Konyhaikon" src="/img/konyhaikon.svg" />
                      <div className="tooltip">{LangCryPKitchen()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Legkondiikon" src="/img/legkondiikon.svg" />
                      <div className="tooltip">{LangCryPAirConditioning()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Jacuzziikon" src="/img/jacuzziikon.svg" />
                      <div className="tooltip">{LangCryPJacuzzi()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Parkolasikon" src="/img/parkolasikon.svg" />
                      <div className="tooltip">{LangCryPParking()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Reggeliikon" src="/img/reggeliikon.svg" />
                      <div className="tooltip">{LangCryPBreakfast()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Wifiikon" src="/img/wifiikon.svg" />
                      <div className="tooltip">{LangCryPWiFi()}</div>
                    </div>
                    <div className="elvalasztovonal" />
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Tvikon" src="/img/tvikon.svg" />
                      <div className="tooltip">{LangCryPTV()}</div>
                    </div>
                  </div>
                    <img loading="lazy" className="frame-5" alt="Frame" src="/img/frame-3-2.svg" onClick={() => changeSlug("/gp")}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="geleriawrap">
          <div className="galeriacontainer">
            <div ref={galeriaSlideRef} className="galeriaheadingwrap">
              <div ref={galeriaRef} className="vonal-2" />
              <div className="text-wrapper-6">{LangGallery()}</div>
            </div>
            <div className="kepsorozat">
              <div className="kepcontainer" onClick={() => openIm(1)}>
                <div className="kep" onClick={() => () => openIm(1)} />
              </div>
              <div className="kep-wrapper" >
                <div className="kep-2" onClick={() => openIm(2)}/>
              </div>
            </div>
            <div className="kepsorozat-2" >
              <div className="div-wrapper" onClick={() => openIm(3)}>
                <div className="kep-3" />
              </div>
              <div className="kepcontainer-2" onClick={() => openIm(4)}>
                <div className="kep-4" />
              </div>
            </div>
            <div className="div-5">
              <div className="kepcontainer-3" onClick={() => openIm(5)}>
                <div className="kep-5" />
              </div>
              <div className="kepcontainer-4" onClick={() => openIm(6)}>
                <div className="kep-6" />
              </div>
            </div>
            <div className="kepsorozat-3">
              <div className="kepcontainer-5" onClick={() => openIm(7)}>
                <div className="kep-7" />
              </div>
              <div className="kepcontainer-6" onClick={() => openIm(8)}>
                <div className="kep-8" />
              </div>
            </div>
            <div className="div-5">
              <div className="kepcontainer-3" onClick={() => openIm(9)}>
                <div className="kep-9" />
              </div>
              <div className="kepcontainer-4" onClick={() => openIm(10)}>
                <div className="kep-10" />
              </div>
            </div>
            <div className="kepsorozat-3">
              <div className="kepcontainer-7" onClick={() => openIm(11)}>
                <div className="kep-11" />
              </div>
              <div className="kepcontainer-8" onClick={() => openIm(12)}>
                <div className="kep-12" />
              </div>
            </div>
            <div className="div-5">
              <div className="kepcontainer-3" onClick={() => openIm(13)}>
                <div className="kep-13" />
              </div>
              <div className="kepcontainer-4" onClick={() => openIm(14)}>
                <div className="kep-14" />
              </div>
            </div>
            <div className="videodiv">
              <video className="video" autoPlay loop muted>
                <source src="/vids/kvid2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="footer" ref={kapRef}>
          <div className="footercontainer">
            <div ref={kapcsSlideRef} className="footerheadingcont">
              <div className="vonal-2" />
              <div className="text-wrapper-6">{LangContact()}</div>
            </div>
            <div className="footer-2">
              <div className="footerjobbcontainer">
                <div className="footer-cuccok">
                  <div className="text-wrapper-8">Kuruk Hill</div>
                  <p className="text-wrapper-9">
                    {LangPhone()}: <a href={`tel:${"+36 70 942 0610"}`} style={{zIndex:'100',color: 'inherit', textDecoration: 'none'}}>+36 70 942 0610</a>
                  </p>
                  <p className="e-mail-info">
                    <span className="span">E-mail: </span>
                    <a href="mailto:info@kurukhill.hu" rel="noopener noreferrer" target="_blank" className="custom-link">
                      <span className="text-wrapper-10">info@kurukhill.hu</span>
                    </a>
                  </p>
                  <div className="socialmediadiv" style={{width:'300px'}} >
                    <a href="https://www.instagram.com/kuruk_hill?igsh=a3JuazRvd28yY3B6" className="w-inline-block" style={{fontSize:"0px"}}>insta<img alt="Loading" src="/img3/insta.png" href="https://www.instagram.com/" style={{height: '35px',transform:'translateY(-7px)'}}/></a>
                    <a href="https://www.facebook.com/kurukhill" className="w-inline-block" style={{fontSize:"0px"}}>facebook<img alt="Loading" src="/img3/facebook.png" href="https://www.facebook.com/" style={{height: '50px'}}/></a>
                    <a href="https://www.tiktok.com/@kuruk.hill?_t=8ndkj9hINhQ&_r=1" className="w-inline-block" style={{fontSize:"0px"}}>tiktok<img alt="Loading" src="/img3/tiktok.png" href="https://www.tiktok.com/" style={{height: '51px',transform:'translateY(1px)'}}/></a>
                  </div>
                  <p className="element-zalakaros-szent">
                    8749 Zalakaros
                    <br />
                    Szent Orbán utca 42.
                  </p>
                </div>
              </div>
              <div className="map">
                <LeafMap/>
              </div>
            </div>
          </div>
          <div className="frame-7">
            <div className="div-6">
              <div className="text-wrapper-11" onClick={() => changeSlug("/ASZF")}>{LangTermsAndConditions()}</div>
              <a href="/docs/Adatvedelmi_Tajekoztato_Kurukhill.docx" download="Adatvedelmi_Tajekoztato_Kurukhill.docx"><div className="text-wrapper-11">{LangPrivacyPolicy()}</div></a>
              <a href="/docs/Impresszum.docx" download="Impresszum.docx"><div className="text-wrapper-11">{LangImprint()}</div></a>
            </div>
          </div>
          <div className="licenszdiv">
            <p className="licensz">
              <span className="span">©2023 </span>
              <a href="https://gmamedia.co/" rel="noopener noreferrer" target="_blank" className="custom-link">
                <span className="text-wrapper-10">GMA Media|</span>
              </a>
              <span className="span"> {LangAllRightsReserved()}</span>
            </p>
          </div>
          <div className="kartyakdiv">
            <img loading="lazy" className="kartyak" alt="Kartyak" src="/img/kartyak.png" />
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default GpesResolution;