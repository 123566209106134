import React, { useState,useRef,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useBase } from "../contexts/BaseContext";
import { useLanguage } from "../contexts/LanguageContext";
import LanguageDropdown from "../components/LangDropdown";
import { useAnalize } from '../contexts/AnalizeContext';


const HambiMenu = ({changeSlug,t,r}) => {
  const {trackEvent,trackException,trackTiming} = useAnalize();
  const {openPopup,setIm,openPopup2,closePopup2,isMenuOpen, setIsMenuOpen,changeHousePic} = useBase();
  const {langId,setLangId,isOpen, setIsOpen,
    LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,LangMainMerul1,LangMainMerul2,LangBookNow,LangOurHouses,LangHouseDescription,LangOurHousesDetailed,LangOurDream,LangExperience,LangGallery,LangContact,LangPhone,LangTermsAndConditions,LangPrivacyPolicy,LangImprint,LangAllRightsReserved} = useLanguage();
  const toggleMenu = () => {
    if (isMenuOpen) {
        setIsOpen(false);
    }
    changeHousePic("CP","RES");changeHousePic("GP","RES");changeHousePic("MM","RES");
    trackEvent("Phone","Toggled","HambiMenu")
    setIsMenuOpen(!isMenuOpen);
  };

  const navigate = useNavigate();
    
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    
    const openIm = (n) => {
      setIm(n);
      openPopup2();
    }
    const changeSlugH = (slug) => {
      if (slug === "/") {changeSlug("/");trackEvent("Phone","Navigated","MainPage")}
      else if (slug === "/gal") {changeSlug("/gal");trackEvent("Phone","Navigated","MainPage-Galery")}
      else if (slug === "/kap") {changeSlug("/kap");trackEvent("Phone","Navigated","MainPage-Contact")}
      else if (slug === "/pri") {changeSlug("/pri");trackEvent("Phone","Navigated","InfoPage-Prices")}
      else if (slug === "/fog") {changeSlug("/fog");trackEvent("Phone","Navigated","Reservation")}
      else if (slug === "/info") {changeSlug("/info");trackEvent("Phone","Navigated","InfoPage")}
      setIsMenuOpen(false);
    }
  return (
    <div style={{position: "absolute",right: r,top:t}}>
        <img loading="lazy" className="hambimenu-4" alt="Hambimenu" src="/img/menu-alt-01-svgrepo-com.svg" onClick={toggleMenu}/>
      {isMenuOpen && (
        <div className="Phone-navlinkek">
          <div className="Phone-kezdolapwrap">
            <div className="Phone-navvonal-4" style={{ width: "77px" ,backgroundColor:'transparent'}} />
            <div className="Phone-text-wrapper-3" onClick={() => changeSlugH("/")}>{LangMainTitStart()}</div>
          </div>
          <div className="Phone-galeriawrap">
            <div className="Phone-navvonal-2" style={{backgroundColor:'transparent'}}/>
            <div className="Phone-text-wrapper-3" onClick={() => changeSlugH("/gal")}>{LangMainTitGal()}</div>
          </div>
          <div className="Phone-kapcsolatwrap">
            <div className="Phone-navvonal-3" style={{backgroundColor:'transparent'}}/>
            <div className="Phone-text-wrapper-3" onClick={() => changeSlugH("/kap")}>{LangMainTitCont()}</div>
          </div>
          <div className="Phone-arainkwrap">
            <div className="Phone-navvonal-3" style={{backgroundColor:'transparent'}}/>
            <div className="Phone-text-wrapper-3" onClick={() => changeSlugH("/pri")}>{LangMainTitPri()}</div>
          </div>
          <div className="Phone-foglalaswrap">
            <div className="Phone-navvonal-3" style={{backgroundColor:'transparent'}}/>
            <div className="Phone-text-wrapper-3" onClick={() => changeSlugH("/fog")}>{LangMainTitRes()}</div>
          </div>
          <div className="Phone-informaciowrap">
            <div className="Phone-navvonal-3" style={{backgroundColor:'transparent'}}/>
            <div className="Phone-text-wrapper-3" onClick={() => changeSlugH("/info")}>{LangMainTitInf()}</div>
          </div>
          <div className="Phone-informaciowrap" style={{transform: 'translate(30px,-10px)'}}>
            {!isOpen && <div className="Phone-navvonal-3" style={{backgroundColor:'transparent'}}/>}
            <LanguageDropdown/>
          </div>
        </div>
      )}
    </div>
  );
};

export default HambiMenu;