import ReactGA from 'react-ga';

const trackingId = "G-BW148L4ZSH"; // Your GA4 Measurement ID
ReactGA.initialize(trackingId);


// Oldalmegtekintés naplózása
export const logPageView = () => {
  console.log(`Logging pageview for ${window.location.pathname}`);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

// Esemény naplózása
export const logEvent = (category = '', action = '', label = '', value = 0) => {
  if (category && action) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
      value: value
    });
  }
};

// Kivétel naplózása
export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.event({
      action: 'exception',
      category: 'Error',
      label: description,
      nonInteraction: true,
      fatal: fatal,
    });
  }
};

// Időtartam mérés naplózása
export const logTiming = (category, variable, value, label) => {
  if (category && variable && value) {
    ReactGA.send({
      hitType: 'timing',
      timingCategory: category,
      timingVar: variable,
      timingValue: value,
      timingLabel: label,
    });
  }
};