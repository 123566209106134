import React, {useEffect,useState} from 'react';
import { format, getDate, getDaysInMonth} from 'date-fns';
import { Day, Segment } from '../styles/CalendarStyles';
import './style.css'
import { useBase } from '../contexts/BaseContext';
import { DayMobile, SegmentMobile } from '../styles/CalendarStylesMobile';
import { DayTablet, SegmentTablet } from '../styles/CalendarStylesTablet';

const segmentColors = {
    GreenPassion: { base: 'transparent', selected: 'rgb(93, 115, 41)',reserved : '#646464' },
    MagicMango: { base: 'transparent', selected: 'rgb(255, 191, 52)',reserved : '#646464' },
    CrystalPower: { base: 'transparent', selected: 'rgb(246, 38, 129)',reserved : '#646464' }
};

const DaySegment = ({ day, onSegmentSelect, selectedRange,selectedRange2,selectedRange3, selectedSegm, reservedDays,pMonthDays,pMonthDays2,pMonthDays3,sDays,sDays2,sDays3,eDays,eDays2,eDays3 }) => {
    const formattedDay = format(day, 'yyyy-MM-dd');
    const segmentTypes = ['GreenPassion', 'MagicMango', 'CrystalPower'];

    const GetDaySegmentColor = (isReserved,type,isSelected) =>{
        if (isSelected) {
            return segmentColors[type].selected;
        }
        if (isReserved) {
            return segmentColors[type].reserved;
        }
        
        return segmentColors[type].base;
    }
    const GetSelRange = (type) =>{
        if (type === "GreenPassion") {
            return selectedRange;
        }
        if (type === "MagicMango") {
            return selectedRange2;
        }
        if (type === "CrystalPower") {
            return selectedRange3;
        }
    }
    const isDateInList = (date, list) => {
        return list.includes(date);
    };
    // Helper function to adjust the date correctly
    const adjustDate = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return format(result, 'yyyy-MM-dd');
    };

    const screenHeight = window.innerHeight+1000;
    const aspectRatio = screenHeight / 3250;    
    const {device,setDevice} = useBase(); 
    useEffect(() => {
        const width = window.innerWidth;
        if (width >= 768/*1024*/) {  // Assuming 1024px and above is a desktop
            setDevice("PC");
        }/* else if (width >= 768) {  // Assuming between 768px and 1023px is a tablet
            setDevice("TAB");
        }*/ else {
            setDevice("PHONE");
        }      
    }, [window.innerWidth]); 
    if (device === "PC") {
        return (
            <Day aspectRatio= {aspectRatio}>
                <div className='daylabel'>{format(day, 'd')}</div>
                {segmentTypes.map(type => {
                    const segmentId = `${formattedDay}-${type}`;
                    const currentDate = new Date();
                    const currentHour = currentDate.getHours();
                    const currentDateNoTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    const formattedDayDate = new Date(formattedDay);
                    const formattedDayNoTime = new Date(formattedDayDate.getFullYear(), formattedDayDate.getMonth(), formattedDayDate.getDate());
                    const isPastDate = formattedDayNoTime < currentDateNoTime || (formattedDayNoTime.getTime() === currentDateNoTime.getTime() && currentHour >= 12);
                    const isReserved = reservedDays[type].includes(formattedDay) || isPastDate;
                    const selrange = GetSelRange(type);

                    const prevDay = adjustDate(day, -1); // Előző nap
                    const nextDay = adjustDate(day, 1); // Következő nap

                    const isPrevReserved = isDateInList(prevDay, reservedDays[type]);
                    const isNextReserved = isDateInList(nextDay, reservedDays[type]);

                    const dayOfMonth = getDate(day);
                    const daysInMonth = getDaysInMonth(day);

                    let previousAndNextMonthDays = [true,false];
                    if (type === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
                    if (type === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
                    if (type === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
                    const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

                    // Determine isStart2 and isEnd2 with the original logic
                    let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
                    let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

                    // Adjust logic for the first and last day of the month
                    if (dayOfMonth === 1) {
                        if (isReserved && !isPrevMonthLastDayReserved) {
                            isEnd2 = false;
                            isStart2 = true;
                        }else {
                            isEnd2 = false;
                            isStart2 = false;
                            if ( isReserved && !isNextReserved) {
                                isEnd2 = true;
                            }
                        }
                        
                        } else if (dayOfMonth === daysInMonth) {
                        if (isReserved && !isNextMonthFirstDayReserved) {
                            isEnd2 = true;
                            isStart2 = false;
                        } else {
                            isEnd2 = false;
                            isStart2 = false;
                            if (isReserved &&!isPrevReserved) {
                                isStart2 = true;
                            }
                        }
                    }

                    if (type === "GreenPassion") {
                            if (sDays.includes(formattedDay)) {
                                isStart2 = true;
                                isEnd2 = false;
                            }
                            if (eDays.includes(formattedDay)) {
                                isEnd2 = true;
                                isStart2 = false;
                            }
                            if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                                isStart2 = false;
                                isEnd2 = false;
                            }
                    }
                    if (type === "MagicMango") {
                        if (isDateInList(formattedDay,sDays2)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (isDateInList(formattedDay,eDays2)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                    }
                    if (type === "CrystalPower") {
                        if (isDateInList(formattedDay,sDays3)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (isDateInList(formattedDay,eDays3)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                    }
                    if (isPastDate) {isEnd2 = false; isStart2 = false;}
                    const isStart = isPastDate ? false : selrange.start === segmentId || isStart2;
                    const isEnd = isPastDate ? false : selrange.end === segmentId ||isEnd2;
                    const isSelected =  (selrange.start === segmentId ? true : (selrange.start && selrange.end &&
                                    segmentId >= selrange.start && segmentId <= selrange.end 
                                    ));
                    const col = GetDaySegmentColor(isReserved,type,isSelected);
                    const showMiddleLine = isReserved && isSelected;
                    let endtype = false;
                    if (showMiddleLine) {
                        if (dayOfMonth === 1) {
                            if (isPrevMonthLastDayReserved) {
                                endtype = true;
                            } else if (isNextReserved) {
                                endtype = false;
                            }
                        } else if (dayOfMonth === daysInMonth) {
                            if (isPrevReserved) {
                                endtype = true;
                            } else if (isNextMonthFirstDayReserved) {
                                endtype = false;
                            }
                        } else {
                            if (!isEnd2) {
                                if (isPrevReserved && !isSelected) {
                                    endtype = true;
                                } else if (isNextReserved ) {
                                    endtype = false;
                                }
                            }
                            else if (!isStart2) {
                                if (isPrevReserved) {
                                    endtype = true;
                                } else if (isNextReserved ) {
                                    endtype = false;
                                }
                            }
                        }
                    }
                    if( isPastDate) {
                        isEnd2 = false; isStart2 = false;
                    }  
                            
                    return (
                        <div className='SegmentHolder'>
                        <Segment
                            key={type}
                            baseColor={col}
                            hoverColor={isReserved ? segmentColors[type].reserved : segmentColors[type].selected}
                            hoverColor2={segmentColors[type].selected}
                            isStart={isStart}
                            isEnd={isEnd}
                            isStart2={isStart2}
                            isEnd2={isEnd2}
                            onClick={(() => onSegmentSelect(day, type))}
                            selected={isSelected}
                            disabled={isReserved}
                            wi={(isSelected || isReserved) ? "100%" : "80%"}
                            ma={(isSelected || isReserved) ? "0%" : "10%"}
                            isR={isReserved}
                            isS={isSelected}
                            showMiddleLine={showMiddleLine}
                            endtype={endtype}
                            aspectRatio={aspectRatio}
                        />
                        </div>
                    );
                })}
            </Day>
        ); 
    } else if (device === "PHONE") {
        return (
            <DayMobile aspectRatio= {aspectRatio}>
                <div className='daylabel'>{format(day, 'd')}</div>
                {segmentTypes.map(type => {
                    const segmentId = `${formattedDay}-${type}`;
                    const currentDate = new Date();
                    const currentHour = currentDate.getHours();
                    const currentDateNoTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    const formattedDayDate = new Date(formattedDay);
                    const formattedDayNoTime = new Date(formattedDayDate.getFullYear(), formattedDayDate.getMonth(), formattedDayDate.getDate());
                    const isPastDate = formattedDayNoTime < currentDateNoTime || (formattedDayNoTime.getTime() === currentDateNoTime.getTime() && currentHour >= 12);
                    const isReserved = reservedDays[type].includes(formattedDay) || isPastDate;
                    const selrange = GetSelRange(type);

                    const prevDay = adjustDate(day, -1); // Előző nap
                    const nextDay = adjustDate(day, 1); // Következő nap

                    const isPrevReserved = isDateInList(prevDay, reservedDays[type]);
                    const isNextReserved = isDateInList(nextDay, reservedDays[type]);

                    const dayOfMonth = getDate(day);
                    const daysInMonth = getDaysInMonth(day);

                    let previousAndNextMonthDays = [true,false];
                    if (type === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
                    if (type === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
                    if (type === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
                    const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

                    // Determine isStart2 and isEnd2 with the original logic
                    let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
                    let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

                    // Adjust logic for the first and last day of the month
                    if (dayOfMonth === 1) {
                        if (isReserved && !isPrevMonthLastDayReserved) {
                            isEnd2 = false;
                            isStart2 = true;
                        }else {
                            isEnd2 = false;
                            isStart2 = false;
                            if ( isReserved && !isNextReserved) {
                                isEnd2 = true;
                            }
                        }
                        
                        } else if (dayOfMonth === daysInMonth) {
                        if (isReserved && !isNextMonthFirstDayReserved) {
                            isEnd2 = true;
                            isStart2 = false;
                        } else {
                            isEnd2 = false;
                            isStart2 = false;
                            if (isReserved &&!isPrevReserved) {
                                isStart2 = true;
                            }
                        }
                    }

                    if (type === "GreenPassion") {
                            if (sDays.includes(formattedDay)) {
                                isStart2 = true;
                                isEnd2 = false;
                            }
                            if (eDays.includes(formattedDay)) {
                                isEnd2 = true;
                                isStart2 = false;
                            }
                            if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                                isStart2 = false;
                                isEnd2 = false;
                            }
                    }
                    if (type === "MagicMango") {
                        if (isDateInList(formattedDay,sDays2)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (isDateInList(formattedDay,eDays2)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                    }
                    if (type === "CrystalPower") {
                        if (isDateInList(formattedDay,sDays3)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (isDateInList(formattedDay,eDays3)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                    }
                    const isStart = isPastDate ? false : selrange.start === segmentId || isStart2;
                    const isEnd = isPastDate ? false : selrange.end === segmentId ||isEnd2;
                    const isSelected =  (selrange.start === segmentId ? true : (selrange.start && selrange.end &&
                                    segmentId >= selrange.start && segmentId <= selrange.end 
                                    ));
                    const col = GetDaySegmentColor(isReserved,type,isSelected);
                    const showMiddleLine = isReserved && isSelected;
                    let endtype = false;
                    if (showMiddleLine) {
                        if (dayOfMonth === 1) {
                            if (isPrevMonthLastDayReserved) {
                                endtype = true;
                            } else if (isNextReserved) {
                                endtype = false;
                            }
                        } else if (dayOfMonth === daysInMonth) {
                            if (isPrevReserved) {
                                endtype = true;
                            } else if (isNextMonthFirstDayReserved) {
                                endtype = false;
                            }
                        } else {
                            if (!isEnd2) {
                                if (isPrevReserved && !isSelected) {
                                    endtype = true;
                                } else if (isNextReserved ) {
                                    endtype = false;
                                }
                            }
                            else if (!isStart2) {
                                if (isPrevReserved) {
                                    endtype = true;
                                } else if (isNextReserved ) {
                                    endtype = false;
                                }
                            }
                        }
                    } 
                    if( isPastDate) {
                        isEnd2 = false; isStart2 = false;
                    }  
                     
                            
                    return (
                        <div className='SegmentHolder'>
                        <SegmentMobile
                            key={type}
                            baseColor={col}
                            hoverColor={isReserved ? segmentColors[type].reserved : segmentColors[type].selected}
                            hoverColor2={segmentColors[type].selected}
                            isStart={isStart}
                            isEnd={isEnd}
                            isStart2={isStart2}
                            isEnd2={isEnd2}
                            onClick={(() => onSegmentSelect(day, type))}
                            selected={isSelected}
                            disabled={isReserved}
                            wi={(isSelected || isReserved) ? "100%" : "80%"}
                            ma={(isSelected || isReserved) ? "0%" : "10%"}
                            isR={isReserved}
                            isS={isSelected}
                            showMiddleLine={showMiddleLine}
                            endtype={endtype}
                            aspectRatio={aspectRatio}
                        />
                        </div>
                    );
                })}
            </DayMobile>
        ); 
    } else if (device === "TAB") {
        return (
            <DayTablet aspectRatio= {aspectRatio}>
                <div className='daylabel'>{format(day, 'd')}</div>
                {segmentTypes.map(type => {
                    const segmentId = `${formattedDay}-${type}`;
                    const currentDate = new Date();
                    const currentHour = currentDate.getHours();
                    const currentDateNoTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    const formattedDayDate = new Date(formattedDay);
                    const formattedDayNoTime = new Date(formattedDayDate.getFullYear(), formattedDayDate.getMonth(), formattedDayDate.getDate());
                    const isPastDate = formattedDayNoTime < currentDateNoTime || (formattedDayNoTime.getTime() === currentDateNoTime.getTime() && currentHour >= 12);
                    const isReserved = reservedDays[type].includes(formattedDay);
                    const selrange = GetSelRange(type);

                    const prevDay = adjustDate(day, -1); // Előző nap
                    const nextDay = adjustDate(day, 1); // Következő nap

                    const isPrevReserved = isDateInList(prevDay, reservedDays[type]);
                    const isNextReserved = isDateInList(nextDay, reservedDays[type]);

                    const dayOfMonth = getDate(day);
                    const daysInMonth = getDaysInMonth(day);

                    let previousAndNextMonthDays = [true,false];
                    if (type === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
                    if (type === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
                    if (type === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
                    const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

                    // Determine isStart2 and isEnd2 with the original logic
                    let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
                    let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

                    // Adjust logic for the first and last day of the month
                    if (dayOfMonth === 1) {
                        if (isReserved && !isPrevMonthLastDayReserved) {
                            isEnd2 = false;
                            isStart2 = true;
                        }else {
                            isEnd2 = false;
                            isStart2 = false;
                            if ( isReserved && !isNextReserved) {
                                isEnd2 = true;
                            }
                        }
                        
                        } else if (dayOfMonth === daysInMonth) {
                        if (isReserved && !isNextMonthFirstDayReserved) {
                            isEnd2 = true;
                            isStart2 = false;
                        } else {
                            isEnd2 = false;
                            isStart2 = false;
                            if (isReserved &&!isPrevReserved) {
                                isStart2 = true;
                            }
                        }
                    }

                    if (type === "GreenPassion") {
                            if (sDays.includes(formattedDay)) {
                                isStart2 = true;
                                isEnd2 = false;
                            }
                            if (eDays.includes(formattedDay)) {
                                isEnd2 = true;
                                isStart2 = false;
                            }
                            if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                                isStart2 = false;
                                isEnd2 = false;
                            }
                    }
                    if (type === "MagicMango") {
                        if (isDateInList(formattedDay,sDays2)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (isDateInList(formattedDay,eDays2)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                    }
                    if (type === "CrystalPower") {
                        if (isDateInList(formattedDay,sDays3)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (isDateInList(formattedDay,eDays3)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                    }
                    const isStart = selrange.start === segmentId || isStart2;
                    const isEnd = selrange.end === segmentId ||isEnd2;
                    const isSelected =  (selrange.start === segmentId ? true : (selrange.start && selrange.end &&
                                    segmentId >= selrange.start && segmentId <= selrange.end 
                                    ));
                    const col = GetDaySegmentColor(isReserved,type,isSelected);
                    const showMiddleLine = isReserved && isSelected;
                    let endtype = false;
                    if (showMiddleLine) {
                        if (dayOfMonth === 1) {
                            if (isPrevMonthLastDayReserved) {
                                endtype = true;
                            } else if (isNextReserved) {
                                endtype = false;
                            }
                        } else if (dayOfMonth === daysInMonth) {
                            if (isPrevReserved) {
                                endtype = true;
                            } else if (isNextMonthFirstDayReserved) {
                                endtype = false;
                            }
                        } else {
                            if (!isEnd2) {
                                if (isPrevReserved && !isSelected) {
                                    endtype = true;
                                } else if (isNextReserved ) {
                                    endtype = false;
                                }
                            }
                            else if (!isStart2) {
                                if (isPrevReserved) {
                                    endtype = true;
                                } else if (isNextReserved ) {
                                    endtype = false;
                                }
                            }
                        }
                    }  
                            
                    return (
                        <div className='SegmentHolder'>
                        <SegmentTablet
                            key={type}
                            baseColor={col}
                            hoverColor={isReserved ? segmentColors[type].reserved : segmentColors[type].selected}
                            hoverColor2={segmentColors[type].selected}
                            isStart={isStart}
                            isEnd={isEnd}
                            isStart2={isStart2}
                            isEnd2={isEnd2}
                            onClick={(() => onSegmentSelect(day, type))}
                            selected={isSelected}
                            disabled={isReserved}
                            wi={(isSelected || isReserved) ? "100%" : "80%"}
                            ma={(isSelected || isReserved) ? "0%" : "10%"}
                            isR={isReserved}
                            isS={isSelected}
                            showMiddleLine={showMiddleLine}
                            endtype={endtype}
                            aspectRatio={aspectRatio}
                        />
                        </div>
                    );
                })}
            </DayTablet>
        ); 
    }
};

export default DaySegment;
