import React, { useEffect, useState } from "react";

const states = [
  {
    label: "Foglalt",
    boxes: [
      { text: "GreenPassion", color: "#646464" },
      { text: "MagicMango", color: "#646464" },
      { text: "CrystalPower", color: "#646464" },
    ],
  },
  {
    label: "Szabad",
    boxes: [
      { text: "GreenPassion", color: "rgb(93, 115, 41)" },
      { text: "MagicMango", color: "rgb(255, 191, 52)" },
      { text: "CrystalPower", color: "rgb(246, 38, 129)" },
    ],
    borderOnly: true,
  },
  {
    label: "Választott",
    boxes: [
      { text: "GreenPassion", color: "rgb(93, 115, 41)" },
      { text: "MagicMango", color: "rgb(255, 191, 52)" },
      { text: "CrystalPower", color: "rgb(246, 38, 129)" },
    ],
  },
  {
    label: "Foglalt",
    boxes: [
      { text: "GreenPassion", color: "#646464" },
      { text: "MagicMango", color: "#646464" },
      { text: "CrystalPower", color: "#646464" },
    ],
    diamond: "cut-left",
  },
  {
    label: "Választott",
    boxes: [
      { text: "GreenPassion", color: "rgb(93, 115, 41)" },
      { text: "MagicMango", color: "rgb(255, 191, 52)" },
      { text: "CrystalPower", color: "rgb(246, 38, 129)" },
    ],
    diamond: "cut-left",
  },
  
];

const TransformDiv = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % states.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const currentState = states[currentIndex];

  return (
    <div className="container">
      <div className="label">{currentState.label}</div>
      <div className="boxes">
        {currentState.boxes.map((box, index) => (
          <div
            key={index}
            className={`box ${
              currentState.borderOnly ? "border-only" : ""
            } ${currentState.diamond || ""}`}
            style={{
              backgroundColor: currentState.borderOnly ? "transparent" : box.color,
              borderColor: box.color,
            }}
          >
            {box.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransformDiv;