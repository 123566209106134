import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './StatsDashboard.css';
import { useAnalize } from '../contexts/AnalizeContext';

// Register necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

const StatsDashboard = () => {
  const [startDateDay, setStartDateDay] = useState('');
  const [startDateMonth, setStartDateMonth] = useState('');
  const [startDateYear, setStartDateYear] = useState('');
  const [endDateDay, setEndDateDay] = useState('');
  const [endDateMonth, setEndDateMonth] = useState('');
  const [endDateYear, setEndDateYear] = useState('');
  const {statistics,fetchStatistics} = useAnalize();

  const handleSend = () => {
    // Handle the send button click
    const endDateYearPlusOne = (parseInt(endDateYear, 10) + 1).toString();
    const startDay = startDateDay + "-"+startDateMonth+"-"+startDateYear;
    const endDay = endDateDay+"-"+endDateMonth+"-"+endDateYearPlusOne;
    console.log("Start Date:", startDay);
    console.log("End Date:", endDay);
    fetchStatistics(startDay,endDay);
  };

  const viewData = {
    labels: ['Főodal', 'Információ', 'CrystalPower', 'GreenPassion', 'MagicMango', 'Foglalás'],
    datasets: [
      {
        label: '# of Votes',
        data: [statistics.wiewp1, statistics.wiewp2, statistics.wiewp3, statistics.wiewp4, statistics.wiewp5, statistics.wiewp6],
        backgroundColor: [
          'rgb(150, 67, 0)',
          'rgba(54, 162, 235)',
          'rgb(246, 38, 129)',
          'rgb(93, 115, 41)',
          'rgb(255, 191, 52)',
          'rgba(100, 100, 250)',
        ],
        borderColor: [
          'black',
          'black',
          'black',
          'black',
          'black',
          'black',
        ],
        borderWidth: 1,
      },
    ],
  };
  const timeData = {
    labels: ['Főodal', 'Információ', 'CrystalPower', 'GreenPassion', 'MagicMango', 'Foglalás'],
    datasets: [
      {
        label: '# of Votes',
        data: [statistics.pageTimep1, statistics.pageTimep2, statistics.pageTimep3, statistics.pageTimep4, statistics.pageTimep5, statistics.pageTimep6],
        backgroundColor: [
          'rgb(150, 67, 0)',
          'rgba(54, 162, 235)',
          'rgb(246, 38, 129)',
          'rgb(93, 115, 41)',
          'rgb(255, 191, 52)',
          'rgba(100, 100, 250)',
        ],
        borderColor: [
          'black',
          'black',
          'black',
          'black',
          'black',
          'black',
        ],
        borderWidth: 1,
      },
    ],
  };
  const res1Data = {
    labels: ['Kártyás Fizetés', 'Banki Átutalás','Egyéb'],
    datasets: [
      {
        label: '# of Votes',
        data: [statistics.cardpay, statistics.bankpay,(statistics.reservation-statistics.cardpay-statistics.bankpay)],
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
          'rgba(255, 206, 86)',
        ],
        borderColor: [
          'black',
          'black',
          'black',
        ],
        borderWidth: 1,
      },
    ],
  };
  const res2Data = {
    labels: ['Teljes összeg', 'Előleg'],
    datasets: [
      {
        label: '# of Votes',
        data: [statistics.cardpay, statistics.bankpay],
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
        ],
        borderColor: [
          'black',
          'black',
        ],
        borderWidth: 1,
      },
    ],
  };

  const bfData = {
    labels: ['Green Passion','Crystal Power','Magic Mango'],
    datasets: [
      {
        label: '# of Votes',
        data: [statistics.breakfastgp, statistics.breakfastcp, statistics.breakfastmm],
        backgroundColor: [
          'rgb(93, 115, 41)',
          'rgb(246, 38, 129)',
          'rgb(255, 191, 52)',
        ],
        borderColor: [
          'black',
          'black',
          'black',
        ],
        borderWidth: 1,
      },
    ],
  };

  const resHData = {
    labels: ['Green Passion','Crystal Power','Magic Mango'],
    datasets: [
      {
        label: '# of Votes',
        data: [statistics.housegpres, statistics.housecpres, statistics.housemmres],
        backgroundColor: [
          'rgb(93, 115, 41)',
          'rgb(246, 38, 129)',
          'rgb(255, 191, 52)',
        ],
        borderColor: [
          'black',
          'black',
          'black',
        ],
        borderWidth: 1,
      },
    ],
  };

  const wathcHData = {
    labels: ['Green Passion','Crystal Power','Magic Mango'],
    datasets: [
      {
        label: '# of Votes',
        data: [statistics.housegpwatch  + statistics.wiewp4, statistics.housecpwatch + statistics.wiewp3, statistics.housemmwatch + statistics.wiewp5],
        backgroundColor: [
          'rgb(93, 115, 41)',
          'rgb(246, 38, 129)',
          'rgb(255, 191, 52)',
        ],
        borderColor: [
          'black',
          'black',
          'black',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="stats-dashboard">
      <div className="stats-section">
      <div className='view-info'>
        <div>
            <h2>Start Date</h2>
            <div className="date-inputs">
            <input
                type="number"
                value={startDateDay}
                onChange={(e) => setStartDateDay(e.target.value)}
                placeholder="Év"
                className="date-input day"
            />
            <input
                type="number"
                value={startDateMonth}
                onChange={(e) => setStartDateMonth(e.target.value)}
                placeholder="Hó"
                className="date-input month"
            />
            <input
                type="number"
                value={startDateYear}
                onChange={(e) => setStartDateYear(e.target.value)}
                placeholder="Nap"
                className="date-input year"
            />
            </div>
        </div>
        <div style={{transform:"translateX(-30vw)"}}>
            <h2>End Date</h2>
            <div className="date-inputs">
            <input
                type="number"
                value={endDateDay}
                onChange={(e) => setEndDateDay(e.target.value)}
                placeholder="Év"
                className="date-input day"
            />
            <input
                type="number"
                value={endDateMonth}
                onChange={(e) => setEndDateMonth(e.target.value)}
                placeholder="Hó"
                className="date-input month"
            />
            <input
                type="number"
                value={endDateYear}
                onChange={(e) => setEndDateYear(e.target.value)}
                placeholder="Nap"
                className="date-input year"
            />
            </div>
        </div>
        </div>
        <button className="send-button" onClick={handleSend}>Küldés</button>
      </div>

      <div className="stats-section">
        <h2>Alap Információk</h2>
        <div className="info">
          <p>Nézettség: {statistics.wiew}</p>
          <p>Kattintás: {statistics.clicks}</p>
          <p>Foglalás: {statistics.reservation}</p>
          <p>Kép nézés: {statistics.imageviewed}</p>
          <p>Kiválasztott időszak: {startDateDay + "-"+startDateMonth+"-"+startDateYear+"    -    "+endDateDay+"-"+endDateMonth+"-"+endDateYear}</p>
        </div>
      </div>

      <div className="stats-section">
        <h2>Nézettség Információk</h2>
        <div className="view-info">
          <div className="view-data">
            <p>Nézettség: {statistics.wiew}</p>
            <p>Főoldal Nézettség: {statistics.wiewp1}</p>
            <p>Infomáció Nézettség: {statistics.wiewp2}</p>
            <p>CrystalPower Nézettség: {statistics.wiewp3}</p>
            <p>GreenPassion Nézettség: {statistics.wiewp4}</p>
            <p>MagicMango Nézettség: {statistics.wiewp5}</p>
            <p>Foglalás Nézettség: {statistics.wiewp6}</p>
          </div>
          <div className="donut-chart">
            <Doughnut data={viewData} />
          </div>
        </div>
      </div>

      <div className="stats-section">
        <h2>Eltöltött Idő</h2>
        <div className="time-info">
          <div className="time-data">
            <p>Összes Eltöltött Idő: {statistics.pageTime} perc</p>
            <p>Főoldalon Eltöltött Idő: {statistics.pageTimep1} perc</p>
            <p>Információn Eltöltött Idő: {statistics.pageTimep2} perc</p>
            <p>CrystalPower-en Eltöltött Idő: {statistics.pageTimep3} perc</p>
            <p>GreenPassion-ön Eltöltött Idő: {statistics.pageTimep4} perc</p>
            <p>Magicmangón Eltöltött Idő: {statistics.pageTimep5} perc</p>
            <p>Foglaláson Eltöltött Idő: {statistics.pageTimep6} perc</p>
          </div>
          <div className="donut-chart">
            <Doughnut data={timeData} />
          </div>
        </div>
      </div>

      <div className="stats-section">
        <h2>Foglalás Infomáció</h2>
        <div className="booking-info">
          <div className="booking-data">
            <p>Foglalás: {statistics.reservation}</p>
            <p>Kártyás fizetés: {statistics.cardpay}</p>
            <p>Banki átutalás: {statistics.bankpay}</p>
            <p>Teljes összeg: {statistics.fullpay}</p>
            <p>Előleg: {statistics.halfpay}</p>
            <p>Intervallum kiválasztva: {statistics.intervallselect}</p>
            <p>Adatok megadva: {statistics.datagiven}</p>
          </div>
          <div className="donut-chart">
            <Doughnut data={res1Data} />
          </div>
          <div className="donut-chart">
            <Doughnut data={res2Data} />
          </div>
        </div>
      </div>

      <div className="stats-section">
        <h2>Reggeli</h2>
        <div className="breakfast-info">
          <div className="breakfast-data">
            <p>Összes: {statistics.breakfastcp + statistics.breakfastgp + statistics.breakfastmm}</p>
            <p>Magic Mango: {statistics.breakfastmm}</p>
            <p>CrystalPower: {statistics.breakfastcp}</p>
            <p>GreenPassion: {statistics.breakfastgp}</p>
          </div>
          <div className="donut-chart">
            <Doughnut data={bfData} />
          </div>
        </div>
      </div>

      <div className="stats-section">
        <h2>Ház Foglalás</h2>
        <div className="house-view-info">
          <div className="house-view-data">
            <p>Magic Mango: {statistics.housemmres}</p>
            <p>Crystal Power: {statistics.housecpres}</p>
            <p>Green Passion: {statistics.housegpres}</p>
          </div>
          <div className="donut-chart">
            <Doughnut data={resHData} />
          </div>
        </div>
      </div>

      <div className="stats-section">
        <h2>Ház nézettség</h2>
        <div className="house-view-info">
          <div className="house-view-data">
            <p>Magic Mango: {statistics.housemmwatch  + statistics.wiewp5}</p>
            <p>Crystal Power: {statistics.housecpwatch  + statistics.wiewp3}</p>
            <p>Green Passion: {statistics.housegpwatch  + statistics.wiewp4}</p>
          </div>
          <div className="donut-chart">
            <Doughnut data={wathcHData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsDashboard;