import React, {useEffect,useRef,useState} from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useBase } from "../../contexts/BaseContext";
import { useLanguage } from "../../contexts/LanguageContext";
import HambiMenu from "../../components/HambiMenu";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from "../../img2/hero-7.png";

const Iphonecrystal = () => {
  const {openPopup,setIm,openPopup2,setIsMenuOpen,setToGal,setToKap,setToPri,changeHousePic,CPPic,CPPicState} = useBase();

  const navigate = useNavigate();
    const {LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,
      LangCryPKitchen, LangCryPTV, LangCryPWiFi, LangCryPBreakfast, LangCryPParking, LangCryPJacuzzi, LangCryPAirConditioning,  LangCryPProceedToBooking,
      LangCryPKitchenDining,LangCryPBedroomDescription,NaptarBack
    } = useLanguage();
    const galeriaRef = useRef(null);
    const kapRef = useRef(null);
    const fogRef = useRef(null);
    const {updateStatistics} = useAnalize();
    let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp3");loaded = true}
    }, []);
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const {trackEvent,trackTiming} = useAnalize();
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'CrystalPower-Phone', 'Initial Page Load',start,end);
      });
    }, []);
    const changeSlug = (slug) => {
      changeHousePic("CP","RES")
      if (slug === "/") {navigate('/');trackEvent("Phone","Navigated","MainPage")}
      else if(slug === "/info") {navigate('/információ');} 
      else if(slug === "/gp") {navigate('/greenpassion');} 
      else if(slug === "/cp") {navigate('/crystalpower');} 
      else if(slug === "/mm") {navigate('/magicmango');} 
      else if(slug === "/fog") {openPopup();trackEvent("Phone","Navigated","Reservation")}
      else if(slug === "/gal") {navigate('/');setTimeout(() => {setToGal(true);}, 200);}
      else if(slug === "/kap") {navigate('/');setTimeout(() => {setToKap(true);}, 200);}
      else if(slug === "/pri") {navigate('/információ');setTimeout(() => {setToPri(true);}, 200);}   
    };
    const openIm = (n) => {

      setIm(n);
      openPopup2();
    }
    const handleLeftClick = () => {
      changeHousePic("CP","L");
      updateStatistics("imageviewed");
      updateStatistics("housecpwatch");
    }
    const handleRightClick = () => {
      changeHousePic("CP","R");
      updateStatistics("imageviewed");
      updateStatistics("housecpwatch");
    }
    const [backgroundPosition, setBackgroundPosition] = useState(50);
    const [trans, setTrans] = useState("translateX(0vw)");
  const startX = useRef(null);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (startX.current !== null) {
      const currentX = e.touches[0].clientX;
      const deltaX = startX.current - currentX; // Megfordítva az irányt
      let newPosition = backgroundPosition + deltaX / window.innerWidth * 10; // Lassabb változás (10-es osztás)

      if (newPosition < 0) newPosition = 0;
      if (newPosition > 100) newPosition = 100;

      setBackgroundPosition(newPosition);
      let str = "translateX("
      if (CPPicState === 100) {str = str + getScroll(-100,0,newPosition,0) + "vw)"}
      else {str = str + getScroll(-100,0,newPosition,0) + "vw)"}
      setTrans(str);
    }
  };

  const handleTouchEnd = () => {
    startX.current = null;
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateStatistics("pageTimep3");
    }, 30000);
    
    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
    };
  }, []);

  const lerp = (x, xMin, xMax, yMin, yMax) => {
    return ((x - xMin) * (yMax - yMin)) / (xMax - xMin) + yMin;
  };
  
  // Function to map backgroundPosition to the new range
  const getScroll = (min, max, backgroundPosition,offset) => {
    let num = lerp(backgroundPosition, 0, 100, min, max);
    return num - offset;
  };
  return (<>
  <Helmet>
      <title>KurukHill - Crystal Power</title>
      <meta name="description" content="Fedezze fel a Crystal Power által nyújtott élményt a Kuruk Hill-en.." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu/crystalpower" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Crystal Power" />
      <meta name="twitter:description" content="Fedezze fel a Crystal Power által nyújtott élményt a Kuruk Hill-en.." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu/crystalpower" />
    </Helmet>
    <div className="iphonecrystal">
      <div className="mangocontainer-wrapper">
        <div className="mangocontainer">
          <div className="overlap-group-wrapper">
            <div className="overlap-group-4">
              <div className="hero-3" style={{overflow:"hidden"}}>
              <img loading="lazy" alt="Loading" src={mainkep} className="Change-Image" style={{height:CPPicState === 0 ? '91vh' : '0vh',width:"100vw"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep1.jpg" className="Change-Image" style={{height:CPPicState === 1 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 1 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep2.jpg" className="Change-Image" style={{height:CPPicState === 2 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 2 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep3.jpg" className="Change-Image" style={{height:CPPicState === 3 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 3 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep4.jpg" className="Change-Image" style={{height:CPPicState === 4 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 4 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep5.jpg" className="Change-Image" style={{height:CPPicState === 5 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 5 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep6.jpg" className="Change-Image" style={{height:CPPicState === 6 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 6 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep7.jpg" className="Change-Image" style={{height:CPPicState === 7 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 7 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep8.jpg" className="Change-Image" style={{height:CPPicState === 8 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 8 ? "block" : "none"}}/>
              <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep9.jpg" className="Change-Image" style={{height:CPPicState === 9 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:CPPicState === 9 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="/img3/klogo3.png" className="frame-52" onClick={() => changeSlug("/")}/>
              </div>
              <div className="frame-53" onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}>
                {CPPicState === 0 && <div className="kuruk-hill-head-3">Crystal Power</div>}
              </div>
              <HambiMenu changeSlug={changeSlug} t={"30px"} r={"20px"}/>
              <div className="cpimg left-arrow" onClick={()=>handleLeftClick()}>
                  <img loading="lazy" alt="Bal nyíl" src="/img/left-arrow.png" style={{height:'100%',margin:'0'}} />
              </div>
              <div className="cpimg right-arrow" onClick={()=>handleRightClick()}>
                  <img loading="lazy" alt="Jobb nyíl" src="/img/right-arrow.png" style={{height:'100%',margin:'0'}} />
              </div>
            </div>
          </div>
          <div className="frame-55">
            <div className="frame-56">
              <div className="frame-57">
                <div className="text-wrapper-28">Crystal power</div>
                <div className="frame-58" />
              </div>
              <div className="ferohely-4">
                <div className="text-wrapper-29">2</div>
                <img loading="lazy" className="icon-user-4" alt="Icon user" src="/img/icon-user-3.png" />
              </div>
            </div>
            <div className="frame-59">
              <p className="h-l-szob-ban-egy-4">
              {LangCryPBedroomDescription()}
              </p>
            </div>
            <div className="frame-60">
              <div className="frame-61">
                <div className="frame-62">
                  <img loading="lazy" className="img-12" alt="Kitchen cabinets" src="/img/kitchen-cabinets-2-svgrepo-com-2.svg" />
                  <div className="text-wrapper-30">{LangCryPKitchen()}</div>
                </div>
                <div className="frame-63">
                  <img loading="lazy" className="img-12" alt="Tv svgrepo com" src="/img/tv-svgrepo-com.svg" />
                  <div className="text-wrapper-30">{LangCryPTV()}</div>
                </div>
              </div>
            </div>
            <div className="frame-60">
              <div className="frame-64">
                <div className="frame-65">
                  <img loading="lazy" className="img-13" alt="Wifi svgrepo com" src="/img/wifi-svgrepo-com-2.svg" />
                  <div className="text-wrapper-31">{LangCryPWiFi()}</div>
                </div>
                <div className="frame-62">
                  <img loading="lazy" className="img-12" alt="Parking svgrepo com" src="/img/parkolasikon-3.svg" />
                  <div className="text-wrapper-30">{LangCryPParking()}</div>
                </div>
              </div>
            </div>
            <div className="frame-60">
              <div className="frame-66">
                <div className="frame-63">
                  <img loading="lazy" className="img-12" alt="Group" src="/img/jacuzziikon.png" />
                  <div className="text-wrapper-30">{LangCryPJacuzzi()}</div>
                </div>
                <div className="frame-65">
                  <img loading="lazy" className="img-13" alt="Condicioner svgrepo" src="/img/legkondiikon.svg" />
                  <div className="text-wrapper-31">{LangCryPAirConditioning()}</div>
                </div>
              </div>
            </div>
            <div className="frame-60">
              <div className="frame-67">
                <div className="frame-65">
                  <img loading="lazy" className="img-13" alt="Breakfast food" src="/img/breakfast-food-healthy-svgrepo-com.svg" />
                  <div className="text-wrapper-31">{LangCryPBreakfast()}</div>
                </div>
              </div>
            </div>
            <div className="frame-59">
              <p className="text-wrapper-32">
              {LangCryPKitchenDining()}
              </p>
            </div>
            <div className="frame-68">
              <div className="frame-69" />
              <div className="frame-70" onClick={() => changeSlug("/fog")}>
                <div className="text-wrapper-33" >{LangCryPProceedToBooking()}</div>
              </div>
              <div className="frame-70" onClick={() => changeSlug("/")} style={{width:"80px",margin:"5px 0px 0px 0px"}}>
                <div className="text-wrapper-33">{NaptarBack()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Iphonecrystal;