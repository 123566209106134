import React, { useEffect, useRef } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useBase } from "../../contexts/BaseContext";
import { useLanguage } from "../../contexts/LanguageContext";
import LanguageDropdown from "../../components/LangDropdown";
import NavLinks from "../../components/NavLinks";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from '../../img2/infokep.png';

const Desktop = () => {
  const priRef = useRef(null);
  const ASZFRef = useRef(null);
    const navigate = useNavigate();
    const {openPopup,setToGal,setToKap,setToPri,toPri,toASZF,setToASZF} = useBase();
    const {LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,LangInfoAboutHouses, LangInfoBookingConditions, LangInfoYearRound, LangInfoMaxTwoPeople, LangInfoBookingDuration, LangInfoNoPets, LangInfoNoSmoking, LangInfoThankYou, LangInfoParameters, LangInfoComfort, LangInfoForgottenItems,LangInfoForgottenItems2,
           LangInfoBedroom,LangInfoLargeDoubleBed,LangInfoQualityBedding,LangInfoBookshelf,LangInfoReadingLamp,LangInfoBoardGames,LangInfoAdjustableTV,LangInfoSafe, LangInfoIron,
           LangInfoSlippers,LangInfoKitchenDining,LangInfoDiningCounter,LangInfoWineFridge,LangInfoPlateSet,LangInfoGlassSet,LangInfoQualityKnives,LangInfoBerlingerPots,LangInfoCeramicHob,
           LangInfoFridge,LangInfoToaster,LangInfoDishwasher,LangInfoBasicFood,LangInfoCleaningSupplies,LangInfoServicesAndPrices,LangInfoMinTwoNights,LangInfoPricesForTwo,
           LangInfoMagicMango,LangInfoCrystalPower,LangInfoGreenPassion,LangInfoSpecialDays,LangInfoMinTwoNightsRepeat,LangInfoHolidaySurcharge,LangInfoHighlightedDays,LangInfoMarch14_17,
           LangInfoMarch28_April1,LangInfoAugust17_20,LangInfoOctober31_November3,LangInfoDecember24_January2,LangInfoBookingPaymentCancellation,LangInfoBookingPaymentCancellationDetails,LangInfoBookingPaymentCancellationDetails2,LangInfoBookingPaymentCancellationDetails3,LangInfoProceedToBooking,NaptarBack
          } = useLanguage();
    const {updateStatistics} = useAnalize();
    let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp2");loaded = true}
    }, []);
    useEffect(() => {
      if (!toASZF){window.scrollTo({ top: 0, behavior: 'smooth' });}
    }, []);
    const {trackEvent,trackTiming} = useAnalize();
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'Info-PC', 'Initial Page Load',start,end);
      });
    }, []);

    const changeSlug = (slug) => {
        if (slug === "/") {navigate('/');trackEvent("PC","Navigated","MainPage")}
        else if(slug === "/info") {navigate('/információ');} 
        else if(slug === "/gp") {navigate('/greenpassion');} 
        else if(slug === "/cp") {navigate('/crystalpower');} 
        else if(slug === "/mm") {navigate('/magicmango');} 
        else if(slug === "/fog") {openPopup();trackEvent("PC","Navigated","Reservation")}
        else if(slug === "/gal") {navigate('/');setTimeout(() => {setToGal(true);}, 200);}
        else if(slug === "/kap") {navigate('/');setTimeout(() => {setToKap(true);}, 200);}
        else if(slug === "/pri") {priRef.current.scrollIntoView({ behavior: "smooth" });}   
    };
    useEffect(() => {
      if (toPri) {priRef.current.scrollIntoView({ behavior: "smooth" }); setToPri(false)}
    }, [toPri]);
    useEffect(() => {
      if (toASZF) {ASZFRef.current.scrollIntoView({ behavior: "smooth" }); setToASZF(false)}
    }, [toASZF]);
    useEffect(() => {
      const intervalId = setInterval(() => {
        updateStatistics("pageTimep2");
      }, 30000);
      
      return () => {
        clearInterval(intervalId); // Clear interval on component unmount
      };
    }, []);
  return (<>
  <Helmet>
      <title>KurukHill - Információ</title>
      <meta name="description" content="Kapcsolat: +36 70 942 0610, info@kurukhill.hu. Látogasson el hozzánk: 8749 Zalakaros Szent Orbán utca 42." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu/információ" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Információ" />
      <meta name="twitter:description" content="Kapcsolat: +36 70 942 0610, info@kurukhill.hu. Látogasson el hozzánk: 8749 Zalakaros Szent Orbán utca 42." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu/információ" />
    </Helmet>
    <div className="desktop">
      <div className="hero">
        <div className="overlap-group">
        <img /*loading="lazy"*/ alt="Loading" src={mainkep} className="Change-Image-Info" style={{height:'100vh',width:"100vw",backgroundColor:"black"}}/>
          <div className="herocontentwrap">
            <div className="kuruk-hillhead">{LangMainTitInf()}</div>
          </div>
          <div className="navcont">
            <img loading="lazy" alt="Loading" src="/img3/klogo3.png" className="logo" onClick={() => changeSlug("/")}/>
            <NavLinks
                changeSlug={changeSlug}
                LangMainTitStart={LangMainTitStart}
                LangMainTitGal={LangMainTitGal}
                LangMainTitCont={LangMainTitCont}
                LangMainTitPri={LangMainTitPri}
                LangMainTitRes={LangMainTitRes}
                LangMainTitInf={LangMainTitInf}
                sts={[false,false,false,false,false,true]}
              />
            <LanguageDropdown/>
          </div>
        </div>
      </div>
      <div className="frame">
        <div className="div-wrapper">
          <div className="div-wrapper-2">
            <div className="infoahazrolcontainer">
              <div className="div-2">
                <div className="infohead" >{LangInfoAboutHouses()}</div>
                <div className="infovonal" />
              </div>
              <div className="frame-2">
                <p className="text-wrapper-3">{LangInfoBookingConditions()}</p>
                <p className="text-wrapper-4">{LangInfoYearRound()}</p>
                <p className="text-wrapper-4">{LangInfoMaxTwoPeople()}</p>
                <p className="text-wrapper-4">{LangInfoBookingDuration()}</p>
                <p className="text-wrapper-4">{LangInfoNoPets()}</p>
                <p className="text-wrapper-4">
                  {LangInfoNoSmoking()}
                </p>
              </div>
              <div className="div-wrapper-3">
                <div className="text-wrapper-5">{LangInfoThankYou()}</div>
              </div>
              <img loading="lazy" className="tilosikonok" alt="Tilosikonok" src="/img/tilosikonok.svg" />
            </div>
          </div>
        </div>
        <div className="parameterekwrap">
          <div className="parameterekcont-wrapper">
            <div className="parameterekcont">
              <div className="parameterekheadcont">
                <div className="div-3" />
                <div className="text-wrapper-6">{LangInfoParameters()}</div>
              </div>
              <div className="div-2">
                <p className="h-zainkat-igyekezt" style={{marginLeft:"0"}}>
                  {LangInfoComfort()}
                  <br />
                  {LangInfoForgottenItems()}<br />
                  {LangInfoForgottenItems2()}
                </p>
              </div>
              <div className="div-4">
                <div className="text-wrapper-3" style={{marginLeft:"0",fontWeight:"700"}}>{LangInfoBedroom()}</div>
                <div className="text-wrapper-4">{LangInfoLargeDoubleBed()}</div>
                <p className="text-wrapper-4">{LangInfoQualityBedding()}</p>
                <p className="text-wrapper-4">{LangInfoBookshelf()}</p>
                <div className="text-wrapper-4">{LangInfoReadingLamp()}</div>
                <p className="text-wrapper-4">{LangInfoBoardGames()}</p>
                <p className="text-wrapper-4">{LangInfoAdjustableTV()}</p>
                <div className="text-wrapper-4">{LangInfoSafe()}</div>
                <div className="text-wrapper-4">{ LangInfoIron()}</div>
                <div className="text-wrapper-4">{LangInfoSlippers()}</div>
              </div>
              <div className="div-5">
                <div className="text-wrapper-3"style={{marginLeft:"0",fontWeight:"700"}}>{LangInfoKitchenDining()}</div>
                <div className="text-wrapper-4">{LangInfoDiningCounter()}</div>
                <p className="text-wrapper-4">
                  {LangInfoWineFridge()}
                </p>
                <div className="text-wrapper-4">{LangInfoPlateSet()}</div>
                <p className="text-wrapper-4">{LangInfoGlassSet()}</p>
                <div className="text-wrapper-4">{LangInfoQualityKnives()}</div>
                <p className="text-wrapper-4">{LangInfoBerlingerPots()}</p>
                <div className="text-wrapper-4">{LangInfoCeramicHob()}</div>
                <div className="text-wrapper-4">{LangInfoFridge()}</div>
                <div className="text-wrapper-4">{LangInfoToaster()}</div>
                <div className="text-wrapper-4">{LangInfoDishwasher()}</div>
                <p className="text-wrapper-4">{LangInfoBasicFood()}</p>
                <div className="text-wrapper-4">{LangInfoCleaningSupplies()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-wrapper-2">
          <div className="div-wrapper">
            <div className="szolgaltatasokcont" ref={priRef}>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <div className="text-wrapper-7">{LangInfoServicesAndPrices()}</div>
                  <div className="szolgaltatasokvonal" />
                </div>
              </div>
              <div className="div-4">
                <p className="text-wrapper-3">{LangInfoMinTwoNights()}</p>
                <p className="text-wrapper-4">{LangInfoPricesForTwo()}</p>
              </div>
              <div className="arainkcont">
                <div className="text-wrapper-8">{LangMainTitPri()}:</div>
                <p className="text-wrapper-4">
                  {LangInfoMagicMango()}
                </p>
                <p className="text-wrapper-4">
                  {LangInfoCrystalPower()}
                </p>
                <p className="text-wrapper-4">
                  {LangInfoGreenPassion()}
                </p>
              </div>
              <div className="div-5">
                <div className="text-wrapper-8">{LangInfoSpecialDays()}</div>
                <p className="text-wrapper-4">{LangInfoMinTwoNightsRepeat()}</p>
                <p className="text-wrapper-9">{LangInfoHolidaySurcharge()}</p>
              </div>
              <div className="div-5">
                <div className="text-wrapper-8">{LangInfoHighlightedDays()}</div>
                <div className="text-wrapper-4">{LangInfoMarch14_17()}</div>
                <div className="text-wrapper-9">{LangInfoMarch28_April1()}</div>
                <div className="text-wrapper-9">{LangInfoAugust17_20()}</div>
                <div className="text-wrapper-9">{LangInfoOctober31_November3()}</div>
                <div className="text-wrapper-9">{LangInfoDecember24_January2()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-wrapper-2">
          <div className="foglalasicont" ref={ASZFRef}>
            <div className="figlalasiheadcont">
              <div className="div-3" />
              <div className="text-wrapper-10">{LangInfoBookingPaymentCancellation()}</div>
            </div>
            <div className="div-2">
              <p className="foglal-si-fizet-si">
                {LangInfoBookingPaymentCancellationDetails3()}
                <br />
                {LangInfoBookingPaymentCancellationDetails2()}
                <br />
                {LangInfoBookingPaymentCancellationDetails()}
              </p>
            </div>
            <a href="/docs/ASZF.docx" download="ASZF.docx">
              <img loading="lazy" className="pdffilegomb" alt="Pdffilegomb" src="/img/pdffilegomb.svg" />
            </a>
          </div>
        </div>
        <div className="foglalasgomb-wrapper">
          <div className="foglalasgomb" onClick={() => changeSlug("/fog")}>
            <div className="text-wrapper-11" >{LangInfoProceedToBooking()}</div>
          </div>
          <div className="foglalasgomb" onClick={() => changeSlug("/")} style={{width:"80px",margin:"5px 0px 0px 0px"}}>
            <div className="text-wrapper-11">{NaptarBack()}</div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Desktop;