import React, { createContext, useContext, useState } from 'react';


const LanguageContext = createContext(null);

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const [langId, setLangId] = useState("HU");
    const [isOpen, setIsOpen] = useState(false);

    //MAINPAGE
    const LangMainLux = () => {
        if (langId === "HU") { return `"Luxus a szőlőben"`}
        else if (langId === "EN") { return `"Luxury in the vineyard"`}
        else if (langId === "DE") { return `"Luxus im Weinberg"`}
        else {return `"Luxus a szőlőben"`}
    }
    const LangMainTitStart = () => {
        if (langId === "HU") { return "Kezdőlap"}
        else if (langId === "EN") { return "Mainpage"}
        else if (langId === "DE") { return "Startseite"}
        else {return "Kezdőlap"}
    }
    const LangMainTitGal = () => {
        if (langId === "HU") { return "Galéria"}
        else if (langId === "EN") { return "Gallery"}
        else if (langId === "DE") { return "Galerie"}
        else {return "Galéria"}
    }
    const LangMainTitCont = () => {
        if (langId === "HU") { return "Kapcsolat"}
        else if (langId === "EN") { return "Contact"}
        else if (langId === "DE") { return "Kontakt"}
        else {return "Kapcsolat"}
    }
    const LangMainTitPri = () => {
        if (langId === "HU") { return "Áraink"}
        else if (langId === "EN") { return "Prices"}
        else if (langId === "DE") { return "Preise"}
        else {return "Áraink"}
    }
    const LangMainTitRes = () => {
        if (langId === "HU") { return "Foglalás"}
        else if (langId === "EN") { return "Reservation"}
        else if (langId === "DE") { return "Reservierung"}
        else {return "Foglalás"}
    }
    const LangMainTitInf = () => {
        if (langId === "HU") { return "Információ"}
        else if (langId === "EN") { return "Information"}
        else if (langId === "DE") { return "Informationen"}
        else {return "Információ"}
    }
    const LangMainMerul1 = () => {
        if (langId === "HU") { return `Merüljetek el a kényeztetés`}
        else if (langId === "EN") { return `Immerse yourselves in the unparalleled `}
        else if (langId === "DE") { return `Tauchen Sie ein in das unvergleichliche`}
    }
    const LangMainMerul2 = () => {
        if (langId === "HU") { return `páratlan élményében.`}
        else if (langId === "EN") { return `experience of indulgence.`}
        else if (langId === "DE") { return `Erlebnis der Verwöhnung.`}
    }
    const LangBookNow = () => {
        if (langId === "HU") { return "Foglalj most!" }
        else if (langId === "EN") { return "Book now!" }
        else if (langId === "DE") { return "Buchen Sie jetzt!" }
        else { return "Foglalj most!" }
    }
    const LangOurHouses = () => {
        if (langId === "HU") { return "Házaink" }
        else if (langId === "EN") { return "Houses" }
        else if (langId === "DE") { return "Häuser" }
        else { return "Házaink" }
    }
    const LangHouseDescription = () => {
        if (langId === "HU") { 
            return "Faházaink Zalakaros szőlőhegyén, a Kis-Balaton szomszédságában, a Zalai Borvidéken helyezkednek el, Nagykanizsától 20 perc, Budapesttől 2 órányi autóútra. Mindhárom házunkból 180 fokos örökpanoráma nyílik a környék szőlőültetvényeire, a Keszthelyi-hegység vonulataira, a Kis-Balatonra és a Balatonfelvidék hegyeire, köztük a Badacsonyra."
        }
        else if (langId === "EN") { 
            return "Our wooden houses are located on the vineyard hill of Zalakaros, near Kis-Balaton, in the Zala Wine Region, 20 minutes from Nagykanizsa and 2 hours by car from Budapest. From all three of our houses, there is a 180-degree panoramic view of the surrounding vineyards, the Keszthely Mountains, Kis-Balaton, and the hills of the Balaton Highlands, including Badacsony."
        }
        else if (langId === "DE") { 
            return "Unsere Holzhäuser befinden sich auf dem Weinberghügel von Zalakaros, in der Nähe des Kis-Balaton, im Weingebiet Zala, 20 Minuten von Nagykanizsa und 2 Autostunden von Budapest entfernt. Von allen drei Häusern hat man einen 180-Grad-Panoramablick auf die umliegenden Weinberge, das Keszthely-Gebirge, den Kis-Balaton und die Hügel des Balaton-Hochlands, einschließlich Badacsony."
        }
        else { 
            return "Faházaink Zalakaros szőlőhegyén, a Kis-Balaton szomszédságában, a Zalai Borvidéken helyezkednek el, Nagykanizsától 20 perc, Budapesttől 2 órányi autóútra. Mindhárom házunkból 180 fokos örökpanoráma nyílik a környék szőlőültetvényeire, a Keszthelyi-hegység vonulataira, a Kis-Balatonra és a Balatonfelvidék hegyeire, köztük a Badacsonyra."
        }
    }
    const LangOurHousesDetailed = () => {
        if (langId === "HU") { 
            return "Ki ne álmodna egy saját diófáról egy mesebeli házikóval a saját birtokán? Hát mi ketten álmodtunk és 2018-ban el is kezdtük valóra váltani ezt az álmot. Előbb megvásároltuk szőlőhegyünket, majd felújítottuk a rajta álló borospincét és rendbe hoztuk annak környezetét, ezután pedig elkezdtük felépíteni A-frame stílusú faházainkat. Mindezt olyan környezetben, ahol ugyanúgy jár a nap, mégis máshogy telik az idő, ami közel van és mégis elég távol és ahol szinte a madár sem jár."
        }
        else if (langId === "EN") { 
            return "Who wouldn't dream of having their own walnut tree with a fairytale house on their own estate? Well, we both dreamed and started making this dream a reality in 2018. First, we bought our vineyard hill, then we renovated the wine cellar on it and tidied up its surroundings, and then we started building our A-frame style wooden houses. All this in an environment where the sun shines the same, yet time passes differently, which is close yet far enough, and where hardly a bird can be seen."
        }
        else if (langId === "DE") { 
            return "Wer würde nicht davon träumen, einen eigenen Walnussbaum mit einem märchenhaften Haus auf seinem eigenen Anwesen zu haben? Nun, wir beide haben geträumt und 2018 begonnen, diesen Traum Wirklichkeit werden zu lassen. Zuerst kauften wir unseren Weinberghügel, dann renovierten wir den darauf stehenden Weinkeller und brachten seine Umgebung in Ordnung, und dann begannen wir mit dem Bau unserer A-Frame-Holzhäuser. All dies in einer Umgebung, in der die Sonne genauso scheint, die Zeit aber anders vergeht, die nah genug, aber doch weit entfernt ist und wo kaum ein Vogel zu sehen ist."
        }
        else { 
            return "Ki ne álmodna egy saját diófáról egy mesebeli házikóval a saját birtokán? Hát mi ketten álmodtunk és 2018-ban el is kezdtük valóra váltani ezt az álmot. Előbb megvásároltuk szőlőhegyünket, majd felújítottuk a rajta álló borospincét és rendbe hoztuk annak környezetét, ezután pedig elkezdtük felépíteni A-frame stílusú faházainkat. Mindezt olyan környezetben, ahol ugyanúgy jár a nap, mégis máshogy telik az idő, ami közel van és mégis elég távol és ahol szinte a madár sem jár."
        }
    }
    const LangOurDream = () => {
        if (langId === "HU") { 
            return "Szerettük volna, ha álmaink birtokán ti is luxus körülmények között élvezhetitek a szőlőhegy csendjét, ahol egy darabkát ti is megtalálhattok az álmaitokból, ahol borozgatva beülhettek a diófák árnyékába, vagy a pattogó tűz mellé, helyi finomságokkal töltött piknikkosárból reggelizhettek teraszotokon, csobbanhattok a privát jakuzziban, vagy magatokra zárhatjátok az ajtót és ki sem kell mozdulnotok napokig."
        }
        else if (langId === "EN") { 
            return "We wanted you to enjoy the tranquility of the vineyard hill in luxurious conditions on our dream estate, where you can find a piece of your dreams, sit in the shade of walnut trees while drinking wine, have breakfast on your terrace with a picnic basket filled with local delicacies, take a dip in the private jacuzzi, or lock the door and stay in for days."
        }
        else if (langId === "DE") { 
            return "Wir wollten, dass ihr auf unserem Traumanwesen den Frieden des Weinberghügels in luxuriösen Bedingungen genießen könnt, wo ihr ein Stück eurer Träume finden könnt, im Schatten der Walnussbäume Wein trinkt, auf eurer Terrasse mit einem Picknickkorb voller lokaler Köstlichkeiten frühstückt, im privaten Whirlpool badet oder die Tür abschließt und tagelang drinnen bleibt."
        }
        else { 
            return "Szerettük volna, ha álmaink birtokán ti is luxus körülmények között élvezhetitek a szőlőhegy csendjét, ahol egy darabkát ti is megtalálhattok az álmaitokból, ahol borozgatva beülhettek a diófák árnyékába, vagy a pattogó tűz mellé, helyi finomságokkal töltött piknikkosárból reggelizhettek teraszotokon, csobbanhattok a privát jakuzziban, vagy magatokra zárhatjátok az ajtót és ki sem kell mozdulnotok napokig."
        }
    }
    const LangExperience = () => {
        if (langId === "HU") { 
            return "Mindhárom faházunk kihagyhatatlan élményt nyújt azoknak, akik a kemény munka, a mókuskerék és a szürke hétköznapok után szeretnének egy kicsit a béke szigetén pihenni, feltöltődni, minőségi időt eltölteni és azoknak, akik új kalandra indulnának, felfedeznék a környéket, miközben emlékezetes pillanatokat gyűjtenek, helyben termett szőlőből készült borokat kóstolnak, ízletes zalai falatok kíséretében."
        }
        else if (langId === "EN") { 
            return "All three of our wooden houses offer an unforgettable experience for those who want to relax, recharge, and spend quality time on the island of peace after hard work, the daily grind, and gray weekdays, and for those who would embark on a new adventure, explore the area, while collecting memorable moments, tasting wines made from locally grown grapes, accompanied by delicious Zala delicacies."
        }
        else if (langId === "DE") { 
            return "Alle drei unserer Holzhäuser bieten ein unvergessliches Erlebnis für diejenigen, die nach harter Arbeit, dem täglichen Trott und grauen Wochentagen auf der Insel des Friedens entspannen, auftanken und Qualitätszeit verbringen möchten, und für diejenigen, die ein neues Abenteuer beginnen, die Gegend erkunden, unvergessliche Momente sammeln, Weine aus lokal angebauten Trauben probieren, begleitet von köstlichen Zala-Spezialitäten."
        }
        else { 
            return "Mindhárom faházunk kihagyhatatlan élményt nyújt azoknak, akik a kemény munka, a mókuskerék és a szürke hétköznapok után szeretnének egy kicsit a béke szigetén pihenni, feltöltődni, minőségi időt eltölteni és azoknak, akik új kalandra indulnának, felfedeznék a környéket, miközben emlékezetes pillanatokat gyűjtenek, helyben termett szőlőből készült borokat kóstolnak, ízletes zalai falatok kíséretében."
        }
    }
    const LangGallery = () => {
        if (langId === "HU") { return "Galéria" }
        else if (langId === "EN") { return "Gallery" }
        else if (langId === "DE") { return "Galerie" }
        else { return "Galéria" }
    }
    const LangContact = () => {
        if (langId === "HU") { return "Kapcsolat" }
        else if (langId === "EN") { return "Contact" }
        else if (langId === "DE") { return "Kontakt" }
        else { return "Kapcsolat" }
    }
    const LangPhone = () => {
        if (langId === "HU") { return "Telefon" }
        else if (langId === "EN") { return "Phone" }
        else if (langId === "DE") { return "Telefon" }
        else { return "Telefon" }
    }
    const LangTermsAndConditions = () => {
        if (langId === "HU") { return "ÁSZF és házirend" }
        else if (langId === "EN") { return "Terms and Conditions" }
        else if (langId === "DE") { return "AGB und Hausordnung" }
        else { return "ÁSZF és házirend" }
    }
    const LangPrivacyPolicy = () => {
        if (langId === "HU") { return "Adatkezelési tájékoztató" }
        else if (langId === "EN") { return "Privacy Policy" }
        else if (langId === "DE") { return "Datenschutzerklärung" }
        else { return "Adatkezelési tájékoztató" }
    }
    const LangImprint = () => {
        if (langId === "HU") { return "Impresszum" }
        else if (langId === "EN") { return "Imprint" }
        else if (langId === "DE") { return "Impressum" }
        else { return "Impresszum" }
    }
    const LangAllRightsReserved = () => {
        if (langId === "HU") { return "Minden jog fenntartva." }
        else if (langId === "EN") { return "All rights reserved." }
        else if (langId === "DE") { return "Alle Rechte vorbehalten." }
        else { return "Minden jog fenntartva." }
    }
    //InfoPage
    const LangInfoAboutHouses = () => {
        if (langId === "HU") { return "Információ a házainkról" }
        else if (langId === "EN") { return "Information about our houses" }
        else if (langId === "DE") { return "Informationen über unsere Häuser" }
        else { return "Információ a házainkról" }
    }
    
    const LangInfoBookingConditions = () => {
        if (langId === "HU") { 
            return "Kérjük foglalás előtt vegyétek figyelembe az alábbi feltételeket."; 
        } else if (langId === "EN") { 
            return "Please consider the following conditions before booking."; 
        } else if (langId === "DE") { 
            return "Bitte beachten Sie die folgenden Bedingungen vor der Buchung."; 
        } else { 
            return "Kérjük foglalás előtt vegyétek figyelembe az alábbi feltételeket."; 
        }
    };
    
    const LangInfoYearRound = () => {
        if (langId === "HU") { return "A Kuruk Hill egész évben fogadja vendégeit." }
        else if (langId === "EN") { return "Kuruk Hill welcomes guests all year round." }
        else if (langId === "DE") { return "Kuruk Hill begrüßt das ganze Jahr über Gäste." }
        else { return "A Kuruk Hill egész évben fogadja vendégeit." }
    }
    
    const LangInfoMaxTwoPeople = () => {
        if (langId === "HU") { return "A faházaink maximum 2 személy fogadására alkalmasak." }
        else if (langId === "EN") { return "Our wooden houses can accommodate a maximum of 2 people." }
        else if (langId === "DE") { return "Unsere Holzhäuser bieten Platz für maximal 2 Personen." }
        else { return "A faházaink maximum 2 személy fogadására alkalmasak." }
    }
    
    const LangInfoBookingDuration = () => {
        if (langId === "HU") { return "1 éjszakától maximum 7 éjszakáig foglalható." }
        else if (langId === "EN") { return "Bookable from 1 night to a maximum of 7 nights." }
        else if (langId === "DE") { return "Buchbar von 1 Nächten bis maximal 7 Nächten." }
        else { return "Minimum 2 éjszakától maximum 7 éjszakáig foglalható." }
    }
    
    const LangInfoNoPets = () => {
        if (langId === "HU") { return "Házikedvencek fogadására sajnos nincs lehetőségünk." }
        else if (langId === "EN") { return "Unfortunately, we cannot accommodate pets." }
        else if (langId === "DE") { return "Leider können wir keine Haustiere aufnehmen." }
        else { return "Házikedvencek fogadására sajnos nincs lehetőségünk." }
    }
    
    const LangInfoNoSmoking = () => {
        if (langId === "HU") { return "A dohányzás a ház egész területén tilos. (Elektromos cigarettára és IQOS-ra is vonatkozik)" }
        else if (langId === "EN") { return "Smoking is prohibited throughout the house. (This also applies to e-cigarettes and IQOS)" }
        else if (langId === "DE") { return "Das Rauchen ist im gesamten Haus verboten. (Dies gilt auch für E-Zigaretten und IQOS)" }
        else { return "A dohányzás a ház egész területén tilos. (Elektromos cigarettára és IQOS-ra is vonatkozik)" }
    }
    
    const LangInfoThankYou = () => {
        if (langId === "HU") { return "Köszönjük megértéseteket!" }
        else if (langId === "EN") { return "Thank you for your understanding!" }
        else if (langId === "DE") { return "Vielen Dank für Ihr Verständnis!" }
        else { return "Köszönjük megértéseteket!" }
    }
    
    const LangInfoParameters = () => {
        if (langId === "HU") { return "Paraméterek és felszereltség" }
        else if (langId === "EN") { return "Parameters and equipment" }
        else if (langId === "DE") { return "Parameter und Ausstattung" }
        else { return "Paraméterek és felszereltség" }
    }
    
    const LangInfoComfort = () => {
        if (langId === "HU") { 
            return "Házainkat igyekeztünk a lehető legkomfortosabban berendezni, ha valamiről mégis megfelejtkeztünk, vagy úgy érzitek, valami még hiányzik, kérdezzetek rá nyugodtan!"
        }
        else if (langId === "EN") { 
            return "We have tried to furnish our houses as comfortably as possible, but if we have forgotten something or you feel something is missing, please feel free to ask!"
        }
        else if (langId === "DE") { 
            return "Wir haben versucht, unsere Häuser so komfortabel wie möglich einzurichten, aber wenn wir etwas vergessen haben oder Ihnen etwas fehlt, fragen Sie bitte gerne nach!"
        }
        else { 
            return "Házainkat igyekeztünk a lehető legkomfortosabban berendezni, ha valamiről mégis megfelejtkeztünk, vagy úgy érzitek, valami még hiányzik, kérdezzetek rá nyugodtan!"
        }
    }
    
    const LangInfoForgottenItems = () => {
        if (langId === "HU") { 
            return "Ha valamit otthon felejtettetek, ne ijedjetek meg, érdeklődjetek a recepción, vagy a"
        }
        else if (langId === "EN") { 
            return "If you have forgotten something at home, don't worry, ask at the reception or call "
        }
        else if (langId === "DE") { 
            return "Wenn Sie etwas zu Hause vergessen haben, keine Sorge, fragen Sie an der Rezeption oder rufen Sie"
        }
        else { 
            return "Ha valamit otthon felejtettetek, ne ijedjetek meg, érdeklődjetek a recepción, vagy a"
        }
    }
    const LangInfoForgottenItems2 = () => {
        if (langId === "HU") { 
            return "+36 70 942 0610-es számon, segítünk!"
        }
        else if (langId === "EN") { 
            return "+36 70 942 0610, we will help!"
        }
        else if (langId === "DE") { 
            return "+36 70 942 0610 an, wir helfen Ihnen!"
        }
        else { 
            return "+36 70 942 0610-es számon, segítünk!"
        }
    }
    const LangInfoBedroom = () => {
        if (langId === "HU") { return "Hálószoba:" }
        else if (langId === "EN") { return "Bedroom:" }
        else if (langId === "DE") { return "Schlafzimmer:" }
        else { return "Hálószoba:" }
    }
    
    const LangInfoLargeDoubleBed = () => {
        if (langId === "HU") { return "Nagyméretű franciaágy" }
        else if (langId === "EN") { return "Large double bed" }
        else if (langId === "DE") { return "Großes Doppelbett" }
        else { return "Nagyméretű franciaágy" }
    }
    
    const LangInfoQualityBedding = () => {
        if (langId === "HU") { return "Minőségi antiallergén ágynemű, 2 db párna fejenként" }
        else if (langId === "EN") { return "Quality hypoallergenic bedding, 2 pillows per person" }
        else if (langId === "DE") { return "Hochwertige antiallergische Bettwäsche, 2 Kissen pro Person" }
        else { return "Minőségi antiallergén ágynemű, 2 db párna fejenként" }
    }
    
    const LangInfoBookshelf = () => {
        if (langId === "HU") { return "Könyvespolc, külön Nektek összeválogatott könyvekkel" }
        else if (langId === "EN") { return "Bookshelf with books specially selected for you" }
        else if (langId === "DE") { return "Bücherregal mit speziell für Sie ausgewählten Büchern" }
        else { return "Könyvespolc, külön Nektek összeválogatott könyvekkel" }
    }
    
    const LangInfoReadingLamp = () => {
        if (langId === "HU") { return "Olvasólámpa és éjjeli szekrények" }
        else if (langId === "EN") { return "Reading lamp and nightstands" }
        else if (langId === "DE") { return "Leselampe und Nachttische" }
        else { return "Olvasólámpa és éjjeli szekrények" }
    }
    
    const LangInfoBoardGames = () => {
        if (langId === "HU") { return "Izgalmas társasjátékok, a móka kedvéért" }
        else if (langId === "EN") { return "Exciting board games for fun" }
        else if (langId === "DE") { return "Spannende Brettspiele für Spaß" }
        else { return "Izgalmas társasjátékok, a móka kedvéért" }
    }
    
    const LangInfoAdjustableTV = () => {
        if (langId === "HU") { return "Állítható QLED TV, Netflix, HBO MAX és Disney + szolgáltatással" }
        else if (langId === "EN") { return "Adjustable QLED TV with Netflix, HBO MAX, and Disney+ service" }
        else if (langId === "DE") { return "Verstellbarer QLED-TV mit Netflix-, HBO MAX- und Disney+-Dienst" }
        else { return "Állítható QLED TV, Netflix, HBO MAX és Disney + szolgáltatással" }
    }
    
    const LangInfoSafe = () => {
        if (langId === "HU") { return "Széf" }
        else if (langId === "EN") { return "Safe" }
        else if (langId === "DE") { return "Safe" }
        else { return "Széf" }
    }
    
    const LangInfoIron = () => {
        if (langId === "HU") { return "Vasaló" }
        else if (langId === "EN") { return "Iron" }
        else if (langId === "DE") { return "Bügeleisen" }
        else { return "Vasaló" }
    }
    
    const LangInfoSlippers = () => {
        if (langId === "HU") { return "Szobapapucs" }
        else if (langId === "EN") { return "Slippers" }
        else if (langId === "DE") { return "Hausschuhe" }
        else { return "Szobapapucs" }
    }
    
    const LangInfoKitchenDining = () => {
        if (langId === "HU") { return "Konyha-Étkező:" }
        else if (langId === "EN") { return "Kitchen-Dining:" }
        else if (langId === "DE") { return "Küche-Esszimmer:" }
        else { return "Konyha-Étkező:" }
    }
    
    const LangInfoDiningCounter = () => {
        if (langId === "HU") { return "Étkező pult bárszékekkel" }
        else if (langId === "EN") { return "Dining counter with bar stools" }
        else if (langId === "DE") { return "Esstheke mit Barhockern" }
        else { return "Étkező pult bárszékekkel" }
    }
    
    const LangInfoWineFridge = () => {
        if (langId === "HU") { 
            return "Borhűtő, gondosan válogatott borokkal feltöltve, melyet itt tartózkodásotok ideje alatt is szívesen utántöltünk nektek (a borhűtő, mint minibár működik, a benne elhelyezett italok fogyasztása díj ellenében történik. A borhűtőből való fogyasztás ellenértékét kijelentkezéskor kell kifizetni. Árlista az információs mappában található)"
        }
        else if (langId === "EN") { 
            return "Wine fridge filled with carefully selected wines, which we are happy to replenish during your stay (the wine fridge functions as a minibar; the consumption of drinks is subject to a fee. The cost of consumption from the wine fridge must be paid upon checkout. Price list can be found in the information folder)"
        }
        else if (langId === "DE") { 
            return "Weinkühlschrank, gefüllt mit sorgfältig ausgewählten Weinen, die wir während Ihres Aufenthalts gerne nachfüllen (der Weinkühlschrank fungiert als Minibar; der Konsum von Getränken ist kostenpflichtig. Die Kosten für den Konsum aus dem Weinkühlschrank müssen beim Auschecken bezahlt werden. Die Preisliste finden Sie im Informationsordner)"
        }
        else { 
            return "Borhűtő, gondosan válogatott borokkal feltöltve, melyet itt tartózkodásotok ideje alatt is szívesen utántöltünk nektek (a borhűtő, mint minibár működik, a benne elhelyezett italok fogyasztása díj ellenében történik. A borhűtőből való fogyasztás ellenértékét kijelentkezéskor kell kifizetni. Árlista az információs mappában található)"
        }
    }
    
    const LangInfoPlateSet = () => {
        if (langId === "HU") { return "Tányér- és evőeszközkészlet" }
        else if (langId === "EN") { return "Plate and cutlery set" }
        else if (langId === "DE") { return "Teller- und Besteckset" }
        else { return "Tányér- és evőeszközkészlet" }
    }
    
    const LangInfoGlassSet = () => {
        if (langId === "HU") { return "Pohárkészlet különböző méretű és funkciójú poharakkal, csészékkel" }
        else if (langId === "EN") { return "Glass set with various sizes and types of glasses, cups" }
        else if (langId === "DE") { return "Gläserset mit verschiedenen Größen und Arten von Gläsern, Tassen" }
        else { return "Pohárkészlet különböző méretű és funkciójú poharakkal, csészékkel" }
    }
    
    const LangInfoQualityKnives = () => {
        if (langId === "HU") { return "Minőségi kések" }
        else if (langId === "EN") { return "Quality knives" }
        else if (langId === "DE") { return "Qualitätsmesser" }
        else { return "Minőségi kések" }
    }
    
    const LangInfoBerlingerPots = () => {
        if (langId === "HU") { return "Berlinger Haus edény és serpenyő készlet" }
        else if (langId === "EN") { return "Berlinger Haus pot and pan set" }
        else if (langId === "DE") { return "Berlinger Haus Topf- und Pfannenset" }
        else { return "Berlinger Haus edény és serpenyő készlet" }
    }
    
    const LangInfoCeramicHob = () => {
        if (langId === "HU") { return "2 zónás kerámia főzőlap" }
        else if (langId === "EN") { return "2-zone ceramic hob" }
        else if (langId === "DE") { return "2-Zonen-Keramikkochfeld" }
        else { return "2 zónás kerámia főzőlap" }
    }
    
    const LangInfoFridge = () => {
        if (langId === "HU") { return "Hűtőszekrény mélyhűtőrésszel" }
        else if (langId === "EN") { return "Refrigerator with freezer compartment" }
        else if (langId === "DE") { return "Kühlschrank mit Gefrierfach" }
        else { return "Hűtőszekrény mélyhűtőrésszel" }
    }
    
    const LangInfoToaster = () => {
        if (langId === "HU") { return "Kenyérpirító" }
        else if (langId === "EN") { return "Toaster" }
        else if (langId === "DE") { return "Toaster" }
        else { return "Kenyérpirító" }
    }
    
    const LangInfoDishwasher = () => {
        if (langId === "HU") { return "Mosogató gép" }
        else if (langId === "EN") { return "Dishwasher" }
        else if (langId === "DE") { return "Geschirrspüler" }
        else { return "Mosogató gép" }
    }
    
    const LangInfoBasicFood = () => {
        if (langId === "HU") {
          return "Nespresso kávéfőző kapszulákkal, palackozott ásványvíz";
        } else if (langId === "EN") {
          return "Nespresso coffee machine with capsules, bottled mineral water";
        } else if (langId === "DE") {
          return "Nespresso-Kaffeemaschine mit Kapseln, Mineralwasser in Flaschen";
        } else {
          return "Nespresso kávéfőző kapszulákkal, palackozott ásványvíz";
        }
      };
    
    const LangInfoCleaningSupplies = () => {
        if (langId === "HU") { return "Tisztítószerek, papírtörlő, szalvéta, konyharuha" }
        else if (langId === "EN") { return "Cleaning supplies, paper towels, napkins, kitchen towel" }
        else if (langId === "DE") { return "Reinigungsmittel, Papiertücher, Servietten, Küchentuch" }
        else { return "Tisztítószerek, papírtörlő, szalvéta, konyharuha" }
    }
    const LangInfoServicesAndPrices = () => {
        if (langId === "HU") { return "Szolgáltatások és árak" }
        else if (langId === "EN") { return "Services and Prices" }
        else if (langId === "DE") { return "Dienstleistungen und Preise" }
        else { return "Szolgáltatások és árak" }
    }
    
    const LangInfoMinTwoNights = () => {
        if (langId === "HU") { return "Faházaink akár 1 éjszakára is foglalhatók." }
        else if (langId === "EN") { return "Our cabins can be booked for even 1 night." }
        else if (langId === "DE") { return "Unsere Hütten können auch für 1 Nacht gebucht werden." }
        else { return "Faházaink akár 1 éjszakára is foglalhatók." }
    }
    
    const LangInfoPricesForTwo = () => {
        if (langId === "HU") { return "Az árak 2 személyre vonatkoznak." }
        else if (langId === "EN") { return "Prices are for 2 people." }
        else if (langId === "DE") { return "Preise gelten für 2 Personen." }
        else { return "Az árak 2 személyre vonatkoznak." }
    }
    
    const LangInfoMagicMango = () => {
        if (langId === "HU") { 
            return "Magic Mango: - Hétköznap: 70 000Ft/éj - Hétvégén: 75 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "Magic Mango: - Weekdays: 70 000Huf/night - Weekends: 75 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "Magic Mango: - Wochentags: 70 000Huf/Nacht - Wochenenden: 75 000Huf/Nacht" 
        }
        else { 
            return "Magic Mango: - Hétköznap: 70 000Ft/éj - Hétvégén: 75 000Ft/éj" 
        }
    }
    
    const LangInfoCrystalPower = () => {
        if (langId === "HU") { 
            return "Crystal Power: - Hétköznap: 75 000Ft/éj - Hétvégén: 80 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "Crystal Power: - Weekdays: 75 000Huf/night - Weekends: 80 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "Crystal Power: - Wochentags: 75 000Huf/Nacht - Wochenenden: 80 000Huf/Nacht" 
        }
        else { 
            return "Crystal Power: - Hétköznap: 75 000Ft/éj - Hétvégén: 80 000Ft/éj" 
        }
    }
    
    const LangInfoGreenPassion = () => {
        if (langId === "HU") { 
            return "Green Passion: - Hétköznap: 80 000Ft/éj - Hétvégén: 85 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "Green Passion: - Weekdays: 80 000Huf/night - Weekends: 85 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "Green Passion: - Wochentags: 80 000Huf/Nacht - Wochenenden: 85.000Huf/Nacht" 
        }
        else { 
            return "Green Passion: - Hétköznap: 80 000Ft/éj - Hétvégén: 85 000Ft/éj" 
        }
    }
    const LangInfoMagicMangoP1 = () => {
        if (langId === "HU") { 
            return "- Hétköznap: 70 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "- Weekdays: 70 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "- Wochentags: 70 000Huf/Nacht" 
        }
        else { 
            return "- Hétköznap: 70 000Ft/éj" 
        }
    }
    const LangInfoMagicMangoP2 = () => {
        if (langId === "HU") { 
            return "- Hétvégén: 75 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "- Weekends: 75 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "- Wochenenden: 75 000Huf/Nacht" 
        }
        else { 
            return "- Hétvégén: 75 000Ft/éj" 
        }
    }
    
    const LangInfoCrystalPowerP1 = () => {
        if (langId === "HU") { 
            return "- Hétköznap: 75 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "- Weekdays: 75 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "- Wochentags: 75 000Huf/Nacht" 
        }
        else { 
            return "- Hétköznap: 75 000Ft/éj" 
        }
    }
    const LangInfoCrystalPowerP2 = () => {
        if (langId === "HU") { 
            return "- Hétvégén: 80 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "- Weekends: 80 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "- Wochenenden: 80 000Huf/Nacht" 
        }
        else { 
            return "- Hétvégén: 80 000Ft/éj" 
        }
    }
    
    const LangInfoGreenPassionP1 = () => {
        if (langId === "HU") { 
            return "- Hétköznap: 80 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "- Weekdays: 80 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "- Wochentags: 80 000Huf/Nacht" 
        }
        else { 
            return "- Hétköznap: 80 000Ft/éj" 
        }
    }
    const LangInfoGreenPassionP2 = () => {
        if (langId === "HU") { 
            return "- Hétvégén: 85 000Ft/éj" 
        }
        else if (langId === "EN") { 
            return "- Weekends: 85 000Huf/night" 
        }
        else if (langId === "DE") { 
            return "- Wochenenden: 85 000Huf/Nacht" 
        }
        else { 
            return "- Hétvégén: 85 000Ft/éj" 
        }
    }
    
    const LangInfoSpecialDays = () => {
        if (langId === "HU") { return "Kiemelt napokon:" }
        else if (langId === "EN") { return "On special days:" }
        else if (langId === "DE") { return "An besonderen Tagen:" }
        else { return "Kiemelt napokon:" }
    }
    
    const LangInfoMinTwoNightsRepeat = () => {
        if (langId === "HU") { return "Faházaink minimum 2 éjszakára foglalhatóak." }
        else if (langId === "EN") { return "Our wooden houses can be booked for a minimum of 2 nights." }
        else if (langId === "DE") { return "Unsere Holzhäuser können für mindestens 2 Nächte gebucht werden." }
        else { return "Faházaink minimum 2 éjszakára foglalhatóak." }
    }
    
    const LangInfoHolidaySurcharge = () => {
        if (langId === "HU") { return "December 31. és Január 1. között az alapár +10 000Ft/éj" }
        else if (langId === "EN") { return "Between December 31 and January 1, the base price is +10 000Huf/night" }
        else if (langId === "DE") { return "Zwischen dem 31. Dezember und dem 1. Januar beträgt der Basispreis +10 000Huf/Nacht" }
        else { return "December 31. és Január 1. között az alapár +10 000Ft/éj" }
    }
    
    const LangInfoHighlightedDays = () => {
        if (langId === "HU") { return "Kiemelt napok:" }
        else if (langId === "EN") { return "Highlighted days:" }
        else if (langId === "DE") { return "Hervorgehobene Tage:" }
        else { return "Kiemelt napok:" }
    }
    
    const LangInfoMarch14_17 = () => {
        if (langId === "HU") { return "március 14-17." }
        else if (langId === "EN") { return "March 14-17." }
        else if (langId === "DE") { return "14.-17. März" }
        else { return "március 14-17." }
    }
    
    const LangInfoMarch28_April1 = () => {
        if (langId === "HU") { return "március 28-április 1." }
        else if (langId === "EN") { return "March 28-April 1." }
        else if (langId === "DE") { return "28. März - 1. April" }
        else { return "március 28-április 1." }
    }
    
    const LangInfoAugust17_20 = () => {
        if (langId === "HU") { return "augusztus 17-20." }
        else if (langId === "EN") { return "August 17-20." }
        else if (langId === "DE") { return "17.-20. August" }
        else { return "augusztus 17-20." }
    }
    
    const LangInfoOctober31_November3 = () => {
        if (langId === "HU") { return "október 31-november 3." }
        else if (langId === "EN") { return "October 31-November 3." }
        else if (langId === "DE") { return "31. Oktober - 3. November" }
        else { return "október 31-november 3." }
    }
    
    const LangInfoDecember24_January2 = () => {
        if (langId === "HU") { return "december 24-január 2." }
        else if (langId === "EN") { return "December 24-January 2." }
        else if (langId === "DE") { return "24. Dezember - 2. Januar" }
        else { return "december 24-január 2." }
    }
    
    const LangInfoBookingPaymentCancellation = () => {
        if (langId === "HU") { return "Foglalási, fizetési, lemondási feltételeket" }
        else if (langId === "EN") { return "Booking, payment, and cancellation conditions" }
        else if (langId === "DE") { return "Buchungs-, Zahlungs- und Stornierungsbedingungen" }
        else { return "Foglalási, fizetési, lemondási feltételeket" }
    }
    
    const LangInfoBookingPaymentCancellationDetails = () => {
        if (langId === "HU") { 
            return "egyéb szabályzatokkal együtt." 
        }
        else if (langId === "EN") { 
            return " the attached document below." 
        }
        else if (langId === "DE") { 
            return "unten eingesehen werden." 
        }
        else { 
            return "egyéb szabályzatokkal együtt." 
        }
    }
    const LangInfoBookingPaymentCancellationDetails2 = () => {
        if (langId === "HU") { 
            return "az alábbiakban csatolt dokumentum letöltésével tudjátok megtekinteni a házirenddel és" 
        }
        else if (langId === "EN") { 
            return " as well as house rules and other regulations, by downloading" 
        }
        else if (langId === "DE") { 
            return " können durch Herunterladen des beigefügten Dokuments" 
        }
        else { 
            return "az alábbiakban csatolt dokumentum letöltésével tudjátok megtekinteni a házirenddel és" 
        }
    }
    const LangInfoBookingPaymentCancellationDetails3 = () => {
        if (langId === "HU") { 
            return "Foglalási, fizetési illetve lemondási feltételeket" 
        }
        else if (langId === "EN") { 
            return "You can view the booking, payment, and cancellation conditions," 
        }
        else if (langId === "DE") { 
            return "Die Buchungs-, Zahlungs- und Stornierungsbedingungen sowie Hausregeln und andere Vorschriften" 
        }
        else { 
            return "Foglalási, fizetési illetve lemondási feltételeket" 
        }
    }
    const LangInfoProceedToBooking = () => {
        if (langId === "HU") { return "Tovább a foglaláshoz" }
        else if (langId === "EN") { return "Proceed to booking" }
        else if (langId === "DE") { return "Weiter zur Buchung" }
        else { return "Tovább a foglaláshoz" }
    }
    //CrystalPower
    const LangCryPBedroomDescription = () => {
        if (langId === "HU") { 
            return "Hálószobában egy nagyméretű franciaágy található, amelyet minőségi antiallergén ágyneművel és 2 párnával láttunk el fejenként. Ezenkívül van egy könyvespolc, melyet külön Nektek összeválogatott könyvekkel töltöttünk meg. Az olvasáshoz és pihenéshez olvasólámpa és éjjeli szekrények állnak rendelkezésre. Ha inkább szórakoznátok, izgalmas társasjátékok várnak Titeket. Ha pedig inkább filmnézés a program, egy állítható QLED TV áll rendelkezésetekre Netflix, HBO MAX és Disney + szolgáltatással. A biztonságos tárolást széf biztosítja, emellett pedig vasaló és szobapapucs is rendelkezésre állnak."
        }
        else if (langId === "EN") { 
            return "The bedroom features a large double bed equipped with quality hypoallergenic bedding and 2 pillows per person. Additionally, there is a bookshelf filled with books specially selected for you. For reading and relaxation, a reading lamp and nightstands are available. If you prefer entertainment, exciting board games await you. For movie nights, an adjustable QLED TV with Netflix, HBO MAX, and Disney+ service is at your disposal. A safe ensures secure storage, and an iron and slippers are also provided."
        }
        else if (langId === "DE") { 
            return "Das Schlafzimmer verfügt über ein großes Doppelbett mit hochwertiger antiallergischer Bettwäsche und 2 Kissen pro Person. Außerdem gibt es ein Bücherregal mit speziell für Sie ausgewählten Büchern. Für das Lesen und Entspannen stehen eine Leselampe und Nachttische zur Verfügung. Wenn Sie Unterhaltung bevorzugen, warten spannende Brettspiele auf Sie. Für Filmabende steht Ihnen ein verstellbarer QLED-TV mit Netflix-, HBO MAX- und Disney+-Dienst zur Verfügung. Ein Safe sorgt für sichere Aufbewahrung, und ein Bügeleisen und Hausschuhe sind ebenfalls vorhanden."
        }
        else { 
            return "Hálószobában egy nagyméretű franciaágy található, amelyet minőségi antiallergén ágyneművel és 2 párnával láttunk el fejenként. Ezenkívül van egy könyvespolc, melyet külön Nektek összeválogatott könyvekkel töltöttünk meg. Az olvasáshoz és pihenéshez olvasólámpa és éjjeli szekrények állnak rendelkezésre. Ha inkább szórakoznátok, izgalmas társasjátékok várnak Titeket. Ha pedig inkább filmnézés a program, egy állítható QLED TV áll rendelkezésetekre Netflix, HBO MAX és Disney + szolgáltatással. A biztonságos tárolást széf biztosítja, emellett pedig vasaló és szobapapucs is rendelkezésre állnak."
        }
    }
    
    const LangCryPKitchen = () => {
        if (langId === "HU") { return "Konyha" }
        else if (langId === "EN") { return "Kitchen" }
        else if (langId === "DE") { return "Küche" }
        else { return "Konyha" }
    }
    
    const LangCryPTV = () => {
        if (langId === "HU") { return "TV" }
        else if (langId === "EN") { return "TV" }
        else if (langId === "DE") { return "Fernseher" }
        else { return "TV" }
    }
    
    const LangCryPWiFi = () => {
        if (langId === "HU") { return "WI-FI" }
        else if (langId === "EN") { return "WI-FI" }
        else if (langId === "DE") { return "WLAN" }
        else { return "WI-FI" }
    }
    
    const LangCryPBreakfast = () => {
        if (langId === "HU") { return "Reggeli (Extra szolgáltatás)" }
        else if (langId === "EN") { return "Breakfast (Extra service)" }
        else if (langId === "DE") { return "Frühstück (Zusatzservice)" }
        else { return "Reggeli (Extra szolgáltatás)" }
    }
    
    const LangCryPParking = () => {
        if (langId === "HU") { return "Parkolás" }
        else if (langId === "EN") { return "Parking" }
        else if (langId === "DE") { return "Parkplatz" }
        else { return "Parkolás" }
    }
    
    const LangCryPJacuzzi = () => {
        if (langId === "HU") { return "Jakuzzi" }
        else if (langId === "EN") { return "Jacuzzi" }
        else if (langId === "DE") { return "Whirlpool" }
        else { return "Jakuzzi" }
    }
    
    const LangCryPAirConditioning = () => {
        if (langId === "HU") { return "Légkondi" }
        else if (langId === "EN") { return "Air Conditioning" }
        else if (langId === "DE") { return "Klimaanlage" }
        else { return "Légkondi" }
    }
    
    const LangCryPKitchenDining = () => {
        if (langId === "HU") { 
          return "A konyha-étkező részben található egy étkező pult bárszékekkel, és egy borhűtő, mely gondosan válogatott borokkal van feltöltve. A borhűtő működése hasonló egy minibáréhoz; a benne lévő italok fogyasztása díj ellenében történik. A kifogyott italokat szívesen utántöltjük tartózkodásuk ideje alatt, és az ellenértéküket kijelentkezéskor kell kifizetni. Az étkező részben megtalálható tányér- és evőeszközkészlet, valamint pohárkészlet különböző méretű és funkciójú poharakkal és csészékkel. A konyhában minőségi kések, Berlinger Haus edény és serpenyő készlet, 2 zónás kerámia főzőlap, hűtőszekrény mélyhűtőrésszel, kenyérpirító, Nespresso kávéfőző kapszulákkal, valamint palackozott ásványvízzel várunk titeket. A mosogatógép és az alapvető tisztítószerek biztosítják a könnyű takarítást, és a bekészített papírtörlők, szalvéták és konyharuhák pedig segítenek a rendet és tisztaságot megőrizni.";
        } else if (langId === "EN") { 
          return "In the kitchen-dining area, there is a dining counter with bar stools and a wine fridge stocked with carefully selected wines. The wine fridge functions similarly to a minibar; the drinks inside are consumed for a fee. We are happy to replenish any empty bottles during your stay, and the cost of consumption is payable at checkout. The dining area includes a plate and cutlery set, as well as a glass set with various sizes and types of glasses and cups. The kitchen is equipped with quality knives, Berlinger Haus pot and pan set, 2-zone ceramic hob, refrigerator with freezer compartment, toaster, Nespresso coffee machine with capsules, as well as bottled mineral water. A dishwasher and basic cleaning supplies ensure easy cleaning, and provided paper towels, napkins, and kitchen towels help maintain order and cleanliness.";
        } else if (langId === "DE") { 
          return "Im Küchen-Essbereich gibt es eine Esstheke mit Barhockern und einen Weinkühlschrank, der mit sorgfältig ausgewählten Weinen gefüllt ist. Der Weinkühlschrank funktioniert ähnlich wie eine Minibar; die Getränke darin werden gegen Gebühr konsumiert. Wir füllen die leeren Flaschen während Ihres Aufenthalts gerne nach, und die Kosten für den Konsum sind beim Check-out zu bezahlen. Der Essbereich enthält ein Teller- und Besteckset sowie ein Gläserset mit verschiedenen Größen und Arten von Gläsern und Tassen. Die Küche ist mit Qualitätsmessern, Berlinger Haus Topf- und Pfannenset, 2-Zonen-Keramikkochfeld, Kühlschrank mit Gefrierfach, Toaster, Nespresso-Kaffeemaschine mit Kapseln sowie Mineralwasser in Flaschen ausgestattet. Ein Geschirrspüler und grundlegende Reinigungsmittel sorgen für einfache Reinigung, und bereitgestellte Papiertücher, Servietten und Küchentücher helfen, Ordnung und Sauberkeit zu bewahren.";
        } else { 
          return "A konyha-étkező részben található egy étkező pult bárszékekkel, és egy borhűtő, mely gondosan válogatott borokkal van feltöltve. A borhűtő működése hasonló egy minibáréhoz; a benne lévő italok fogyasztása díj ellenében történik. A kifogyott italokat szívesen utántöltjük tartózkodásuk ideje alatt, és az ellenértéküket kijelentkezéskor kell kifizetni. Az étkező részben megtalálható tányér- és evőeszközkészlet, valamint pohárkészlet különböző méretű és funkciójú poharakkal és csészékkel. A konyhában minőségi kések, Berlinger Haus edény és serpenyő készlet, 2 zónás kerámia főzőlap, hűtőszekrény mélyhűtőrésszel, kenyérpirító, Nespresso kávéfőző kapszulákkal, valamint palackozott ásványvízzel várunk titeket. A mosogatógép és az alapvető tisztítószerek biztosítják a könnyű takarítást, és a bekészített papírtörlők, szalvéták és konyharuhák pedig segítenek a rendet és tisztaságot megőrizni.";
        }
      };
    
    const LangCryPProceedToBooking = () => {
        if (langId === "HU") { return "Tovább a foglaláshoz" }
        else if (langId === "EN") { return "Proceed to booking" }
        else if (langId === "DE") { return "Weiter zur Buchung" }
        else { return "Tovább a foglaláshoz" }
    }
    const NaptarGuest1FullName = () => {
        if (langId === "HU") { return "Vendég 1 teljes neve" }
        else if (langId === "EN") { return "Guest 1 Full Name" }
        else if (langId === "DE") { return "Gast 1 Vollständiger Name" }
        else { return "Vendég 1 teljes neve" }
    }
    
    const NaptarGuest2FullName = () => {
        if (langId === "HU") { return "Vendég 2 teljes neve" }
        else if (langId === "EN") { return "Guest 2 Full Name" }
        else if (langId === "DE") { return "Gast 2 Vollständiger Name" }
        else { return "Vendég 2 teljes neve" }
    }
    
    const NaptarGuest3FullName = () => {
        if (langId === "HU") { return "Vendég 3 teljes neve" }
        else if (langId === "EN") { return "Guest 3 Full Name" }
        else if (langId === "DE") { return "Gast 3 Vollständiger Name" }
        else { return "Vendég 3 teljes neve" }
    }
    
    const NaptarGuest4FullName = () => {
        if (langId === "HU") { return "Vendég 4 teljes neve" }
        else if (langId === "EN") { return "Guest 4 Full Name" }
        else if (langId === "DE") { return "Gast 4 Vollständiger Name" }
        else { return "Vendég 4 teljes neve" }
    }
    
    const NaptarGuest5FullName = () => {
        if (langId === "HU") { return "Vendég 5 teljes neve" }
        else if (langId === "EN") { return "Guest 5 Full Name" }
        else if (langId === "DE") { return "Gast 5 Vollständiger Name" }
        else { return "Vendég 5 teljes neve" }
    }
    
    const NaptarGuest6FullName = () => {
        if (langId === "HU") { return "Vendég 6 teljes neve" }
        else if (langId === "EN") { return "Guest 6 Full Name" }
        else if (langId === "DE") { return "Gast 6 Vollständiger Name" }
        else { return "Vendég 6 teljes neve" }
    }
    
    const NaptarCompanyNameOptional = () => {
        if (langId === "HU") { return "Cégnév (Opcionális)" }
        else if (langId === "EN") { return "Company Name (Optional)" }
        else if (langId === "DE") { return "Firmenname (Optional)" }
        else { return "Cégnév (Opcionális)" }
    }
    
    const NaptarTaxNumberOptional = () => {
        if (langId === "HU") { return "Adószám (Opcionális)" }
        else if (langId === "EN") { return "Tax Number (Optional)" }
        else if (langId === "DE") { return "Steuernummer (Optional)" }
        else { return "Adószám (Opcionális)" }
    }
    
    const NaptarPostalCode = () => {
        if (langId === "HU") { return "Irányítószám" }
        else if (langId === "EN") { return "Postal Code" }
        else if (langId === "DE") { return "Postleitzahl" }
        else { return "Irányítószám" }
    }
    
    const NaptarCity = () => {
        if (langId === "HU") { return "Város" }
        else if (langId === "EN") { return "City" }
        else if (langId === "DE") { return "Stadt" }
        else { return "Város" }
    }
    
    const NaptarAddress = () => {
        if (langId === "HU") { return "Cím" }
        else if (langId === "EN") { return "Address" }
        else if (langId === "DE") { return "Adresse" }
        else { return "Cím" }
    }
    
    const NaptarCountry = () => {
        if (langId === "HU") { return "Ország" }
        else if (langId === "EN") { return "Country" }
        else if (langId === "DE") { return "Land" }
        else { return "Ország" }
    }
    
    const NaptarEmail = () => {
        if (langId === "HU") { return "E-mail" }
        else if (langId === "EN") { return "E-mail" }
        else if (langId === "DE") { return "E-mail" }
        else { return "E-mail" }
    }
    
    const NaptarPhoneNumber = () => {
        if (langId === "HU") { return "Telefonszám" }
        else if (langId === "EN") { return "Phone Number" }
        else if (langId === "DE") { return "Telefonnummer" }
        else { return "Telefonszám" }
    }
    
    const NaptarNotesOptional = () => {
        if (langId === "HU") { return "Megjegyzés (Opcionális)" }
        else if (langId === "EN") { return "Notes (Optional)" }
        else if (langId === "DE") { return "Bemerkung (Optional)" }
        else { return "Megjegyzés (Opcionális)" }
    }
    
    const NaptarWouldYouLikeBreakfast = () => {
        if (langId === "HU") { return "Szeretnétek reggelit a következő házakhoz?" }
        else if (langId === "EN") { return "Would you like breakfast for the following houses?" }
        else if (langId === "DE") { return "Möchten Sie Frühstück für die folgenden Häuser?" }
        else { return "Szeretnétek reggelit a következő házakhoz?" }
    }
    
    const NaptarCreditCardPayment = () => {
        if (langId === "HU") { return "Bankkártyás fizetés" }
        else if (langId === "EN") { return "Credit Card Payment" }
        else if (langId === "DE") { return "Kreditkartenzahlung" }
        else { return "Bankkártyás fizetés" }
    }
    
    const NaptarBankTransfer = () => {
        if (langId === "HU") { return "Banki átutalás" }
        else if (langId === "EN") { return "Bank Transfer" }
        else if (langId === "DE") { return "Banküberweisung" }
        else { return "Banki átutalás" }
    }
    
    const NaptarBack = () => {
        if (langId === "HU") { return "Vissza" }
        else if (langId === "EN") { return "Back" }
        else if (langId === "DE") { return "Zurück" }
        else { return "Vissza" }
    }
    
    const NaptarBookNow = () => {
        if (langId === "HU") { return "Foglalás" }
        else if (langId === "EN") { return "Book Now" }
        else if (langId === "DE") { return "Jetzt Buchen" }
        else { return "Foglalás" }
    }
    const NaptarReadAndAcceptedTerms = () => {
        if (langId === "HU") { return "Elolvastam a házirendet és a foglalási feltételeket, továbbá elfogadom, hogy a foglalás fizetési kötelezettséggel jár. Megértettem és elfogadom az ÁSZF-ben és az Adatkezelési tájékoztatóban foglaltakat." }
        else if (langId === "EN") { return "I have read and accepted the house rules and booking conditions, and I accept that the booking involves a payment obligation. I understand and accept the terms and conditions and the privacy policy." }
        else if (langId === "DE") { return "Ich habe die Hausordnung und die Buchungsbedingungen gelesen und akzeptiere, dass die Buchung eine Zahlungsverpflichtung beinhaltet. Ich verstehe und akzeptiere die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie." }
        else { return "Elolvastam a házirendet és a foglalási feltételeket, továbbá elfogadom, hogy a foglalás fizetési kötelezettséggel jár. Megértettem és elfogadom az ÁSZF-ben és az Adatkezelési tájékoztatóban foglaltakat." }
    }
    
    const NaptarDepositAmount = () => {
        if (langId === "HU") { return "Foglaló összege:" }
        else if (langId === "EN") { return "Deposit Amount:" }
        else if (langId === "DE") { return "Anzahlung:" }
        else { return "Foglaló összege:" }
    }
    const NaptarDepositFullAmount = () => {
        if (langId === "HU") { return "Teljes összeg:" }
        else if (langId === "EN") { return "Total Amount:" }
        else if (langId === "DE") { return "Der Gesamtbetrag:" }
        else { return "Teljes összeg:" }
    }
    
    const NaptarBookingTransferPolicy = () => {
        if (langId === "HU") { 
            return "A foglalás leadását követően, ha 3 napon belül, vagy az érkezés napja előtt egy nappal nem történik meg az átutalás, a foglalás törlésre kerül." 
        }
        else if (langId === "EN") { 
            return "After placing the booking, if the transfer is not completed within 3 days, or at least one day before the arrival date, the booking will be canceled." 
        }
        else if (langId === "DE") { 
            return "Nach der Buchung wird die Buchung storniert, wenn die Überweisung nicht innerhalb von 3 Tagen oder mindestens einen Tag vor dem Anreisedatum erfolgt." 
        }
        else { 
            return "A foglalás leadását követően, ha 3 napon belül, vagy az érkezés napja előtt egy nappal nem történik meg az átutalás, a foglalás törlésre kerül." 
        }
    }
    
    const NaptarCardholderName = () => {
        if (langId === "HU") { return "Kártyatulajdonos neve" }
        else if (langId === "EN") { return "Cardholder Name" }
        else if (langId === "DE") { return "Name des Karteninhabers" }
        else { return "Kártyatulajdonos neve" }
    }
    
    const NaptarIBAN = () => {
        if (langId === "HU") { return "IBAN számlaszám" }
        else if (langId === "EN") { return "IBAN Account Number" }
        else if (langId === "DE") { return "IBAN-Kontonummer" }
        else { return "IBAN számlaszám" }
    }
    
    const NaptarAccountNumber = () => {
        if (langId === "HU") { return "Számlaszám" }
        else if (langId === "EN") { return "Account Number" }
        else if (langId === "DE") { return "Kontonummer" }
        else { return "Számlaszám" }
    }
    
    const NaptarNote = () => {
        if (langId === "HU") { return "Megjegyzés" }
        else if (langId === "EN") { return "Note" }
        else if (langId === "DE") { return "Bemerkung" }
        else { return "Megjegyzés" }
    }
    const translateName = () => {
        if (langId === "HU") { return "Név"; }
        else if (langId === "EN") { return "Name"; }
        else if (langId === "DE") { return "Name"; }
        else { return "Név"; }
    };
    
    const translateFromDate = () => {
        if (langId === "HU") { return "ÉLrkezés Dátuma"; }
        else if (langId === "EN") { return "From Date"; }
        else if (langId === "DE") { return "Von Datum"; }
        else { return "ÉLrkezés Dátuma"; }
    };
    
    const translateToDate = () => {
        if (langId === "HU") { return "Távozás Dátuma"; }
        else if (langId === "EN") { return "To Date"; }
        else if (langId === "DE") { return "Bis Datum"; }
        else { return "Távozás Dátuma"; }
    };
    
    const translateHouses = () => {
        if (langId === "HU") { return "Ház(ak)"; }
        else if (langId === "EN") { return "House(s)"; }
        else if (langId === "DE") { return "Haus(er)"; }
        else { return "Ház(ak)"; }
    };
    
    const translatePaidAmount = () => {
        if (langId === "HU") { return "Fizetett összeg"; }
        else if (langId === "EN") { return "Paid Amount"; }
        else if (langId === "DE") { return "Bezahlter Betrag"; }
        else { return "Fizetett összeg"; }
    };
    
    const translateTotal = () => {
        if (langId === "HU") { return "Teljes összeg"; }
        else if (langId === "EN") { return "Total amount"; }
        else if (langId === "DE") { return "Gesamtbetrag"; }
        else { return "Teljes összeg"; }
    };
    
    const translateDeposit = () => {
        if (langId === "HU") { return "Foglaló összege"; }
        else if (langId === "EN") { return "Deposit amount"; }
        else if (langId === "DE") { return "Anzahlungsbetrag"; }
        else { return "Foglaló összege"; }
    };
    const translateWeAreWaiting = () => {
        if (langId === "HU") { return "Várunk titeket!"; }
        else if (langId === "EN") { return "We are waiting for you!"; }
        else if (langId === "DE") { return "Wir warten auf euch!"; }
        else { return "Várunk titeket!"; }
    };
    const translateWithBreakfast = () => {
        if (langId === "HU") { return "Reggelivel"; }
        else if (langId === "EN") { return "With breakfast"; }
        else if (langId === "DE") { return "Mit Frühstück"; }
        else { return "Reggelivel"; }
    };
    const translateWithoutBreakfast = () => {
        if (langId === "HU") { return "Reggeli nélkül"; }
        else if (langId === "EN") { return "Without breakfast"; }
        else if (langId === "DE") { return "Ohne Frühstück"; }
        else { return "Reggeli nélkül"; }
    };
    // Context value that will be passed to consumers
    const contextValue = {
        langId,setLangId,isOpen, setIsOpen,
        LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,
        
        LangMainMerul1,LangMainMerul2,LangBookNow,LangOurHouses,LangHouseDescription,LangOurHousesDetailed,LangOurDream,LangExperience,LangGallery,LangContact,LangPhone,LangTermsAndConditions,LangPrivacyPolicy,LangImprint,LangAllRightsReserved,

        LangInfoAboutHouses, LangInfoBookingConditions, LangInfoYearRound, LangInfoMaxTwoPeople, LangInfoBookingDuration, LangInfoNoPets, LangInfoNoSmoking, LangInfoThankYou, LangInfoParameters, LangInfoComfort, LangInfoForgottenItems,LangInfoForgottenItems2,
        LangInfoBedroom,LangInfoLargeDoubleBed,LangInfoQualityBedding,LangInfoBookshelf,LangInfoReadingLamp,LangInfoBoardGames,LangInfoAdjustableTV,LangInfoSafe, LangInfoIron,
        LangInfoSlippers,LangInfoKitchenDining,LangInfoDiningCounter,LangInfoWineFridge,LangInfoPlateSet,LangInfoGlassSet,LangInfoQualityKnives,LangInfoBerlingerPots,LangInfoCeramicHob,
        LangInfoFridge,LangInfoToaster,LangInfoDishwasher,LangInfoBasicFood,LangInfoCleaningSupplies,LangInfoServicesAndPrices,LangInfoMinTwoNights,LangInfoPricesForTwo,
        LangInfoMagicMango,LangInfoCrystalPower,LangInfoGreenPassion,LangInfoSpecialDays,LangInfoMinTwoNightsRepeat,LangInfoHolidaySurcharge,LangInfoHighlightedDays,LangInfoMarch14_17,
        LangInfoMarch28_April1,LangInfoAugust17_20,LangInfoOctober31_November3,LangInfoDecember24_January2,LangInfoBookingPaymentCancellation,LangInfoBookingPaymentCancellationDetails,LangInfoBookingPaymentCancellationDetails2,LangInfoBookingPaymentCancellationDetails3,LangInfoProceedToBooking ,
        
        LangCryPKitchen, LangCryPTV, LangCryPWiFi, LangCryPBreakfast, LangCryPParking, LangCryPJacuzzi, LangCryPAirConditioning,  LangCryPProceedToBooking,

        LangCryPKitchenDining,LangCryPBedroomDescription,

        NaptarGuest1FullName,NaptarGuest2FullName,NaptarGuest3FullName,NaptarGuest4FullName,NaptarGuest5FullName,NaptarGuest6FullName,NaptarCompanyNameOptional,
        NaptarTaxNumberOptional,NaptarPostalCode,NaptarCity,NaptarAddress,NaptarCountry,NaptarEmail,NaptarPhoneNumber,NaptarNotesOptional,NaptarWouldYouLikeBreakfast,
        NaptarCreditCardPayment,NaptarBankTransfer,NaptarBack,NaptarBookNow,

        NaptarReadAndAcceptedTerms,NaptarDepositAmount,NaptarBookingTransferPolicy,NaptarCardholderName,NaptarIBAN,NaptarAccountNumber,NaptarNote,

        LangInfoMagicMangoP1,LangInfoCrystalPowerP1,LangInfoGreenPassionP1,LangInfoMagicMangoP2,LangInfoCrystalPowerP2,LangInfoGreenPassionP2,NaptarDepositFullAmount,
        translateName,translateFromDate,translateToDate,translateHouses,translatePaidAmount,translateTotal,translateDeposit,translateWeAreWaiting,
        translateWithBreakfast,translateWithoutBreakfast
    };

    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};