import React, { useState, useEffect } from 'react';
import { useAdmin } from './AdminContext';

const AdminList = () => {
    const {deleteById,setpayedById,fetchReservations,totalPages,reservations,order,setOrder,desc,setDesc,search,setSearch,page, setPage} = useAdmin();
    const [modalContent, setModalContent] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastlab,setLastLab] = useState("");

    useEffect(() => {
        fetchReservations(page,order,desc,search);
    }, [page,order,desc,search]);

    

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleFieldClick = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const handleOrderClick = (label) => {
        if (label === lastlab) {
            setDesc(!desc);
        } else {
            if (label === "Név") { setOrder("reservationName"); setLastLab("Név");}
            else if (label === "Ház") { setOrder("houseId"); setLastLab("Ház");}
            else if (label === "Érkezés Dátuma") { setOrder("startDate"); setLastLab("Érkezés Dátuma");}
            else if (label === "Távozás Dátuma") { setOrder("endDate"); setLastLab("Távozás Dátuma");}
            else if (label === "Fizetve") { setOrder("payed"); setLastLab("Fizetve");}
            else if (label === "Reggeli") { setOrder("breakfast"); setLastLab("Reggeli");}
            else if (label === "Név 2") { setOrder("name2"); setLastLab("Név 2");}
            else if (label === "Név 3") { setOrder("name3"); setLastLab("Név 3");}
            else if (label === "Név 4") { setOrder("name4"); setLastLab("Név 4");}
            else if (label === "Név 5") { setOrder("name5"); setLastLab("Név 5");}
            else if (label === "Név 6") { setOrder("name6"); setLastLab("Név 6");}
            else if (label === "Cégnév") { setOrder("cname"); setLastLab("Cégnév");}
            else if (label === "Adószám") { setOrder("watnum"); setLastLab("Adószám");}
            else if (label === "Irányítószám") { setOrder("postalCode"); setLastLab("Irányítószám");}
            else if (label === "Város") { setOrder("city"); setLastLab("Város");}
            else if (label === "Cím") { setOrder("adress"); setLastLab("Cím");}
            else if (label === "Ország") { setOrder("country"); setLastLab("Ország");}
            else if (label === "Email") { setOrder("email"); setLastLab("Email");}
            else if (label === "Telefon") { setOrder("phone"); setLastLab("Telefon");}
            else if (label === "Megjegyzés") { setOrder("comment"); setLastLab("Megjegyzés");}
            else if (label === "ID Key") { setOrder("idKey"); setLastLab("ID Key");}
            else if (label === "Foglalás Dátuma") { setOrder("resDate"); setLastLab("Foglalás Dátuma");}
        }
    }

    const labels = [
        "Funkciók","Név", "Ház", "Érkezés Dátuma", "Távozás Dátuma", "Fizetve", "Reggeli", 
        "Név 2", "Név 3", "Név 4", "Név 5", "Név 6", "Cégnév", "Adószám", "Irányítószám",
        "Város", "Cím", "Ország", "Email", "Telefon", "Megjegyzés", "ID Key", "Foglalás Dátuma","Funkciók 2"
    ];

    return (
            <div className="reservations-container">
                <h2>Foglalások</h2>
                <input
                    type="text"
                    className="stylish-input"
                    placeholder="Keresés"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    />
                <div className="reservations-list-wrapper">
                    <div className="reservations-list-header">
                        {labels.map(label => (
                            <div key={label} className="reservation-label" onClick={() => handleOrderClick(label)}>{label}{label === lastlab ? desc ? "▼" : "▲"  : ""}</div>
                        ))}
                    </div>
                    <div className="reservations-list">
                        {reservations.map((reservation) => (
                            <div key={reservation.id} className="reservation-item">
                                <div className="button-group" style={{display:'flex',flexDirection:"row"}}>
                                    <button className="delete-button" style={{marginLeft:'50px'}} onClick={() => deleteById(reservation.id)}>X</button>
                                    <button className="buy-button" style={{alignItems: "flex-end",marginLeft:'50px'}} onClick={() => setpayedById(reservation.id)}>💶</button>
                                </div>
                                <div onClick={() => handleFieldClick(reservation.reservationName)}>{reservation.reservationName}</div>
                                <div onClick={() => handleFieldClick(reservation.houseName)}>{reservation.houseName}</div>
                                <div onClick={() => handleFieldClick(new Date(reservation.startDate).toLocaleDateString())}>{new Date(reservation.startDate).toLocaleDateString()}</div>
                                <div onClick={() => handleFieldClick(new Date(reservation.endDate).toLocaleDateString())}>{new Date(reservation.endDate).toLocaleDateString()}</div>
                                <div onClick={() => handleFieldClick(reservation.payed ? 'Fizetve' : 'Nincs')}>{reservation.payed ? 'Fizetve' : 'Nincs'}</div>
                                <div onClick={() => handleFieldClick(reservation.breakfast ? 'Kérnek reggelit' : 'Nem kérnek reggelit')}>{reservation.breakfast ? 'Igen' : '-'}</div>
                                <div onClick={() => handleFieldClick(reservation.name2)}>{reservation.name2 ? reservation.name2 : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.name3)}>{reservation.name3 ? reservation.name3 : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.name4)}>{reservation.name4 ? reservation.name4 : "-"} </div>
                                <div onClick={() => handleFieldClick(reservation.name5)}>{reservation.name5 ? reservation.name5 : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.name6)}>{reservation.name6 ? reservation.name6 : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.cname)}>{reservation.cname ? reservation.cname : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.watnum)}>{reservation.watnum ? reservation.watnum : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.postalCode)}>{reservation.postalCode ? reservation.postalCode : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.city)}>{reservation.city ? reservation.city : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.adress)}>{reservation.adress ? reservation.adress : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.country)}>{reservation.country ? reservation.country : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.email)}>{reservation.email ? reservation.email : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.phone)}>{reservation.phone ? reservation.phone : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.comment)}>{reservation.comment ? reservation.comment : "-"}</div>
                                <div onClick={() => handleFieldClick(reservation.idKey)}>{reservation.idKey ? reservation.idKey : "-"}</div>
                                <div onClick={() => handleFieldClick(new Date(reservation.resDate).toLocaleDateString())}>{new Date(reservation.resDate).toLocaleDateString()}</div>
                                <div className="button-group" style={{display:'flex',flexDirection:"row"}}>
                                    <button className="delete-button" style={{marginLeft:'50px'}} onClick={() => deleteById(reservation.id)}>X</button>
                                    <button className="buy-button" style={{alignItems: "flex-end",marginLeft:'50px'}} onClick={() => setpayedById(reservation.id)}>💶</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pagination-controls">
                    {totalPages !== 1 && <button disabled={page === 0} onClick={() => handlePageChange(page - 1)}>Previous</button>}
                    {[...Array(totalPages).keys()].map((pageIndex) => (
                        <button
                            key={pageIndex}
                            className={pageIndex === page ? 'active' : ''}
                            onClick={() => handlePageChange(pageIndex)}
                        >
                            {pageIndex + 1}
                        </button>
                    ))}
                    {totalPages !== 1 && <button disabled={page === totalPages - 1} onClick={() => handlePageChange(page + 1)}>Next</button>}
                </div>
                {isModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close-button" onClick={() => setIsModalOpen(false)}>&times;</span>
                            <p>{modalContent}</p>
                        </div>
                    </div>
                )}
            </div>
    );
};

export default AdminList;