import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './style.css';

// Fix icon issues with React-Leaflet and Webpack
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

function StaticCoordinateControl() {
  const staticCoords = [46.57058750852, 17.11399173959];
  
  return (
    <div className="coordinate-control">
      {`Lat: ${staticCoords[0]}, Lng: ${staticCoords[1]}`}
    </div>
  );
}

function LeafMap() {
  const location = [46.57058750852, 17.11399173959]; // Example location

  return (
    <MapContainer center={location} zoom={13} style={{ height: "100%", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='' // Remove the attribution text
      />
      <Marker position={location}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
      <StaticCoordinateControl />
    </MapContainer>
  );
}

export default LeafMap;