// AdminPage.js
import React from 'react';
import { useAdmin } from './AdminContext';
import './Admin.css';
import AdminSuperNaptar from './AdminSuperNaptar';
import { useBase } from '../contexts/BaseContext';
import AdminList from './AdminList';
import StatsDashboard from '../components/StatsDashboard';

const AdminPage = () => {
    const { logout,pageAdmin,setPageAdmin,napstat,name,setName,
            name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,
            cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,
            setNapStat,getToggles,hnum,setHnum,toggles,getColor,handleToggle,handleSubmitAdmin
    } = useAdmin();

    const {getDatesAdmin,selectedRangeAdmin, setSelectedRangeAdmin,selectedRange2Admin, setSelectedRange2Admin,selectedRange3Admin, setSelectedRange3Admin,clearRangesAdmin} = useBase();

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; 
    const handleFoglalasClickAdmin = () => {
        if (napstat === 0)  {
            getToggles(selectedRange3Admin,selectedRange2Admin,selectedRangeAdmin)
            setNapStat(1);
        }
        if (napstat === 1)  {
            if (name !== "" && name !== null) {
                handleSubmitAdmin(selectedRange3Admin,selectedRange2Admin,selectedRangeAdmin);
                setNapStat(0);
                clearRangesAdmin();
            }
        }
    }
    const handleVisszaClickAdmin = () => {
        setNapStat(0);
    }

    return (
        <div className="admin-container">
            <nav className="admin-navbar">
                <ul>
                    <li onClick={()=>setPageAdmin('nap')}>Naptár</li>
                    <li onClick={()=>setPageAdmin('list')}>Foglalás Lista</li>
                    <li onClick={()=>setPageAdmin('sin')}>* Naptár Szinkron *</li>
                    <li onClick={()=>setPageAdmin('stat')}>* Statisztika *</li>
                </ul>
                <button onClick={logout} className="logout-button">Logout</button>
            </nav>
            <div>
                {napstat === 0 ? pageAdmin === "nap" && 
                <div className='AdminNaptarHolder'>
                    <AdminSuperNaptar year={currentYear} month={currentMonth}/>
                    <div className="NaptarReserveGomb1" style={{marginLeft:'43%',marginTop:'50px',marginBottom:'50px'}} onClick={handleFoglalasClickAdmin}>Befoglalás</div>
                </div>
                : pageAdmin === "nap" && 
                <div className='AdminNaptarHolder2'>
                    <div style={{ display: 'block' ,marginLeft:"25%",marginTop:"5%"}} className="popup1" >
                        <div className="input-container" style={{height:"50vh"}}>
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Vendég 1"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Vendég 2"
                            value={name2}
                            onChange={(e) => setName2(e.target.value)}
                            />
                            {hnum > 1 && 
                            <div>
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Vendég 3"
                            value={name3}
                            onChange={(e) => setName3(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Vendég 4"
                            value={name4}
                            onChange={(e) => setName4(e.target.value)}
                            />
                            </div>
                            }
                            {hnum > 2 && 
                            <div>
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Vendég 5"
                            value={name5}
                            onChange={(e) => setName5(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Vendég 6"
                            value={name6}
                            onChange={(e) => setName6(e.target.value)}
                            />
                            </div>
                            }
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Cégnév"
                            value={cegname}
                            onChange={(e) => setCegName(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Adoszam"
                            value={adoszam}
                            onChange={(e) => setAdoSzam(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Irányítószám"
                            value={postalcode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Város"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Cím"
                            value={adress}
                            onChange={(e) => setAdress(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Ország"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Telefonszám"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            />
                            <input
                            type="text"
                            className="stylish-input"
                            placeholder="Megjegyzés"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            />
                            </div>
                            <div className="toggle-container">
                            <div className="toggle-instructions">
                                Reggeli
                            </div>
                            {Object.keys(toggles).map((house) => (
                                <div key={house} className="toggle-wrapper">
                                 <div style={{display:'flex',flexDirection:'row'}}>
                                    <span className="toggle-label" style={{color:getColor(house)}}>{house+"  "}</span>
                                    <span className="toggle-label">{"  :  "+getDatesAdmin(house)}</span>
                                </div>
                                <div
                                    className={`toggle-switch ${toggles[house] ? 'active' : ''}`}
                                    onClick={() => handleToggle(house)}
                                ></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div className="NaptarReserveGomb" style={{marginLeft:'35%',marginTop:'50px',marginBottom:'50px',width:'10%',justifyContent:'center',alignItems:'center',display:'flex'}} onClick={handleVisszaClickAdmin}>Vissza</div>
                        <div className="NaptarReserveGomb1" style={{marginLeft:'2%',marginTop:'50px',marginBottom:'50px'}} onClick={handleFoglalasClickAdmin}>Befoglalás</div>
                    </div>
                </div>
                }
                {pageAdmin === "list" &&
                    <AdminList/>
                }
                {pageAdmin === "stat" &&
                    <StatsDashboard/>
                }
            </div>
        </div>
    );
};

export default AdminPage;