import React, { useState, useEffect } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, getDay, parseISO, getDate, getDaysInMonth, isWithinInterval, differenceInDays } from 'date-fns';
import DaySegment from './DaySegment';
import { CalendarContainer, Header } from '../styles/CalendarStyles';
import axios from 'axios';
import { useBase } from '../contexts/BaseContext';
import TransformDiv from './TransformDiv';
import { CalendarContainerMobile } from '../styles/CalendarStylesMobile';
import { CalendarContainerTablet } from '../styles/CalendarStylesTablet';
import { useAnalize } from '../contexts/AnalizeContext';

const SuperNaptar = ({ year, month }) => {
    const {predefinedToken,selectedRangeCP, setSelectedRangeCP,selectedRangeGP, setSelectedRangeGP,selectedRangeMM, setSelectedRangeMM,done,setPstate,getToggles,setChose,selectedRange, setSelectedRange,selectedRange2, setSelectedRange2,selectedRange3, setSelectedRange3,device,naptarLoaded,setNaparLoaded} = useBase();
    const [currentDate, setCurrentDate] = useState(new Date(year, month - 1));
    const weekDays = ["Hé", "Ke", "Sze", "Csü", "Pé", "Szo", "Va"];
    
    const [selectedSegm,setSelectedSegm] = useState("");
    const [reservedGreenPassion, setReservedGreenPassion] = useState([]);
    const [reservedMagicMango, setReservedMagicMango] = useState([]);
    const [reservedCrystalPower, setReservedCrystalPower] = useState([]);
    const [pMonthDays,setpMonthDays] = useState([false,false]);
    const [pMonthDays2,setpMonthDays2] = useState([false,false]);
    const [pMonthDays3,setpMonthDays3] = useState([false,false]);
    const [sDays,setSDays] = useState([]);
    const [sDays2,setSDays2] = useState([]);
    const [sDays3,setSDays3] = useState([]);
    const [eDays,setEDays] = useState([]);
    const [eDays2,setEDays2] = useState([]);
    const [eDays3,setEDays3] = useState([]);
    const {trackEvent,trackException,trackTiming} = useAnalize();
    const {updateStatistics} = useAnalize();
    let loaded = false;

    const generateIntervals = () => {
        const intervals = [];
        for (let year = 2023; year <= 2050; year++) {
          intervals.push(
            { start: `${year}-03-14`, end: `${year}-03-16` },
            { start: `${year}-03-28`, end: `${year}-04-01` },
            { start: `${year}-08-17`, end: `${year}-08-19` },
            { start: `${year}-10-31`, end: `${year}-11-02` },
            { start: `${year}-12-24`, end: `${year + 1}-01-01` }
          );
        }
        return intervals;
      };
      
      const intervals = generateIntervals();

    const isDateWithinIntervals = (startDate, endDate) => {
      
        const start = parseISO(startDate);
        const end = parseISO(endDate);
      
        const days = eachDayOfInterval({ start, end });
      
        for (const day of days) {
          for (const interval of intervals) {
            if (isWithinInterval(day, { start: parseISO(interval.start), end: parseISO(interval.end) })) {
              return true;
            }
          }
        }
      
        return false;
      };
      
      const hasAtLeastTwoDaysBetween = (startDate, endDate) => {
        const start = parseISO(startDate);
        const end = parseISO(endDate);
        return differenceInDays(end, start) >= 2;
      };
      
      // Example usage:
      console.log(isDateWithinIntervals('2024-12-25', '2024-12-30')); // true
      console.log(isDateWithinIntervals('2024-12-12', '2024-12-15')); // true
      console.log(hasAtLeastTwoDaysBetween('2024-03-13', '2024-03-15')); 
      console.log(hasAtLeastTwoDaysBetween('2024-03-13', '2024-03-14')); 
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp6");loaded = true}
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
          updateStatistics("pageTimep6");
        }, 30000);
        
        return () => {
          clearInterval(intervalId); // Clear interval on component unmount
        };
      }, []);

    useEffect(() => {
        const fetchE = async () => {
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const start = performance.now();
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/getEnds?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getEnds?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getEnds?houseId=3&month=${monthString}`, config)
                ]);
                const end = performance.now();
                trackTiming('API', 'fetchData','API Request',start,end)
                setEDays(greenPassionResponse.data);
                setEDays2(magicMangoResponse.data);
                setEDays3(crystalPowerResponse.data);
                trackEvent("Function","Backend-Call","End-Dates");
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
                trackException("Error fetching reserved dates:" + error)
            }
        };
        fetchE();
    }, [currentDate]);
    useEffect(() => {


    }, [sDays,eDays]);
    useEffect(() => {
        if(done) {
            setSelectedRangeCP(selectedRange3);
            setSelectedRangeGP(selectedRange);
            setSelectedRangeMM(selectedRange2);

            
        }
    }, [done]);
    useEffect(() => {
        if (done) {
            getToggles()
            setPstate(1);
        }
    }, [selectedRangeCP,selectedRangeGP,selectedRangeMM]);

    useEffect(() => {
        const fetchS = async () => {
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const start = performance.now();
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/getStarts?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getStarts?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getStarts?houseId=3&month=${monthString}`, config)
                ]);
                const end = performance.now();
                trackTiming('API', 'fetchData','API Request',start,end)
                setSDays(greenPassionResponse.data);
                setSDays2(magicMangoResponse.data);
                setSDays3(crystalPowerResponse.data);
                trackEvent("Function","Backend-Call","Start-Dates");
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
                trackException("Error fetching reserved dates:"+ error);
            }
        };
        fetchS();
    }, [currentDate]);

    useEffect(() => {
        setNaparLoaded(false);
        const fetchReservedDates = async () => {
            
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const start = performance.now();
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/bookedDates?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/bookedDates?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/bookedDates?houseId=3&month=${monthString}`, config)
                ]);
                const formatDates = (dates) => dates.map(date => format(parseISO(date), 'yyyy-MM-dd'));
                const end = performance.now();
                trackTiming('API', 'fetchData','API Request',start,end)
                setReservedGreenPassion(formatDates(greenPassionResponse.data));
                setReservedMagicMango(formatDates(magicMangoResponse.data));
                setReservedCrystalPower(formatDates(crystalPowerResponse.data));
                setNaparLoaded(true);
                trackEvent("Function","Backend-Call","Booked-Dates");
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
                trackException("Error fetching reserved dates:"+ error);
                setNaparLoaded(false);
            }
        };
        fetchReservedDates();
    }, [currentDate]);

    useEffect(() => {
        const fetchReservedDates = async () => {
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const start = performance.now();
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/getPrevAndNext?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getPrevAndNext?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getPrevAndNext?houseId=3&month=${monthString}`, config)
                ]);
                const end = performance.now();
                trackTiming('API', 'fetchData','API Request',start,end)
                setpMonthDays(greenPassionResponse.data);
                setpMonthDays2(magicMangoResponse.data);
                setpMonthDays3(crystalPowerResponse.data);
                trackEvent("Function","Backend-Call","MonthStartEnd-Dates");
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
                trackException("Error fetching reserved dates:"+ error);
            }
        };
        fetchReservedDates();
    }, [currentDate]);

    useEffect(() => {
        if (selectedRange.start === null && selectedRange.end === null && 
            selectedRange2.start === null && selectedRange.end === null && 
            selectedRange3.start === null && selectedRange.end === null  
        ) {

        }
    }, [selectedRange,selectedRange2,selectedRange3]);

    const isDateInList = (date, list) => {
        return list.includes(date);
    };

    const GetSelRange = (type) =>{
        if (type === "GreenPassion") {
            return selectedRange;
        }
        if (type === "MagicMango") {
            return selectedRange2;
        }
        if (type === "CrystalPower") {
            return selectedRange3;
        }
    }
    const adjustDate = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return format(result, 'yyyy-MM-dd');
    };

    const isRangeValid = (start, end, reservedDays) => {
        const dayRange = eachDayOfInterval({ start: parseISO(start), end: parseISO(end) });
        return !dayRange.some(day => reservedDays.includes(format(day, 'yyyy-MM-dd')));
    };
    function addOneDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      function minusOneDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() - 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const handleSegmentSelect = (day, segmentType) => {
        trackEvent('Naptár','Selected',segmentType)
        const segmentId = `${format(day, 'yyyy-MM-dd')}-${segmentType}`;
        const reservedSegmentDays = {
            GreenPassion: reservedGreenPassion,
            MagicMango: reservedMagicMango,
            CrystalPower: reservedCrystalPower
        }[segmentType];
    
        
        if (segmentType === "GreenPassion") {
            const formattedDay = format(day, 'yyyy-MM-dd');
            const currentDate = new Date();
                    const currentHour = currentDate.getHours();
                    const currentDateNoTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    const formattedDayDate = new Date(formattedDay);
                    const formattedDayNoTime = new Date(formattedDayDate.getFullYear(), formattedDayDate.getMonth(), formattedDayDate.getDate());
                    const isPastDate = formattedDayNoTime < currentDateNoTime || (formattedDayNoTime.getTime() === currentDateNoTime.getTime() && currentHour >= 12);
            const isReserved = reservedGreenPassion.includes(formattedDay) || isPastDate;
                const selrange = GetSelRange(segmentType);

                const prevDay = adjustDate(day, -1); // Előző nap
                const nextDay = adjustDate(day, 1); // Következő nap

                const isPrevReserved = isDateInList(prevDay, reservedGreenPassion);
                const isNextReserved = isDateInList(nextDay, reservedGreenPassion);

                const dayOfMonth = getDate(day);
                const daysInMonth = getDaysInMonth(day);

                let previousAndNextMonthDays = [true,false];
                if (segmentType === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
                if (segmentType === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
                if (segmentType === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
                const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

                // Determine isStart2 and isEnd2 with the original logic
                let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
                let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

                // Adjust logic for the first and last day of the month
                if (dayOfMonth === 1) {
                     if (isReserved && !isPrevMonthLastDayReserved) {
                        isEnd2 = false;
                        isStart2 = true;
                     }else {
                        isEnd2 = false;
                        isStart2 = false;
                        if ( isReserved && !isNextReserved) {
                            isEnd2 = true;
                        }
                    }
                    
                    } else if (dayOfMonth === daysInMonth) {
                    if (isReserved && !isNextMonthFirstDayReserved) {
                        isEnd2 = true;
                        isStart2 = false;
                    } else {
                        isEnd2 = false;
                        isStart2 = false;
                        if (isReserved &&!isPrevReserved) {
                            isStart2 = true;
                        }
                    }
                }

                if (segmentType === "GreenPassion") {
                        if (sDays.includes(formattedDay)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (eDays.includes(formattedDay)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                }
                if (segmentType === "MagicMango") {
                    if (isDateInList(formattedDay,sDays2)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays2)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (segmentType === "CrystalPower") {
                    if (isDateInList(formattedDay,sDays3)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays3)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (isPastDate) {isStart2 = false; isEnd2 = false;}
                const isStart = isPastDate ? false : selrange.start === segmentId || isStart2;
                const isEnd = isPastDate ? false : selrange.end === segmentId ||isEnd2;
            
            if (selectedRange.start && !selectedRange.end && (segmentType !== selectedSegm)) {
                setSelectedRange({ start: null, end: null });
                return;
            }
            if (selectedRange.start && selectedRange.end && (segmentType === selectedSegm)) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRange({ start: null, end: null });
                return;
            }
            if (reservedSegmentDays.includes(format(day, 'yyyy-MM-dd')) || isPastDate) {
                //return; // Prevent selection of reserved days
                
                if ((!selectedRange.start || selectedRange.end) && !isEnd) {
                    return;
                 }
                 if ((selectedRange.start && !selectedRange.end) && !isStart) {
                    return;
                 }
            }
            if (!selectedRange.start || selectedRange.end) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRange({ start: segmentId, end: null });
                setSelectedSegm(segmentType);
            } else if (segmentId !== selectedRange.start) {
                // If there is a
                //if (segmentType === selectedSegm) {
                const startDay = selectedRange.start.split('-')[0]+"-"+selectedRange.start.split('-')[1]+"-"+selectedRange.start.split('-')[2];
                const selectedSecDay = segmentId.split('-')[0] +"-"+segmentId.split('-')[1]+"-"+segmentId.split('-')[2]
                if (isDateWithinIntervals(startDay,selectedSecDay)) {
                    if (!hasAtLeastTwoDaysBetween(startDay,selectedSecDay)) {
                        setSelectedRange({ start: null, end: null });
                        console.log("KIEMELT NAP 1 NAP NEM JÓ.")
                        return;
                    }
                }
                const check = addOneDay(startDay) === selectedSecDay;
                if (segmentId >= selectedRange.start) {
                    const newStartDay = addOneDay(startDay);
                    if (isRangeValid(startDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange({ ...selectedRange, end: segmentId });
                    }else if (isStart2 &&check) {
                        setSelectedRange({ ...selectedRange, end: segmentId });
                    }else if (isStart2 && isRangeValid(addOneDay(startDay), minusOneDay(format(day, 'yyyy-MM-dd')), reservedSegmentDays)) {
                        setSelectedRange({ ...selectedRange, end: segmentId });
                    }else if (isRangeValid(newStartDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange({ ...selectedRange, end: segmentId });
                    } else {
                        //alert("The selection includes reserved days. Please select a different range.");
                        //setSelectedRange({ start: null, end: null });
                        setSelectedRange({ start: null, end: null });
                            setSelectedSegm("");
                    }
                } else {

                    // If the selected day is before the start within the same segment type, reset the start.
                    setSelectedRange({ start: segmentId, end: null });
                }
                } else {
                    setSelectedRange({ start: null, end: null });
                            setSelectedSegm("");
                }
            }
            else if (segmentType === "MagicMango") {
                const formattedDay = format(day, 'yyyy-MM-dd');
                const currentDate = new Date();
                    const currentHour = currentDate.getHours();
                    const currentDateNoTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    const formattedDayDate = new Date(formattedDay);
                    const formattedDayNoTime = new Date(formattedDayDate.getFullYear(), formattedDayDate.getMonth(), formattedDayDate.getDate());
                    const isPastDate = formattedDayNoTime < currentDateNoTime || (formattedDayNoTime.getTime() === currentDateNoTime.getTime() && currentHour >= 12);
                const isReserved = reservedMagicMango.includes(formattedDay) || isPastDate;
                const selrange = GetSelRange(segmentType);

                const prevDay = adjustDate(day, -1); // Előző nap
                const nextDay = adjustDate(day, 1); // Következő nap

                const isPrevReserved = isDateInList(prevDay, reservedMagicMango);
                const isNextReserved = isDateInList(nextDay, reservedMagicMango);

                const dayOfMonth = getDate(day);
                const daysInMonth = getDaysInMonth(day);

                let previousAndNextMonthDays = [true,false];
                if (segmentType === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
                if (segmentType === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
                if (segmentType === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
                const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

                // Determine isStart2 and isEnd2 with the original logic
                let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
                let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

                // Adjust logic for the first and last day of the month
                if (dayOfMonth === 1) {
                     if (isReserved && !isPrevMonthLastDayReserved) {
                        isEnd2 = false;
                        isStart2 = true;
                     }else {
                        isEnd2 = false;
                        isStart2 = false;
                        if ( isReserved && !isNextReserved) {
                            isEnd2 = true;
                        }
                    }
                    
                    } else if (dayOfMonth === daysInMonth) {
                    if (isReserved && !isNextMonthFirstDayReserved) {
                        isEnd2 = true;
                        isStart2 = false;
                    } else {
                        isEnd2 = false;
                        isStart2 = false;
                        if (isReserved &&!isPrevReserved) {
                            isStart2 = true;
                        }
                    }
                }

                if (segmentType === "GreenPassion") {
                    if (sDays.includes(formattedDay)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (eDays.includes(formattedDay)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (segmentType === "MagicMango") {
                    if (isDateInList(formattedDay,sDays2)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays2)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (segmentType === "CrystalPower") {
                    if (isDateInList(formattedDay,sDays3)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays3)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (isPastDate) {isEnd2 = false; isStart2 = false;}
                const isStart = isPastDate ? false : selrange.start === segmentId || isStart2;
                const isEnd = isPastDate ? false : selrange.end === segmentId ||isEnd2;
                if (selectedRange2.start && !selectedRange2.end && (segmentType !== selectedSegm)) {
                    setSelectedRange2({ start: null, end: null });
                    return;
                }
                if (selectedRange2.start && selectedRange2.end && (segmentType === selectedSegm)) {
                    // If there is no start or if the end is already set, restart the selection.
                    setSelectedRange2({ start: null, end: null });
                    return;
                }
                if (reservedSegmentDays.includes(format(day, 'yyyy-MM-dd')) || isPastDate) {
                    //return; // Prevent selection of reserved days
                    
                    if ((!selectedRange2.start || selectedRange2.end) && !isEnd) {
                        return;
                     }
                     if ((selectedRange2.start && !selectedRange2.end) && !isStart) {
                        return;
                     }
                }
                if (!selectedRange2.start || selectedRange2.end) {
                    // If there is no start or if the end is already set, restart the selection.
                    setSelectedRange2({ start: segmentId, end: null });
                    setSelectedSegm(segmentType);
                } else if (segmentId !== selectedRange2.start) {
                    // If there is a
                    //if (segmentType === selectedSegm) {
                    const startDay = selectedRange2.start.split('-')[0]+"-"+selectedRange2.start.split('-')[1]+"-"+selectedRange2.start.split('-')[2];
                    
                    const selectedSecDay = segmentId.split('-')[0] +"-"+segmentId.split('-')[1]+"-"+segmentId.split('-')[2]
                    if (isDateWithinIntervals(startDay,selectedSecDay)) {
                        if (!hasAtLeastTwoDaysBetween(startDay,selectedSecDay)) {
                            setSelectedRange2({ start: null, end: null });
                            console.log("KIEMELT NAP 1 NAP NEM JÓ.")
                            return;
                        }
                    }
                    const check = addOneDay(startDay) === selectedSecDay;
                    if (segmentId >= selectedRange2.start) {
                        const newStartDay = addOneDay(startDay);
                    if (isRangeValid(startDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange2({ ...selectedRange2, end: segmentId });
                    }else if (isStart2 && check) {
                        setSelectedRange2({ ...selectedRange2, end: segmentId });
                    }else if (isStart2 && isRangeValid(addOneDay(startDay), minusOneDay(format(day, 'yyyy-MM-dd')), reservedSegmentDays)) {
                        setSelectedRange2({ ...selectedRange2, end: segmentId });
                    }else if (isRangeValid(newStartDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange2({ ...selectedRange2, end: segmentId });
                    } else {
                        //alert("The selection includes reserved days. Please select a different range.");
                        //setSelectedRange({ start: null, end: null });
                        setSelectedRange2({ start: null, end: null });
                            setSelectedSegm("");
                    }
                    } else {
    
                        // If the selected day is before the start within the same segment type, reset the start.
                        setSelectedRange2({ start: segmentId, end: null });
                    }
                } else {
                    setSelectedRange2({ start: null, end: null });
                            setSelectedSegm("");
                }
        }
        else if (segmentType === "CrystalPower") {
            const formattedDay = format(day, 'yyyy-MM-dd');
            const currentDate = new Date();
                    const currentHour = currentDate.getHours();
                    const currentDateNoTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    const formattedDayDate = new Date(formattedDay);
                    const formattedDayNoTime = new Date(formattedDayDate.getFullYear(), formattedDayDate.getMonth(), formattedDayDate.getDate());
                    const isPastDate = formattedDayNoTime < currentDateNoTime || (formattedDayNoTime.getTime() === currentDateNoTime.getTime() && currentHour >= 12);
            const isReserved = reservedCrystalPower.includes(formattedDay);
            const selrange = GetSelRange(segmentType);

            const prevDay = adjustDate(day, -1); // Előző nap
            const nextDay = adjustDate(day, 1); // Következő nap

            const isPrevReserved = isDateInList(prevDay, reservedCrystalPower);
            const isNextReserved = isDateInList(nextDay, reservedCrystalPower);

            const dayOfMonth = getDate(day);
            const daysInMonth = getDaysInMonth(day);

            let previousAndNextMonthDays = [true,false];
            if (segmentType === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
            if (segmentType === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
            if (segmentType === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
            const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

            // Determine isStart2 and isEnd2 with the original logic
            let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
            let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

            // Adjust logic for the first and last day of the month
            if (dayOfMonth === 1) {
                 if (isReserved && !isPrevMonthLastDayReserved) {
                    isEnd2 = false;
                    isStart2 = true;
                 }else {
                    isEnd2 = false;
                    isStart2 = false;
                    if ( isReserved && !isNextReserved) {
                        isEnd2 = true;
                    }
                }
                
                } else if (dayOfMonth === daysInMonth) {
                if (isReserved && !isNextMonthFirstDayReserved) {
                    isEnd2 = true;
                    isStart2 = false;
                } else {
                    isEnd2 = false;
                    isStart2 = false;
                    if (isReserved &&!isPrevReserved) {
                        isStart2 = true;
                    }
                }
            }

            if (segmentType === "GreenPassion") {
                if (sDays.includes(formattedDay)) {
                    isStart2 = true;
                    isEnd2 = false;
                }
                if (eDays.includes(formattedDay)) {
                    isEnd2 = true;
                    isStart2 = false;
                }
                if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                    isStart2 = false;
                    isEnd2 = false;
                }
            }
            if (segmentType === "MagicMango") {
                if (isDateInList(formattedDay,sDays2)) {
                    isStart2 = true;
                    isEnd2 = false;
                }
                if (isDateInList(formattedDay,eDays2)) {
                    isEnd2 = true;
                    isStart2 = false;
                }
                if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                    isStart2 = false;
                    isEnd2 = false;
                }
            }
            if (segmentType === "CrystalPower") {
                if (isDateInList(formattedDay,sDays3)) {
                    isStart2 = true;
                    isEnd2 = false;
                }
                if (isDateInList(formattedDay,eDays3)) {
                    isEnd2 = true;
                    isStart2 = false;
                }
                if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                    isStart2 = false;
                    isEnd2 = false;
                }
            }
            if (isPastDate) {isEnd2 = false; isStart2 = false;} 
            const isStart = isPastDate ? false : selrange.start === segmentId || isStart2;
                const isEnd = isPastDate ? false : selrange.end === segmentId ||isEnd2;
            if (selectedRange3.start && selectedRange3.end && (segmentType === selectedSegm)) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRange3({ start: null, end: null });
                return;
            }
            if (reservedSegmentDays.includes(format(day, 'yyyy-MM-dd')) || isPastDate) {
                //return; // Prevent selection of reserved days
                
                if ((!selectedRange3.start || selectedRange3.end) && !isEnd) {
                    return;
                 }
                 if ((selectedRange3.start && !selectedRange3.end) && !isStart) {
                    return;
                 }
            }
            if (!selectedRange3.start || selectedRange3.end) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRange3({ start: segmentId, end: null });
                setSelectedSegm(segmentType);
            } else if (segmentId !== selectedRange3.start)  {
                // If there is a
                //if (segmentType === selectedSegm) {
                const startDay = selectedRange3.start.split('-')[0]+"-"+selectedRange3.start.split('-')[1]+"-"+selectedRange3.start.split('-')[2];
                const selectedSecDay = segmentId.split('-')[0] +"-"+segmentId.split('-')[1]+"-"+segmentId.split('-')[2];
                if (isDateWithinIntervals(startDay,selectedSecDay)) {
                    if (!hasAtLeastTwoDaysBetween(startDay,selectedSecDay)) {
                        setSelectedRange3({ start: null, end: null });
                        console.log("KIEMELT NAP 1 NAP NEM JÓ.")
                        return;
                    }
                }
                const check = addOneDay(startDay) === selectedSecDay;
                if (segmentId >= selectedRange3.start) {
                    const newStartDay = addOneDay(startDay);
                    
                    if (isRangeValid(startDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange3({ ...selectedRange3, end: segmentId });
                    }else if (isStart2 && check) {
                        setSelectedRange3({ ...selectedRange3, end: segmentId });
                    }else if (isStart2 && isRangeValid(addOneDay(startDay), minusOneDay(format(day, 'yyyy-MM-dd')), reservedSegmentDays)) {
                        setSelectedRange3({ ...selectedRange3, end: segmentId });
                    }else if (isRangeValid(newStartDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange3({ ...selectedRange3, end: segmentId });
                    } else {
                        //alert("The selection includes reserved days. Please select a different range.");
                        //setSelectedRange({ start: null, end: null });
                        setSelectedRange3({ start: null, end: null });
                            setSelectedSegm("");
                    }
                } else {


                    // If the selected day is before the start within the same segment type, reset the start.
                    setSelectedRange3({ start: segmentId, end: null });
                }
            } else {
                setSelectedRange3({ start: null, end: null });
                        setSelectedSegm("");
            }
        }
    };
    

    const daysInMonth = eachDayOfInterval({
        start: startOfMonth(currentDate),
        end: endOfMonth(currentDate)
    });

    const firstDayOfMonth = getDay(startOfMonth(currentDate));
    const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1; // Adjust for Monday start; note that getDay() returns 0 for Sunday

    const getMonth = (str) => {
        const mo = str.split("-")[0];
        if (mo === "January") {return str.split("-")[1]+" Január"}
        else if (mo === "February") {return str.split("-")[1]+" Február"}
        else if (mo === "March") {return str.split("-")[1]+" Március"}
        else if (mo === "April") {return str.split("-")[1]+" Április"}
        else if (mo === "May") {return str.split("-")[1]+" Május"}
        else if (mo === "June") {return str.split("-")[1]+" Június"}
        else if (mo === "July") {return str.split("-")[1]+" Július"}
        else if (mo === "August") {return str.split("-")[1]+" Augusztus"}
        else if (mo === "September") {return str.split("-")[1]+" Szeptember"}
        else if (mo === "October") {return str.split("-")[1]+" Október"}
        else if (mo === "November") {return str.split("-")[1]+" November"}
        else if (mo === "December") {return str.split("-")[1]+" December"}
        else {return str.split("-")[0]}
    }
    const leftarr = "<";
    const rightarr = ">";
    return (
        <div className='naptarHolder'>
            <TransformDiv/>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1vh 2vw', alignItems: 'center' }}>
                <button className='SwitchButt' onClick={() => setCurrentDate(subMonths(currentDate, 1))}>{leftarr}</button>
                <span>{getMonth(format(currentDate, 'MMMM-yyyy'))}</span>
                <button className='SwitchButt' onClick={() => setCurrentDate(addMonths(currentDate, 1))}>{rightarr}</button>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', textAlign: 'center', padding: '10px' }}>
                {weekDays.map(day => <Header key={day}>{day}</Header>)}
            </div>
            {device === "PC" && naptarLoaded && <CalendarContainer>
                {Array.from({ length: offset }, (_, i) => (
                    <div key={`empty-${i}`} style={{ opacity: 0 }}></div> // Empty divs for days before start of the month
                ))}
                {daysInMonth.map(day => (
                    <DaySegment
                        key={format(day, 'yyyy-MM-dd')}
                        day={day}
                        onSegmentSelect={handleSegmentSelect}
                        selectedRange={selectedRange}
                        selectedRange2={selectedRange2}
                        selectedRange3={selectedRange3}
                        selectedSegm={selectedSegm}
                        pMonthDays={pMonthDays}
                        pMonthDays2={pMonthDays2}
                        pMonthDays3={pMonthDays3}
                        sDays={sDays}
                        sDays2={sDays2}
                        sDays3={sDays3}
                        eDays={eDays}
                        eDays2={eDays2}
                        eDays3={eDays3}
                        reservedDays={{
                            GreenPassion: reservedGreenPassion,
                            MagicMango: reservedMagicMango,
                            CrystalPower: reservedCrystalPower
                        }}
/>
                ))}
            </CalendarContainer>}
            {device === "PHONE" && naptarLoaded && <CalendarContainerMobile>
                {Array.from({ length: offset }, (_, i) => (
                    <div key={`empty-${i}`} style={{ opacity: 0 }}></div> // Empty divs for days before start of the month
                ))}
                {daysInMonth.map(day => (
                    <DaySegment
                        key={format(day, 'yyyy-MM-dd')}
                        day={day}
                        onSegmentSelect={handleSegmentSelect}
                        selectedRange={selectedRange}
                        selectedRange2={selectedRange2}
                        selectedRange3={selectedRange3}
                        selectedSegm={selectedSegm}
                        pMonthDays={pMonthDays}
                        pMonthDays2={pMonthDays2}
                        pMonthDays3={pMonthDays3}
                        sDays={sDays}
                        sDays2={sDays2}
                        sDays3={sDays3}
                        eDays={eDays}
                        eDays2={eDays2}
                        eDays3={eDays3}
                        reservedDays={{
                            GreenPassion: reservedGreenPassion,
                            MagicMango: reservedMagicMango,
                            CrystalPower: reservedCrystalPower
                        }}
/>
                ))}
            </CalendarContainerMobile>}
        </div>
    );
};

export default SuperNaptar;
