import React, { useEffect, useRef, useState } from "react";
import SuperNaptar from "./SuperNaptar";
import './style.css';
import { useBase } from "../contexts/BaseContext";
import StripePayment from "./StripePayment";
import SzepKartyaPayment from "./SzepKartyaPayment";
import { useLanguage } from "../contexts/LanguageContext";
import { useAnalize } from "../contexts/AnalizeContext";

const NaptarPage = () => {
  const { on, closePopup, pstate, setPstate, setDone, toggles, handleToggle, getToggles,name, setName,selectedMenu, setSelectedMenu,createBasket,setCartData,selectedRangeCP,selectedRangeGP,selectedRangeMM,selectedRange, setSelectedRange,selectedRange2, setSelectedRange2,selectedRange3, setSelectedRange3,
          name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,hnum,getAmount,popMessageCol,setPopMassageCol,popMessage,setPopMassage,CloseMessagePopup,
          cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,getDates,getColor,
          nameStyle,setNameStyle,nameStyle2,setNameStyle2,nameStyle3,setNameStyle3,nameStyle4,setNameStyle4,nameStyle5,setNameStyle5,nameStyle6,setNameStyle6,postalStyle,setPostalStyle,
          cityStyle,setCityStyle,adressStyle,setAdressStyle,countryStyle,setCountryStyle,emailStyle,setEmailStyle,phoneStyle,setPhoneStyle,device,handleToggle2,togg,getAmountDetailed
          ,MMpriceSTR,GPpriceSTR,CPpriceSTR,MMprice,GPprice,CPprice,amount,useButton
        } = useBase();

   const {NaptarGuest1FullName,NaptarGuest2FullName,NaptarGuest3FullName,NaptarGuest4FullName,NaptarGuest5FullName,NaptarGuest6FullName,NaptarCompanyNameOptional,
    NaptarTaxNumberOptional,NaptarPostalCode,NaptarCity,NaptarAddress,NaptarCountry,NaptarEmail,NaptarPhoneNumber,NaptarNotesOptional,NaptarWouldYouLikeBreakfast,
    NaptarCreditCardPayment,NaptarBankTransfer,NaptarBack,NaptarBookNow,langId,
    translateName,translateFromDate,translateToDate,translateHouses,translatePaidAmount,translateTotal,translateDeposit,translateWeAreWaiting,
    translateWithBreakfast,translateWithoutBreakfast} = useLanguage();

  const stripeRef = useRef(null);
  const szepKartyaRef = useRef(null);
  const buttmarg = device === 'PC' ? '50px' : '22px';
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const {trackEvent,updateStatistics} = useAnalize();

  const formatNumberWithSpaces = (number) => {
    return new Intl.NumberFormat('en-US').format(number).replace(/,/g, ' ');
  };

  const handleVisszaClick = () => {
    if (pstate === 0) { closePopup(); setDone(false);trackEvent("Naptar","Back To","Close"); }
    else if (pstate === 1) {
      setPstate(0); setDone(false);setName('');
      trackEvent("Naptar","Back To","Days");
    }
    else if (pstate === 2) {
      setPstate(1);
      trackEvent("Naptar","Back To","Datas");
    }
  }

  const handleFoglalasClick = () => {
    if (pstate === 0) {
      if ((selectedRange.start === null || selectedRange.end === null) &&
        (selectedRange2.start === null || selectedRange2.end === null) &&
        (selectedRange3.start === null || selectedRange3.end === null) ) {
          console.log("Válassz elöbb!")
      }
      else {
        setDone(true);
        getToggles();
        setPstate(1);
        getAmountDetailed();
        trackEvent("Naptar","Forward To","Datas");
        updateStatistics("intervallselect");
      }
    }
    else if (pstate === 1) {
      if (name === '') {setNameStyle('2px solid red')}
      if (name2 === '') {setNameStyle2('2px solid red')}
      if (name3 === '') {setNameStyle3('2px solid red')}
      if (name4 === '') {setNameStyle4('2px solid red')}
      if (name5 === '') {setNameStyle5('2px solid red')}
      if (name6 === '') {setNameStyle6('2px solid red')}
      if (postalcode === '') {setPostalStyle('2px solid red')}
      if (city === '') {setCityStyle('2px solid red')}
      if (adress === '') {setAdressStyle('2px solid red')}
      if (country === '') {setCountryStyle('2px solid red')}
      if (email === '') {setEmailStyle('2px solid red')}
      if (phone === '') {setPhoneStyle('2px solid red')}
      else {
        setCartData(createBasket());
        //setPstate(2);

        getAmount();
        trackEvent("Naptar","Forward To","Pay");
        updateStatistics("datagiven");
            setPstate(2);
      }
    }
    else if (pstate === 2) {
      if (selectedMenu === 'stripe' && stripeRef.current) {
        stripeRef.current.submit();
        
      } else if (selectedMenu === 'szep' && szepKartyaRef.current) {
        szepKartyaRef.current.submit();
        trackEvent("Naptar","Pay","Utalas");
        
      }
    }
  }
  

  return (
    <div>
      {on && (
        <div id="popup-container" className="popup-container">
          {pstate === 0 && <div style={{ display: 'block' }} className="popup">
            <SuperNaptar year={currentYear} month={currentMonth} />
          </div>}
          {pstate === 1 && <div style={{ display: 'block' }} className="popup1">
          <div className="input-container">
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarGuest1FullName()}
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{border: nameStyle}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarGuest2FullName()}
              value={name2}
              onChange={(e) => setName2(e.target.value)}
              style={{border: nameStyle2}}
            />
            {hnum > 1 && 
            <div>
              <input
              type="text"
              className="stylish-input"
              placeholder={NaptarGuest3FullName()}
              value={name3}
              onChange={(e) => setName3(e.target.value)}
              style={{border: nameStyle3}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarGuest4FullName()}
              value={name4}
              onChange={(e) => setName4(e.target.value)}
              style={{border: nameStyle4}}
            />
            </div>
            }
            {hnum > 2 && 
            <div>
              <input
              type="text"
              className="stylish-input"
              placeholder={NaptarGuest5FullName()}
              value={name5}
              onChange={(e) => setName5(e.target.value)}
              style={{border: nameStyle5}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarGuest6FullName()}
              value={name6}
              onChange={(e) => setName6(e.target.value)}
              style={{border: nameStyle6}}
            />
            </div>
            }
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarCompanyNameOptional()}
              value={cegname}
              onChange={(e) => setCegName(e.target.value)}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarTaxNumberOptional()}
              value={adoszam}
              onChange={(e) => setAdoSzam(e.target.value)}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarPostalCode()}
              value={postalcode}
              onChange={(e) => setPostalCode(e.target.value)}
              style={{border: postalStyle}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarCity()}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={{border: cityStyle}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarAddress()}
              value={adress}
              onChange={(e) => setAdress(e.target.value)}
              style={{border: adressStyle}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarCountry()}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              style={{border: countryStyle}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarEmail()}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{border: emailStyle}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarPhoneNumber()}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{border: phoneStyle}}
            />
            <input
              type="text"
              className="stylish-input"
              placeholder={NaptarNotesOptional()}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            </div>
            <div className="toggle-container">
              <div className="toggle-prices">
                {CPpriceSTR !== "NO" && <div className="toggle-prices-CP" style={{color:getColor("CrystalPower")}}>{CPpriceSTR}{langId === "HU" ? " Ft" : " Huf"}</div>}
                {GPpriceSTR !== "NO" && <div className="toggle-prices-GP" style={{color:getColor("GreenPassion")}}>{GPpriceSTR}{langId === "HU" ? " Ft" : " Huf"}</div>}
                {MMpriceSTR !== "NO" && <div className="toggle-prices-MM" style={{color:getColor("MagicMango")}}>{MMpriceSTR}{langId === "HU" ? " Ft" : " Huf"}</div>}
              </div>
              <div className="toggle-instructions">
                {NaptarWouldYouLikeBreakfast()}
              </div>
              {Object.keys(toggles).map((house) => (
                <div key={house} className="toggle-wrapper">
                  <div style={{display:'flex',flexDirection:'row'}}>
                    <span className="toggle-label" style={{color:getColor(house)}}>{house+"  "}</span>
                    <span className="toggle-label">{"  :  "+getDates(house)}</span>
                  </div>
                  <div
                    className={`toggle-switch ${toggles[house] ? 'active' : ''}`}
                    onClick={() => handleToggle(house)}
                  ></div>
                </div>
              ))}
            </div>
          </div>}
          {pstate === 2 && (
            <div style={{ display: 'block' }} className="popup2">
            <div className="arwrap">
              <div className="payment-amount">{translateDeposit()}</div>
              <div
                className={`toggle-switch ${togg ? 'active' : ''}`}
                onClick={() => handleToggle2()}
                style={{    transform: "translateX(-17px)"}}
              ></div>
              <div className="payment-amount" style={{    transform: "translateX(-17px)"}}>{translateTotal()}</div>
            </div>
              <div className="menu-container" style={{display:"none"}}>
                <button style={{display:"block"}}
                  className={`menu-button ${selectedMenu === 'stripe' ? 'active' : ''}`}
                  onClick={() => setSelectedMenu('stripe')}
                >
                  {NaptarCreditCardPayment()}
                </button>
                <button style={{display:"block"}}
                  className={`menu-button ${selectedMenu === 'szep' ? 'active' : ''}`}
                  onClick={() => setSelectedMenu('szep')}
                >
                  {NaptarBankTransfer()}
                </button>
              </div>
              {selectedMenu === 'stripe' && <StripePayment ref={stripeRef} />}
              {selectedMenu === 'szep' && <SzepKartyaPayment ref={szepKartyaRef} />}
            </div>
          )}
          {pstate === 3 && popMessageCol === 'red' && (
          <div style={{ display: 'block' }} className="popup2">
            <div className="messagepopup" style={{ color: popMessageCol }}>{popMessage}</div>
            <button className="messagepopup-closebutton" onClick={CloseMessagePopup}>X</button>
          </div>
        )}
        {pstate === 3 && popMessageCol === 'green' && (
          <div style={{ display: 'block' }} className="popup2">
            <div className="form-group">
              <label htmlFor="name">{translateName()}</label>
              <span id="name">{name}</span>
            </div>
            {Object.keys(toggles).map((house) => (
                <div key={house} className="toggle-wrapper" style={{transform:"translateX(22%)"}}>
                  <div style={{display:'flex',flexDirection:'row'}}>
                    <span className="toggle-label" style={{color:getColor(house)}}>{house+"  "}</span>
                    <span className="toggle-label">{"  :  "+getDates(house)}</span>
                    <span className="toggle-label" style={{transform:"translateX(5px)",fontWeight:"400"}}>{toggles[house] ? translateWithBreakfast() : translateWithoutBreakfast()}</span>
                  </div>
                </div>
              ))}
            <div className="form-group">
              <label htmlFor="price">{translatePaidAmount()}</label>
              <span id="price">{formatNumberWithSpaces(togg ? amount : amount/2)} HUF</span>
              <p>{togg ? translateTotal() : translateDeposit()}</p>
            </div>
            <div className="form-group">
              
              <div className="messagepopup" style={{ color: popMessageCol }}>{popMessage}</div>
            </div>
            <button className="messagepopup-closebutton" onClick={CloseMessagePopup}>X</button>
          </div>
        )}
          {pstate !== 3 &&<div style={{ display: 'flex' }}>
            <div className="NaptarReserveGomb" onClick={handleVisszaClick} style={{ width: "102px", marginRight: buttmarg, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>{NaptarBack()}</div>
            <div className="NaptarReserveGomb1" onClick={useButton ? ()=>console.log("NOUSEBUTTON") :handleFoglalasClick}>{NaptarBookNow()}</div>
          </div>}
        </div>
      )}
    </div>
  );
};

export default NaptarPage;