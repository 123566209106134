// In CalendarStyles.js

import styled from 'styled-components';

export const CalendarContainerMobile = styled.div`
        display: grid;
    grid-template-columns: repeat(7, 14.24%);
    row-gap: 1vh;
    padding: 5px;
    background: transparent;
`;

export const DayMobile = styled.div`
    display: flex;
    flex-direction: column;
    aspect-ratio: ${props => `1 / 1.5`};
    background: transparent;
    margin-right: -5px; // To eliminate gaps
    border-radius: ${props => props.isStart ? '10px 0 0 10px' : props.isEnd ? '0 10px 10px 0' : '0'}; // Rounded corners on the ends
    z-index: ${props => props.isStart || props.isEnd ? '1' : '0'}; // Bring start and end dates forward
    width: 100%;
    grid-template-rows: auto;
    max-height:9vh;
`;

export const SegmentMobile = styled.div`
    flex: 1;
    /*height: ${props => props.isStart || props.isEnd ? '15px' : '11px'};*/
    height: ${props =>props.isEnd2 && !props.selected ? `83%` : props.isStart2 && !props.selected ? `83%` : (props.selected || props.disabled || props.isStart || props.isEnd) ? '83%' : '66%'};
    margin:8% 0% 8% 0%;/*3% 0% 3% 0%;  /*${props =>props.isEnd2 && !props.selected ? `1.5% 0% 4% 0%` : props.isStart2 && !props.selected ? `4.5% 0% 4% 0%` : (props.selected || props.disabled || props.isStart || props.isEnd) ? '3% 0% 3% 0%' : '4% 0% 4% 0%'};*/
    transform: ${props => !props.selected && props.isStart2 ? 'translateY(0px)' : !props.selected && props.isEnd2 ? 'translateY(-4px)' : !props.selected && !props.disabled && !props.isStart2 && !props.isEnd2 ? 'translateY(-0px)' : 'translateY(-2px)'};
    width: ${props => props.isEnd2 && !props.selected ? '97.5%' : props.isStart2 && !props.selected ? '97.5%' : (props.selected || props.disabled || props.isStart || props.isEnd) ? props.wi : "95%"};
    margin-left: ${props => props.isEnd2  && !props.selected ? '0%' : props.isStart2   && !props.selected ? '2.5%' : (props.selected || props.disabled || props.isStart || props.isEnd) ? props.ma : "2.5%"};
    cursor: pointer;
    transition: width 0.1s linear, margin 0.1s linear, background-color 0.3s ease;
    background-color: ${props =>  props.showMiddleLine ?  props.endtype ? '#646464' : props.baseColor : (!props.isStart && !props.isEnd) ? props.baseColor : 'transparent' };
    position: relative;
    border-radius: ${props => (props.isStart2 || props.isEnd2) && !props.selected  ? '0px' : props.isStart ? '10px 0 0 10px' : props.isEnd ? '0 10px 10px 0' : '0'};
    box-shadow: ${props => props.isEnd2 && !props.selected ? `2px 2px 0 0px ${props.hoverColor2};` : props.isStart2 && !props.selected ? `-2px -2px 0 0 ${props.hoverColor2};` : (props.selected || props.disabled || props.isStart || props.isEnd) ? `0 0 0 0px transparent` : `0 0 0 2px ${props.hoverColor}`};
    
    

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 51%;
        background-color: ${props => ( props.isEnd) ? props.baseColor : 'transparent'};
        
    }

    &::before {
        clip-path: ${props => {
            if (props.isStart && (!props.isR || !props.isS)) {
                return 'polygon(100% 0, 15% 100%, 100% 100%)';
            } else if (props.showMiddleLine) {
                return 'none';
            }
            return 'none';
        }};
        left: 0;
        background-color: ${props => {
            if (props.showMiddleLine) {
                return props.endtype ?  '#646464' : props.baseColor ;
            }
            return props.isR && props.isEnd2 ? '#646464' : (props.isStart || props.isEnd) ? props.baseColor : 'transparent';
        }};
        height:100%/*calc(100%+2px)*/;
        transform: ${props => !props.selected && props.isStart2 ? 'translateY(-2px)' : !props.selected && props.isEnd2 ? 'translateY(2px)' : 'translateY(0px)'};
    }

    &::after {
        clip-path: ${props => {
        if (props.isEnd && (!props.isR || !props.isS)) {
            return 'polygon(0 0, 85% 0%, 0 100%)';
        } else if (props.showMiddleLine) {
            return 'polygon(80% 0, 100% 0, 100% 100%, 10% 100%)';
        }
        return 'none';
    }};
    width: ${props => {
        if (props.isEnd && (!props.isR || !props.isS)) {
            return '51%';
        } else if (props.showMiddleLine) {
            return '80%';
        }
        return 'none';
    }};
    right: 0;
    background-color: ${props => {
        if (props.showMiddleLine) {
            return props.endtype ?props.baseColor : '#646464';
        }
        return props.isR && props.isStart2 ? '#646464' : (props.isStart || props.isEnd) ? props.baseColor : 'transparent';
    }};
    /*height: ${props =>  props.selected  ? 'none' : props.isEnd2  ? '100%' : '110%' };
    transform: ${props => props.selected  ? 'none' : props.isEnd2  ? 'translateY(8%)': 'translateY(-16.5%)'};*/
    height:100%/*calc(100%+2px)*/;
    transform:none/*${props => props.selected  ? 'none' : props.isEnd2  ? 'translateY(2px)': 'translateY(-2px)'}*/;
    transform: ${props => !props.selected && props.isStart2 ? 'translateY(-2px)' : !props.selected && props.isEnd2 ? 'translateY(2px)' : 'translateY(0px)'};
    
    }

`;

export const HeaderMobile = styled.div`
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: #333;
`;
