// AdminContext.js
import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';


const AdminContext = createContext(null);

export const useAdmin = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
    const [page, setPage] = useState(0);
    const [napstat,setNapStat] = useState(0);
    const [reservations, setReservations] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [predefinedToken,setpredefinedToken] = useState("KurukHillAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120")
    const [pageAdmin,setPageAdmin] = useState("nap");
    const [name, setName] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [name2, setName2] = useState('');
    const [name3, setName3] = useState('');
    const [name4, setName4] = useState('');
    const [name5, setName5] = useState('');
    const [name6, setName6] = useState('');
    const [cegname, setCegName] = useState('');
    const [adoszam, setAdoSzam] = useState('');
    const [postalcode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [adress,setAdress] = useState('');
    const [country,setCountry] = useState('Magyarország');
    const [email, setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [comment,setComment] = useState('');
    const [hnum, setHNum] = useState(1);
    const [toggles, setToggles] = useState({
        CrystalPower: false,
        GreenPassion: false,
        MagicMango: false,
      });
      const [order,setOrder] = useState("id");
      const [desc,setDesc] = useState(false);
      const [search,setSearch] = useState("");

    const login = (uname,pass) => {
        axios.get(`https://kurukhill.hu:41808/admin/login?username=${uname}&pass=${pass}`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
            setIsLoggedIn(response.data)
          console.log('Response:', response);
          setpredefinedToken("KurukHillAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120");
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
        });
    };

    const logout = () => {
        setIsLoggedIn(false);
    };
    
    const handleSubmitAdmin = (selectedRange3Admin,selectedRange2Admin,selectedRangeAdmin) => {

            const chargeDetails = {
                source: "MakeCode()",
                amount: 180 * 100,
                shippingName: name,
                name2: name2,
                name3: name3,
                name4: name4,
                name5: name5,
                name6: name6,
                cname: cegname,
                watnum: adoszam,
                postalCode: postalcode,
                city: city,
                adress: adress,
                country: country,
                email: email,
                phone: phone,
                comment: comment,
                desc: "ADMIN",
                basket: createBasketAdmin(selectedRange3Admin,selectedRange2Admin,selectedRangeAdmin)
            };
            axios.post('https://kurukhill.hu:41808/api/payments/noChargeAdmin', chargeDetails, {
            headers: {
                Authorization: `Bearer ${predefinedToken}`,
                'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
            })
            .then(response => {
                console.log('Payment Success:', response.data);
                setName('');setName2('');setName3('');setName4('');setName5('');setName6('');setCegName('');
                setAdoSzam('');setPostalCode('');setCity('');setAdress('');setCountry('');setEmail('');setPhone('');setComment('');
            })
            .catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Payment Error:', error.response.data);
                    console.error('Status:', error.response.status);
                    console.error('Headers:', error.response.headers);
                    
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Payment Error:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                }
                setName('');setName2('');setName3('');setName4('');setName5('');setName6('');setCegName('');
                setAdoSzam('');setPostalCode('');setCity('');setAdress('');setCountry('');setEmail('');setPhone('');setComment('');
            });
      };

      const extractDate = (dateStr) => {
        if (dateStr !== null) {
            return dateStr.split('-').slice(0, 3).join('-');
        } else {return "";}
      };

      const createBasketAdmin = (selectedRange3AdminAdmin,selectedRange2AdminAdmin,selectedRangeAdminAdmin) => {
        // Termékek létrehozása a context adatok alapján
        const products = [
          {
            ID: 0,
            cikkszam: 1,
            megnevezes: `${extractDate(selectedRange3AdminAdmin.start)}=${extractDate(selectedRange3AdminAdmin.end)}`
          },
          {
            ID: 0,
            cikkszam: 2,
            megnevezes: `${extractDate(selectedRangeAdminAdmin.start)}=${extractDate(selectedRangeAdminAdmin.end)}`
          },
          {
            ID: 0,
            cikkszam: 3,
            megnevezes: `${extractDate(selectedRange2AdminAdmin.start)}=${extractDate(selectedRange2AdminAdmin.end)}`
          }
        ];
      
        // Amounts létrehozása a toggles alapján
        const amounts = [
            toggles.CrystalPower !== null ? toggles.CrystalPower ? 1 : 0 : 0,
            toggles.GreenPassion !== null ? toggles.GreenPassion ? 1 : 0 : 0,
            toggles.MagicMango !== null ? toggles.MagicMango ? 1 : 0 : 0
        ];
      
        // Basket objektum létrehozása
        const basket = {
          products: products,
          amounts: amounts,
          id: 0
        };
      
        return basket;
      };

      const getToggles = (selectedRange3Admin,selectedRange2Admin,selectedRangeAdmin) => {
        if (selectedRange3Admin.end === null && selectedRangeAdmin.end === null && selectedRange2Admin.end !== null) {
          setToggles({
            MagicMango: false,
          })
          setHNum(1);
        }
        else if (selectedRange3Admin.end === null && selectedRangeAdmin.end !== null && selectedRange2Admin.end === null) {
          setToggles({
            GreenPassion: false,
          })
          setHNum(1);
        }
        else if (selectedRange3Admin.end !== null && selectedRangeAdmin.end === null && selectedRange2Admin.end === null) {
          setToggles({
            CrystalPower: false,
          })
          setHNum(1);
        }
        else if (selectedRange3Admin.end !== null && selectedRangeAdmin.end !== null && selectedRange2Admin.end === null) {
          setToggles({
            CrystalPower: false,
            GreenPassion: false,
          })
          setHNum(2);
        }
        else if (selectedRange3Admin.end !== null && selectedRangeAdmin.end === null && selectedRange2Admin.end !== null) {
          setToggles({
            CrystalPower: false,
            MagicMango: false,
          })
          setHNum(2);
        }
        else if (selectedRange3Admin.end === null && selectedRangeAdmin.end !== null && selectedRange2Admin.end !== null) {
          setToggles({
            GreenPassion: false,
            MagicMango: false,
          })
          setHNum(2);
        }
        else {
          setToggles({
            CrystalPower: false,
            GreenPassion: false,
            MagicMango: false,
          })
          setHNum(3);
        }
      }

      const getColor = (house) => {
        let color = "";
          if (house === "GreenPassion") {
            color = 'rgb(93, 115, 41)';
          }
          else if (house === "MagicMango") {
            color = 'rgb(255, 191, 52)';
          }
          else if (house === "CrystalPower") {
            color = 'rgb(246, 38, 129)';
          }
        return color;
      }

      const handleToggle = (house) => {
        setToggles((prevToggles) => ({
          ...prevToggles,
          [house]: !prevToggles[house],
        }));
      };
      const deleteById = (id) => {
        axios.get(`https://kurukhill.hu:41808/reservation/adminDelete?id=${id}`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          console.log('Response:', response);
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
        });
        fetchReservations(page,order,desc,search);
      }
      const setpayedById = (id) => {
        axios.get(`https://kurukhill.hu:41808/reservation/adminSetPayed?id=${id}`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
          console.log('Response:', response);
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
        });
        fetchReservations(page,order,desc,search);
      }

      const fetchReservations = async (page,order,desc,search) => {
        axios.get(`https://kurukhill.hu:41808/reservation/adminList?page=${page}&size=${10}&sortBy=${order}&desc=${desc}&search=${search}`, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
        .then(response => {
            setReservations(response.data);
            axios.get(`https://kurukhill.hu:41808/reservation/adminList/totalPages?&size=${10}&search=${search}`, {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`,
                    'Content-Type': 'application/json' // Ensure the correct Content-Type is set
                }
                })
                .then(response => {
                    setTotalPages(response.data);
                console.log('Response:', response);
                })
                .catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Payment Error:', error.response.data);
                    console.error('Status:', error.response.status);
                    console.error('Headers:', error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Payment Error:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error', error.message);
                }
            });
          console.log('Response:', response);
        })
        .catch(error => {
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Payment Error:', error.response.data);
              console.error('Status:', error.response.status);
              console.error('Headers:', error.response.headers);
          } else if (error.request) {
              // The request was made but no response was received
              console.error('Payment Error:', error.request);
          } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
          }
        });
    };
    
    // Context value that will be passed to consumers
    const contextValue = {
        isLoggedIn,setIsLoggedIn,login,logout,pageAdmin,setPageAdmin,handleSubmitAdmin,createBasketAdmin,predefinedToken,
        name, setName,name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,
        cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,
        napstat,setNapStat,getToggles,hnum, setHNum,getColor,handleToggle,toggles,deleteById,setpayedById,fetchReservations,totalPages, setTotalPages,reservations, setReservations,
        order,setOrder,desc,setDesc,search,setSearch,page, setPage

    };

    return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
};

