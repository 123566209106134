import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import HambiMenu from "../../components/HambiMenu";
import { useNavigate } from "react-router-dom";
import { useBase } from "../../contexts/BaseContext";
import { useLanguage } from "../../contexts/LanguageContext";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from "../../img2/hero-7.png";

const Iphonegreen = () => {
  const {openPopup,setIm,openPopup2,setIsMenuOpen,setToGal,setToKap,setToPri,changeHousePic,GPPic,GPPicState,loading} = useBase();

  const navigate = useNavigate();
  const {LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,
    LangCryPKitchen, LangCryPTV, LangCryPWiFi, LangCryPBreakfast, LangCryPParking, LangCryPJacuzzi, LangCryPAirConditioning,  LangCryPProceedToBooking,
    LangCryPKitchenDining,LangCryPBedroomDescription,NaptarBack
  } = useLanguage();
    const galeriaRef = useRef(null);
    const kapRef = useRef(null);
    const fogRef = useRef(null);
    const {updateStatistics} = useAnalize();
    let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp4");loaded = true}
    }, []);
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const {trackEvent,trackTiming} = useAnalize();
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'GreenPassion-Phone', 'Initial Page Load',start,end);
      });
    }, []);
    const changeSlug = (slug) => {
      if (slug === "/") {navigate('/');trackEvent("Phone","Navigated","MainPage")}
      else if(slug === "/info") {navigate('/információ');} 
      else if(slug === "/gp") {navigate('/greenpassion');} 
      else if(slug === "/cp") {navigate('/crystalpower');} 
      else if(slug === "/mm") {navigate('/magicmango');} 
      else if(slug === "/fog") {openPopup();trackEvent("Phone","Navigated","Reservation")}
      else if(slug === "/gal") {navigate('/');setTimeout(() => {setToGal(true);}, 200);}
      else if(slug === "/kap") {navigate('/');setTimeout(() => {setToKap(true);}, 200);}
      else if(slug === "/pri") {navigate('/információ');setTimeout(() => {setToPri(true);}, 200);}     
    };
    const openIm = (n) => {
      setIm(n);
      openPopup2();
    }
    const handleLeftClick = () => {
      changeHousePic("GP","L");
      updateStatistics("imageviewed");
      updateStatistics("housegpwatch");
    }
    const handleRightClick = () => {
      changeHousePic("GP","R");
      updateStatistics("imageviewed");
      updateStatistics("housegpwatch");
    }
    const [backgroundPosition, setBackgroundPosition] = useState(50);
    const [trans, setTrans] = useState("translateX(0vw)");
  const startX = useRef(null);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (startX.current !== null) {
      const currentX = e.touches[0].clientX;
      const deltaX =  startX.current- currentX; // Megfordítva az irányt
      let newPosition = backgroundPosition + deltaX / window.innerWidth * 10; // Lassabb változás (10-es osztás)

      if (newPosition < 0) newPosition = 0;
      if (newPosition > 100) newPosition = 100;

      setBackgroundPosition(newPosition);
    }
  };

  const handleTouchEnd = () => {
    startX.current = null;
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateStatistics("pageTimep4");
    }, 30000);
    
    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector('.cptext-wrapper-3');
    if (GPPicState === 0 && element) {
      element.classList.add('shimmer-text');
      const timer = setTimeout(() => {
        element.classList.remove('shimmer-text');
      }, 3000); // Duration of the animation

      return () => clearTimeout(timer);
    }
  }, [GPPicState,loading])

  const lerp = (x, xMin, xMax, yMin, yMax) => {
    return ((x - xMin) * (yMax - yMin)) / (xMax - xMin) + yMin;
  };
  
  // Function to map backgroundPosition to the new range
  const getScroll = (min, max, backgroundPosition,offset) => {
    let num = lerp(backgroundPosition, 0, 100, min, max);
    return num - offset;
  };
  return (<>
  <Helmet>
      <title>KurukHill - Green Passion</title>
      <meta name="description" content="Ismerje meg a Green Passion csodás világát a Kuruk Hill-en." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu/greenpassion" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Green Passion" />
      <meta name="twitter:description" content="Ismerje meg a Green Passion csodás világát a Kuruk Hill-en." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu/greenpassion" />
    </Helmet>
    <div className="iphonegreen">
      <div className="passioncontainer-wrapper">
        <div className="passioncontainer">
          <div className="div-5">
            <div className="div-5">
              <div className="overlap-group-3">
                <div className="hero-2" style={{overflow:"hidden"}}>
                <img loading="lazy" alt="Loading" src={mainkep} className="Change-Image" style={{height:GPPicState === 0 ? '91vh' : '0vh',width:"100vw"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep1.jpg" className="Change-Image" style={{height:GPPicState === 1 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 1 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep2.jpg" className="Change-Image" style={{height:GPPicState === 2 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 2 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep3.jpg" className="Change-Image" style={{height:GPPicState === 3 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 3 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep4.jpg" className="Change-Image" style={{height:GPPicState === 4 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 4 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep5.jpg" className="Change-Image" style={{height:GPPicState === 5 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 5 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep6.jpg" className="Change-Image" style={{height:GPPicState === 6 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 6 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep7.jpg" className="Change-Image" style={{height:GPPicState === 7 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 7 ? "block" : "none"}}/>
                <img loading="lazy" alt="Loading" src="../hazkepek/changeGreenKep8.jpg" className="Change-Image" style={{height:GPPicState === 8 ? '91vh' : '0vh',width:"100vw",objectPosition: `${backgroundPosition}% 50%`,backgroundColor:"black",display:GPPicState === 8 ? "block" : "none"}}/>
                  <img src="/img3/klogo3.png" alt="Loading" className="frame-33" onClick={() => changeSlug("/")}/>
                </div>
                <div className="frame-34" onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}>
                  {GPPicState === 0 && <div className="kuruk-hill-head-2">Green Passion</div>}
                </div>
                
              </div>
            </div>
            <HambiMenu changeSlug={changeSlug} t={"30px"} r={"20px"}/>
            <div className="cpimg left-arrow" onClick={()=>handleLeftClick()}>
                  <img loading="lazy" alt="Bal nyíl" src="/img/left-arrow.png" style={{height:'100%',margin:'0'}} />
              </div>
              <div className="cpimg right-arrow" onClick={()=>handleRightClick()}>
                  <img loading="lazy" alt="Jobb nyíl" src="/img/right-arrow.png" style={{height:'100%',margin:'0'}} />
              </div>
          </div>
          <div className="frame-36">
            <div className="frame-37">
              <div className="frame-38">
                <div className="text-wrapper-20">Green Passion</div>
                <div className="frame-39" />
              </div>
              <div className="ferohely-3">
                <div className="text-wrapper-21">2</div>
                <img loading="lazy" className="icon-user-3" alt="Icon user" src="/img/icon-user-3.png" />
              </div>
            </div>
            <div className="frame-40">
              <p className="h-l-szob-ban-egy-3">
              {LangCryPBedroomDescription()}
              </p>
            </div>
            <div className="frame-41">
              <div className="frame-42">
                <div className="frame-43">
                  <img loading="lazy" className="img-9" alt="Kitchen cabinets" src="/img/kitchen-cabinets-2-svgrepo-com.svg" />
                  <div className="text-wrapper-22">{LangCryPKitchen()}</div>
                </div>
                <div className="frame-44">
                  <img loading="lazy" className="img-9" alt="Tv svgrepo com" src="/img/tv-svgrepo-com.svg" />
                  <div className="text-wrapper-22">{LangCryPTV()}</div>
                </div>
              </div>
            </div>
            <div className="frame-41">
              <div className="frame-45">
                <div className="frame-46">
                  <img loading="lazy" className="img-10" alt="Wifi svgrepo com" src="/img/wifi-svgrepo-com-4.svg" />
                  <div className="text-wrapper-23">{LangCryPWiFi()}</div>
                </div>
                <div className="frame-43">
                  <img loading="lazy" className="img-9" alt="Parking svgrepo com" src="/img/parkolasikon-3.svg" />
                  <div className="text-wrapper-22">{LangCryPParking()}</div>
                </div>
              </div>
            </div>
            <div className="frame-41">
              <div className="frame-47">
                <div className="frame-44">
                  <img loading="lazy" className="img-9" alt="Group" src="/img/jacuzziikon.png" />
                  <div className="text-wrapper-22">{LangCryPJacuzzi()}</div>
                </div>
                <div className="frame-46">
                  <img loading="lazy" className="img-10" alt="Condicioner svgrepo" src="/img/legkondiikon.svg" />
                  <div className="text-wrapper-23">{LangCryPAirConditioning()}</div>
                </div>
              </div>
            </div>
            <div className="frame-41">
              <div className="frame-48">
                <div className="frame-46">
                  <img loading="lazy" className="img-10" alt="Breakfast food" src="/img/breakfast-food-healthy-svgrepo-com-5.svg" />
                  <div className="text-wrapper-23">{LangCryPBreakfast()}</div>
                </div>
              </div>
            </div>
            <div className="frame-40">
              <p className="text-wrapper-24">
              {LangCryPKitchenDining()}
              </p>
            </div>
            <div className="frame-49">
              <div className="frame-50" />
              <div className="frame-51" onClick={() => changeSlug("/fog")}>
                <div className="text-wrapper-25" >{LangCryPProceedToBooking()}</div>
              </div>
              <div className="frame-51" onClick={() => changeSlug("/")} style={{width:"80px",margin:"5px 0px 0px 0px"}}>
                <div className="text-wrapper-25">{NaptarBack()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Iphonegreen;