import React, { useEffect } from "react";
import "./style.css";
import { useBase } from "../../contexts/BaseContext";
import { useNavigate } from "react-router-dom";
import LanguageDropdown from "../../components/LangDropdown";
import { useLanguage } from "../../contexts/LanguageContext";
import NavLinks from "../../components/NavLinks";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from "../../img2/herokep.png";

const CrystalPower = () => {
  const {LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,
    LangCryPKitchen, LangCryPTV, LangCryPWiFi, LangCryPBreakfast, LangCryPParking, LangCryPJacuzzi, LangCryPAirConditioning,  LangCryPProceedToBooking,
    LangCryPKitchenDining,LangCryPBedroomDescription,NaptarBack,
  } = useLanguage();
  const {updateStatistics} = useAnalize();
  let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp3");loaded = true}
    }, []);
  const navigate = useNavigate();
    const {openPopup,setToGal,setToKap,setToPri,changeHousePic,CPPic,CPPicState,loading} = useBase();
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const {trackEvent,trackTiming} = useAnalize();
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'CrystalPower-PC', 'Initial Page Load',start,end);
      });
    }, []);

    const changeSlug = (slug) => {
        if (slug === "/") {navigate('/');trackEvent("PC","Navigated","MainPage")}
        else if(slug === "/info") {navigate('/információ');} 
        else if(slug === "/gp") {navigate('/greenpassion');} 
        else if(slug === "/cp") {navigate('/crystalpower');} 
        else if(slug === "/mm") {navigate('/magicmango');} 
        else if(slug === "/fog") {openPopup();;trackEvent("PC","Navigated","Reservation")}
        else if(slug === "/gal") {navigate('/');setTimeout(() => {setToGal(true);}, 200);}
        else if(slug === "/kap") {navigate('/');setTimeout(() => {setToKap(true);}, 200);}
        else if(slug === "/pri") {navigate('/információ');setTimeout(() => {setToPri(true);}, 200);}    
    };
    const handleLeftClick = () => {
      changeHousePic("CP","L");
      updateStatistics("imageviewed");
      updateStatistics("housecpwatch");
    }
    const handleRightClick = () => {
      changeHousePic("CP","R");
      updateStatistics("imageviewed");
      updateStatistics("housecpwatch");
    }
    useEffect(() => {
      const intervalId = setInterval(() => {
        updateStatistics("pageTimep3");
      }, 30000);
      
      return () => {
        clearInterval(intervalId); // Clear interval on component unmount
      };
    }, []);

    useEffect(() => {
      const element = document.querySelector('.cptext-wrapper-3');
      if (CPPicState === 0 && element) {
        element.classList.add('shimmer-text');
        const timer = setTimeout(() => {
          element.classList.remove('shimmer-text');
        }, 3000); // Duration of the animation
  
        return () => clearTimeout(timer);
      }
    }, [CPPicState,loading]);

  return (<>
  <Helmet>
      <title>KurukHill - Crystal Power</title>
      <meta name="description" content="Fedezze fel a Crystal Power által nyújtott élményt a Kuruk Hill-en.." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu/crystalpower" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Crystal Power" />
      <meta name="twitter:description" content="Fedezze fel a Crystal Power által nyújtott élményt a Kuruk Hill-en.." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu/crystalpower" />
    </Helmet>
    <div className="crystalpower">
      <div className="cpherokep" >
        <div className="cpframe" style={{overflow:"hidden"}}>
          <img /*loading="lazy"*/ alt="Loading" src={mainkep} className="Change-Image" style={{height:CPPicState === 0 ? '100vh' : '0vh',backgroundColor:"black"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep1.jpg" className="Change-Image" style={{height:CPPicState === 1 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 1 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep2.jpg" className="Change-Image" style={{height:CPPicState === 2 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 2 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep3.jpg" className="Change-Image" style={{height:CPPicState === 3 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 3 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep4.jpg" className="Change-Image" style={{height:CPPicState === 4 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 4 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep5.jpg" className="Change-Image" style={{height:CPPicState === 5 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 5 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep6.jpg" className="Change-Image" style={{height:CPPicState === 6 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 6 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep7.jpg" className="Change-Image" style={{height:CPPicState === 7 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 7 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep8.jpg" className="Change-Image" style={{height:CPPicState === 8 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 8 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeCrystalKep9.jpg" className="Change-Image" style={{height:CPPicState === 9 ? '100vh' : '0vh',backgroundColor:"black",display:CPPicState === 9 ? "block" : "none"}}/>
            <div className="cpimg left-arrow" onClick={()=>handleLeftClick()}>
                <img loading="lazy" alt="Bal nyíl" src="/img/left-arrow.png" style={{height:'100%'}} />
            </div>
            <div className="cpimg right-arrow" onClick={()=>handleRightClick()}>
                <img loading="lazy" alt="Jobb nyíl" src="/img/right-arrow.png" style={{height:'100%'}} />
            </div>
          <div className="cpoverlap-group">
            <div className="cpnavcont">
              <img loading="lazy" alt="Loading" src="/img3/klogo3.png" className="cplogo" onClick={() => changeSlug("/")}/>
              <NavLinks
                changeSlug={changeSlug}
                LangMainTitStart={LangMainTitStart}
                LangMainTitGal={LangMainTitGal}
                LangMainTitCont={LangMainTitCont}
                LangMainTitPri={LangMainTitPri}
                LangMainTitRes={LangMainTitRes}
                LangMainTitInf={LangMainTitInf}
                sts={[false,false,false,false,true,false]}
              />
              <LanguageDropdown/>
            </div>
            <div className="cpframe-2">
            {CPPicState === 0 && <div className="cptext-wrapper-3">Crystal Power</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="cpdiv-wrapper">
        <div className="cpdiv-3">
          <div className="cpframe-3">
            <div className="cpcrystalpowerwrap">
              <div className="cptext-wrapper-5">Crystal Power</div>
              <div className="cpcrystal-vonal" />
            </div>
            <div className="cpferohely">
              <div className="cptext-wrapper-6">2</div>
              <img loading="lazy"  className="cpicon-user" alt="Icon user" src="/img/icon-user.png" />
            </div>
          </div>
          <div className="cpszoveg">
            <p className="cpp">
              {LangCryPBedroomDescription()}
            </p>
          </div>
          <div className="cpikonok">
            <div className="cpdiv-4">
              <img loading="lazy" className="cpimg-3" alt="Konyhaikon" src="/img/konyhaikon.svg" />
              <div className="cptext-wrapper-7">{LangCryPKitchen()}</div>
            </div>
            <div className="cpdiv-5">
              <img loading="lazy" className="cpimg-3" alt="Tvikon" src="/img/tvikon.svg" />
              <div className="cptext-wrapper-7">{LangCryPTV()}</div>
            </div>
            <div className="cpdiv-6">
              <img loading="lazy" className="cpimg-4" alt="Wifiikon" src="/img/wifiikon.svg" />
              <div className="cptext-wrapper-8">{LangCryPWiFi()}</div>
            </div>
            <div className="cpdiv-6">
              <img loading="lazy" className="cpimg-4" alt="Reggeliikon" src="/img/reggeliikon.svg" />
              <div className="cptext-wrapper-8">{LangCryPBreakfast()}</div>
            </div>
          </div>
          <div className="cpikonok">
            <div className="cpdiv-4">
              <img loading="lazy" className="cpimg-3" alt="Parkolasikon" src="/img/parkolasikon.svg" />
              <div className="cptext-wrapper-7">{LangCryPParking()}</div>
            </div>
            <div className="cpdiv-5">
              <img loading="lazy" className="cpimg-3" alt="Jacuzziikon" src="/img/jacuzziikon.png" />
              <div className="cptext-wrapper-7">{LangCryPJacuzzi()}</div>
            </div>
            <div className="cpdiv-6">
              <img loading="lazy" className="cpimg-4" alt="Legkondiikon" src="/img/legkondiikon.svg" />
              <div className="cptext-wrapper-8">{LangCryPAirConditioning()}</div>
            </div>
          </div>
          <div className="cpszoveg">
            <p className="cptext-wrapper-9">
              {LangCryPKitchenDining()}
            </p>
          </div>
          <div className="cpgombwrap">
            <div className="cpgombcont" >
              <div className="cpgombvonal" />
              <div className="cpgomb" onClick={() => changeSlug("/fog")}>
                <div className="cptext-wrapper-10">{LangCryPProceedToBooking()}</div>
              </div>
              <div className="cpgomb" onClick={() => changeSlug("/")} style={{width:"80px",margin:"5px 0px 0px 45px"}}>
                <div className="cptext-wrapper-10">{NaptarBack()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CrystalPower;