import React, { forwardRef, useImperativeHandle,useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutFrom';
import { useBase } from '../contexts/BaseContext';
import { useLanguage } from '../contexts/LanguageContext';

const stripePromise = loadStripe('pk_live_51O58hFIBgIKOsknjAgoXk5ji6k2MKDmr0IfDG2Dz6BSX8mfBAaJT3TS3SCKBOEuK0VJHn4PHamCyp5iQPuRSzpiw00bGJ1IpPj');


const StripePayment = forwardRef((props, ref) => {
const {amount,accepted, setAccepted,tcolor,togg} = useBase();
const {NaptarReadAndAcceptedTerms,NaptarDepositAmount,langId,NaptarDepositFullAmount} = useLanguage()
const locale = langId === 'HU' ? 'hu' : (langId === 'EN' ? 'en' : 'de');
const formatNumberWithSpaces = (number) => {
  return new Intl.NumberFormat('en-US').format(number).replace(/,/g, ' ');
};

return (
  <Elements stripe={stripePromise} options={{ locale: locale }}>
    <div className="payment-container">
      <CheckoutForm ref={ref} />
      <div style={{display:'flex',flexDirection:'row',transform:'translateY(40px)'}}>
        <input
            type="checkbox"
            className="dasdasdasd"
            value={accepted}
            onChange={(e) => setAccepted(e.target.value)}
          />
        <div className="asdasdasd" style={{color:tcolor}}>
          {NaptarReadAndAcceptedTerms()}
        </div>
      </div>
      <div className="payment-amount" style={{transform:'translateY(50px)'}}>
        {togg ? NaptarDepositFullAmount() : NaptarDepositAmount()} {formatNumberWithSpaces(togg ? amount : amount/2)} HUF
      </div>
    </div>
  </Elements>
)   
});

export default StripePayment;