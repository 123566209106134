import React, { useEffect, useRef } from "react";
import "./style.css";
import { useBase } from "../../contexts/BaseContext";
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";
import HambiMenu from "../../components/HambiMenu";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from "../../img2/hero-8.png";

const Iphoneinfo = () => {
  const {openPopup,setToGal,setToKap,setToPri,toPri,toASZF,setToASZF} = useBase();

  const navigate = useNavigate();
  const {LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,LangInfoAboutHouses, LangInfoBookingConditions, LangInfoYearRound, LangInfoMaxTwoPeople, LangInfoBookingDuration, LangInfoNoPets, LangInfoNoSmoking, LangInfoThankYou, LangInfoParameters, LangInfoComfort, LangInfoForgottenItems,LangInfoForgottenItems2,
    LangInfoBedroom,LangInfoLargeDoubleBed,LangInfoQualityBedding,LangInfoBookshelf,LangInfoReadingLamp,LangInfoBoardGames,LangInfoAdjustableTV,LangInfoSafe, LangInfoIron,
    LangInfoSlippers,LangInfoKitchenDining,LangInfoDiningCounter,LangInfoWineFridge,LangInfoPlateSet,LangInfoGlassSet,LangInfoQualityKnives,LangInfoBerlingerPots,LangInfoCeramicHob,
    LangInfoFridge,LangInfoToaster,LangInfoDishwasher,LangInfoBasicFood,LangInfoCleaningSupplies,LangInfoServicesAndPrices,LangInfoMinTwoNights,LangInfoPricesForTwo,
    LangInfoMagicMango,LangInfoCrystalPower,LangInfoGreenPassion,LangInfoSpecialDays,LangInfoMinTwoNightsRepeat,LangInfoHolidaySurcharge,LangInfoHighlightedDays,LangInfoMarch14_17,
    LangInfoMarch28_April1,LangInfoAugust17_20,LangInfoOctober31_November3,LangInfoDecember24_January2,LangInfoBookingPaymentCancellation,LangInfoBookingPaymentCancellationDetails,LangInfoBookingPaymentCancellationDetails2,LangInfoBookingPaymentCancellationDetails3,LangInfoProceedToBooking
    ,LangInfoMagicMangoP1,LangInfoCrystalPowerP1,LangInfoGreenPassionP1,LangInfoMagicMangoP2,LangInfoCrystalPowerP2,LangInfoGreenPassionP2,NaptarBack
  } = useLanguage();
   const galeriaRef = useRef(null);
    const kapRef = useRef(null);
    const fogRef = useRef(null);
    const priRef = useRef(null);
    const ASZFRef = useRef(null);
    const {updateStatistics} = useAnalize();
    let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp2");loaded = true}
    }, []);
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const {trackEvent,trackTiming} = useAnalize();
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'Info-Phone', 'Initial Page Load',start,end);
      });
    }, []);
    const changeSlug = (slug) => {
      
        if (slug === "/") {navigate('/');trackEvent("Phone","Navigated","MainPage")}
        else if(slug === "/info") {navigate('/információ');} 
        else if(slug === "/gp") {navigate('/greenpassion');} 
        else if(slug === "/cp") {navigate('/crystalpower');} 
        else if(slug === "/mm") {navigate('/magicmango');} 
        else if(slug === "/fog") {openPopup();trackEvent("Phone","Navigated","Reservation")}
        else if(slug === "/gal") {navigate('/');setTimeout(() => {setToGal(true);}, 200);}
        else if(slug === "/kap") {navigate('/');setTimeout(() => {setToKap(true);}, 200);}   
        else if(slug === "/pri") {priRef.current.scrollIntoView({ behavior: "smooth" });}
      };
    useEffect(() => {
      if (!toASZF){window.scrollTo({ top: 0, behavior: 'smooth' });}
    }, []);
    useEffect(() => {
      if (toPri) {priRef.current.scrollIntoView({ behavior: "smooth" }); setToPri(false)}
    }, [toPri]);
    useEffect(() => {
      if (toASZF) {ASZFRef.current.scrollIntoView({ behavior: "smooth" }); setToASZF(false)}
    }, [toASZF]);
    useEffect(() => {
      const intervalId = setInterval(() => {
        updateStatistics("pageTimep2");
      }, 30000);
      
      return () => {
        clearInterval(intervalId); // Clear interval on component unmount
      };
    }, []);
  return (<>
    <Helmet>
      <title>KurukHill - Információ</title>
      <meta name="description" content="Kapcsolat: +36 70 942 0610, info@kurukhill.hu. Látogasson el hozzánk: 8749 Zalakaros Szent Orbán utca 42." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu/információ" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Információ" />
      <meta name="twitter:description" content="Kapcsolat: +36 70 942 0610, info@kurukhill.hu. Látogasson el hozzánk: 8749 Zalakaros Szent Orbán utca 42." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu/információ" />
    </Helmet>
    <div className="iphoneinfo">
      <div className="div-29">
        <div className="herowrap-2">
          <div className="overlap-group-10">
          <img loading="lazy" alt="Loading" src={mainkep} className="Change-Image-Info" style={{height:'100vh',width:"100vw"}}/>
            <div className="hero-6">
              <img loading="lazy" alt="Loading" src="/img3/klogo3.png" className="logo-5" onClick={() => changeSlug("/")}/>
            </div>
            <div className="herocontentcont-2">
              <div className="kuruk-hill-head-7">{LangMainTitInf()}</div>
            </div>
            <HambiMenu changeSlug={changeSlug} t={"30px"} r={"20px"}/>
          </div>
        </div>
        <div className="frame-96">
          <div className="container-2">
            <div className="miniinfo">
              <div className="infoahazrolcontainer">
                <div className="infoheadingwrap">
                  <div className="div-31" />
                  <div className="text-wrapper-83">{LangInfoAboutHouses()}</div>
                </div>
                <div className="div-32">
                  <p className="text-wrapper-84">{LangInfoBookingConditions()}</p>
                  <p className="text-wrapper-85">{LangInfoYearRound()}</p>
                  <p className="text-wrapper-85">{LangInfoMaxTwoPeople()}</p>
                  <p className="text-wrapper-85">{LangInfoBookingDuration()}</p>
                  <p className="text-wrapper-85">{LangInfoNoPets()}</p>
                  <p className="text-wrapper-85">
                    {LangInfoNoSmoking()}
                  </p>
                </div>
                <div className="text-wrapper-86">{LangInfoThankYou()}</div>
                <img loading="lazy" className="tilosikonok-2" alt="Tilosikonok" src="/img/tilosikonok-2.svg" />
              </div>
            </div>
            <div className="paramter-2">
              <div className="div-33">
                <div className="div-31" />
                <div className="text-wrapper-83">{LangInfoParameters()}</div>
              </div>
              <p className="text-wrapper-85">
                  {LangInfoComfort()}
                  <br />
                  {LangInfoForgottenItems()}
                  <br />
                  {LangInfoForgottenItems2()}
              </p>
              <div className="div-34">
                <div className="text-wrapper-84" style={{marginLeft: '0',fontWeight:'700'}}>{LangInfoBedroom()}</div>
                <div className="text-wrapper-85">{LangInfoLargeDoubleBed()}</div>
                <p className="text-wrapper-85">{LangInfoQualityBedding()}</p>
                <p className="text-wrapper-85">{LangInfoBookshelf()}</p>
                <div className="text-wrapper-85">{LangInfoReadingLamp()}</div>
                <p className="text-wrapper-85">{LangInfoBoardGames()}</p>
                <p className="text-wrapper-85">{LangInfoAdjustableTV()}</p>
                <div className="text-wrapper-85">{LangInfoSafe()}</div>
                <div className="text-wrapper-85">{ LangInfoIron()}</div>
                <div className="text-wrapper-85">{LangInfoSlippers()}</div>
              </div>
              <div className="div-34">
                <div className="text-wrapper-84" style={{marginLeft: '0',fontWeight:'700'}}>{LangInfoKitchenDining()}</div>
                <div className="text-wrapper-85">{LangInfoDiningCounter()}</div>
                <p className="text-wrapper-85">
                  {LangInfoWineFridge()}
                </p>
                <div className="text-wrapper-85">{LangInfoPlateSet()}</div>
                <p className="text-wrapper-85">{LangInfoGlassSet()}</p>
                <div className="text-wrapper-85">{LangInfoQualityKnives()}</div>
                <p className="text-wrapper-85">{LangInfoBerlingerPots()}</p>
                <div className="text-wrapper-85">{LangInfoCeramicHob()}</div>
                <div className="text-wrapper-85">{LangInfoFridge()}</div>
                <div className="text-wrapper-85">{LangInfoToaster()}</div>
                <div className="text-wrapper-85">{LangInfoDishwasher()}</div>
                <p className="text-wrapper-85">{LangInfoBasicFood()}</p>
                <div className="text-wrapper-85">{LangInfoCleaningSupplies()}</div>
              </div>
            </div>
            <div className="szolgaltatasokcont-2" ref={priRef}>
              <div className="div-33">
                <div className="div-31" />
                <div className="text-wrapper-83">{LangInfoServicesAndPrices()}</div>
              </div>
              <div className="div-35">
                <p className="text-wrapper-84">{LangInfoMinTwoNights()}</p>
                <p className="text-wrapper-85">{LangInfoPricesForTwo()}</p>
              </div>
              <div className="arainkcont-2">
                <div className="text-wrapper-87">{LangMainTitPri()}</div>
                <p className="text-wrapper-85">
                Magic Mango:
                <br />
                {LangInfoMagicMangoP1()}
                <br />
                {LangInfoMagicMangoP2()}
                </p>
                <p className="text-wrapper-85">
                  Crystal Power:
                  <br />
                  {LangInfoCrystalPowerP1()}
                  <br />
                  {LangInfoCrystalPowerP2()}
                </p>
                <p className="green-passion-green-2">
                  Green Passion:
                  <br />
                  {LangInfoGreenPassionP1()}
                  <br />
                  {LangInfoGreenPassionP2()}
                </p>
              </div>
              <div className="div-36">
                <div className="text-wrapper-87">{LangInfoSpecialDays()}</div>
                <p className="text-wrapper-85">{LangInfoMinTwoNightsRepeat()}</p>
                <p className="text-wrapper-85">{LangInfoHolidaySurcharge()}</p>
              </div>
              <div className="div-36">
                <div className="text-wrapper-87">{LangInfoHighlightedDays()}</div>
                <div className="text-wrapper-85">{LangInfoMarch14_17()}</div>
                <div className="text-wrapper-85">{LangInfoMarch28_April1()}</div>
                <div className="text-wrapper-85">{LangInfoAugust17_20()}</div>
                <div className="text-wrapper-85">{LangInfoOctober31_November3()}</div>
                <div className="text-wrapper-85">{LangInfoDecember24_January2()}</div>
              </div>
            </div>
            <div className="foglalasicont-wrapper">
              <div className="foglalasicont-2" ref={ASZFRef}>
                <div className="div-33">
                  <div className="div-31" />
                  <div className="text-wrapper-83">
                  {LangInfoBookingPaymentCancellation()}
                  </div>
                </div>
                <div className="foglalasicontentwrap">
                  <p className="text-wrapper-84">
                  {LangInfoBookingPaymentCancellationDetails3()}
                  <br />
                  {LangInfoBookingPaymentCancellationDetails2()}
                  <br />
                  {LangInfoBookingPaymentCancellationDetails()}
                </p>
                  <a href="/docs/ASZF.docx" download="ASZF.docx">
                    <img loading="lazy" className="pdffilegomb-2" alt="Pdffilegomb" src="/img/pdffilegomb-2.svg" />
                  </a>
                </div>
              </div>
            </div>
            <div className="foglalasgomb-wrapper-2">
              <div className="foglalasgomb-2" onClick={() => changeSlug("/fog")}>
                <div className="text-wrapper-88">{LangInfoProceedToBooking()}</div>
              </div>
              <div className="foglalasgomb-2" onClick={() => changeSlug("/")} style={{width:"80px",margin:"5px 0px 0px 0px"}}>
                <div className="text-wrapper-88">{NaptarBack()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Iphoneinfo;