import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useBase } from '../contexts/BaseContext';
import axios from 'axios';
import { useLanguage } from '../contexts/LanguageContext';
import { useAnalize } from '../contexts/AnalizeContext';

const SzepKartyaPayment = forwardRef((props, ref) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const {NaptarReadAndAcceptedTerms,NaptarDepositAmount,NaptarBookingTransferPolicy,NaptarCardholderName,NaptarIBAN,NaptarAccountNumber,NaptarNote,NaptarDepositFullAmount} = useLanguage()
  const {predefinedToken,amount,name,cartData,createBasket,clearRanges,closePopup,setPstate,setDone,setName,name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,
    cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,
    accepted,setAccepted,MakeCode,tcolor,setTColor,popMessageCol,setPopMassageCol,popMessage,setPopMassage,CloseMessagePopup,togg,checkToggleData} = useBase(); // Összeg állítása

    const {trackEvent,trackException,trackTiming,updateStatistics} = useAnalize();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (accepted) {
        const descSTR1 = MakeCode();
        const descSTR2 = togg ? "teljes" : "foglaló";
        const descSTR3 = togg ? amount : amount/2;
        const description = descSTR1 + " - " + descSTR2 + " - "+ descSTR3;
        const chargeDetails = {
          source: MakeCode(),
          amount: togg ? amount : amount/2,
          shippingName: name,
          name2: name2,
          name3: name3,
          name4: name4,
          name5: name5,
          name6: name6,
          cname: cegname,
          watnum: adoszam,
          postalCode: postalcode,
          city: city,
          adress: adress,
          country: country,
          email: email,
          phone: phone,
          comment: comment,
          desc: description,
          basket: cartData
        };
        const start = performance.now();
        axios.post('https://kurukhill.hu:41808/api/payments/noCharge', chargeDetails, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
          .then(response => {
            const end = performance.now();
            trackTiming('API', 'fetchData','API Request',start,end)
            console.log('Payment Success:', response.data);
            const { docid, status } = response.data;  // Extracting docid and status from the response
            clearRanges();
            console.log('Document ID:', docid);
            console.log('Payment Status:', status);
            setDone(false);
            setPopMassage("We are waiting for you :)");setPopMassageCol("green");
            setPstate(3);
            setName('');setName2('');setName3('');setName4('');setName5('');setName6('');setCegName('');
            setAdoSzam('');setPostalCode('');setCity('');setAdress('');setCountry('');setEmail('');setPhone('');setComment('');
            trackEvent("Function","Backend-Call","Pay-Bank");
            updateStatistics("reservation");
            updateStatistics("bankpay");
            const str = togg ? "fullpay" : "halfpay";
            updateStatistics(str);
            const str2 = checkToggleData("CP");
            if (str2 !== "NO") {
              updateStatistics("chousecpres");
              if (str2 === "TRUE") {updateStatistics("breakfastcp");}
            }
            const str3 = checkToggleData("GP");
            if (str3 !== "NO") {
              updateStatistics("chousegpres");
              if (str3 === "TRUE") {updateStatistics("breakfastgp");}
            }
            const str4 = checkToggleData("MM");
            if (str4 !== "NO") {
              updateStatistics("chousemmres");
              if (str4 === "TRUE") {updateStatistics("breakfastmm");}
            }
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
                setPstate(3);
                setPopMassage(error.response.data);setPopMassageCol("red");
                trackException('Payment Error:'+ error.response.data);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
                setPstate(3);
                setPopMassage(error.request);setPopMassageCol("red");
                trackException('Payment Error:'+ error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
                setPstate(3);
                setPopMassage(error.message);setPopMassageCol("red");
                trackException('Payment Error:'+ error.message);
            }
        });
    } else {
      setTColor("red");
      setTimeout(() => {
        setTColor('black');
      }, 3000);
    }
  };

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit({ preventDefault: () => {} });
    }
  }));

  const formatNumberWithSpaces = (number) => {
    return new Intl.NumberFormat('en-US').format(number).replace(/,/g, ' ');
  };

  return (
    <div className="payment-container1">
      {<form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>{NaptarBookingTransferPolicy()}</label>
        </div>
        <div className="input-group">
          <label>{NaptarCardholderName()}</label>
          <div>Zs And G Group Kft</div>
        </div>
        <div className="input-group">
          <label>{NaptarIBAN()}</label>
          <div>HU12 1070 0275 6694 9978 5110 0005</div>
        </div>
        <div className="input-group">
          <label>{NaptarAccountNumber()}</label>
          <div>10700275-66949978-51100005</div>
        </div>
        <div className="input-group">
          <label>{NaptarNote()}</label>
          <div>{MakeCode()}</div>
        </div>
        <div style={{display:'flex',flexDirection:'row'}}>
        <input
            type="checkbox"
            className="dasdasdasd"
            value={accepted}
            onChange={(e) => setAccepted(e.target.value)}
          />
        <div className="asdasdasd" style={{color:tcolor}}>
          {NaptarReadAndAcceptedTerms()}
        </div>
      </div>
        <div className="payment-amount" style={{transform:'translateY(50%)'}}>
          {togg ? NaptarDepositFullAmount() : NaptarDepositAmount()} {formatNumberWithSpaces(togg ? amount : amount/2)} HUF
        </div>
      </form>}
    </div>
  );
});

export default SzepKartyaPayment;