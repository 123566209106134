import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useBase } from '../contexts/BaseContext';
import axios from 'axios';
import { useLanguage } from '../contexts/LanguageContext';
import { useAnalize } from '../contexts/AnalizeContext';

const CheckoutForm = forwardRef((props, ref) => {

    const {predefinedToken,amount,name,cartData,createBasket,clearRanges,closePopup,setPstate,setDone,setName,name2,setName2,name3,setName3,name4,setName4,name5,setName5,name6,setName6,
            cegname,setCegName,adoszam,setAdoSzam,postalcode,setPostalCode,city,setCity,adress,setAdress,country,setCountry,email,setEmail,phone,setPhone,comment,setComment,accepted,setTColor,
            popMessageCol,setPopMassageCol,popMessage,setPopMassage,CloseMessagePopup,MakeCode,togg,checkToggleData,useButton, setUseButton
    } = useBase();
    const stripe = useStripe();
    const elements = useElements();
    const {trackEvent,trackException,trackTiming,updateStatistics} = useAnalize();
    const {translateWeAreWaiting} = useLanguage();
    const handleSubmit = async (event) => {
    event.preventDefault();
    if (accepted) {
      setUseButton(true);
      const cardElement = elements.getElement(CardElement);
      const start = performance.now();
      const result = await stripe.createSource(cardElement);
      const end = performance.now();
      trackTiming('API', 'Stripe','Create Source',start,end)
      if (result.error) {
        setUseButton(false);
        console.error(result.error.message);
        trackException(result.error.message);
      } else {
        const sourceId = result.source.id;
        const descSTR1 = MakeCode();
        const descSTR2 = togg ? "teljes" : "foglaló";
        const descSTR3 = togg ? amount : amount/2;
        const description = descSTR1 + " - " + descSTR2 + " - "+ descSTR3;
        const amount2 = amount * 100;
        const chargeDetails = {
          source: sourceId,
          amount: togg ? amount2 : amount2/2,
          shippingName: name,
          name2: name2,
          name3: name3,
          name4: name4,
          name5: name5,
          name6: name6,
          cname: cegname,
          watnum: adoszam,
          postalCode: postalcode,
          city: city,
          adress: adress,
          country: country,
          email: email,
          phone: phone,
          comment: comment,
          desc: description,
          basket: cartData
        };
        const start = performance.now();
        axios.post('https://kurukhill.hu:41808/api/payments/charge', chargeDetails, {
          headers: {
            Authorization: `Bearer ${predefinedToken}`,
            'Content-Type': 'application/json' // Ensure the correct Content-Type is set
          }
        })
          .then(response => {
            console.log('Payment Success:', response.data);
            const { docid, status } = response.data;  // Extracting docid and status from the response
            
            console.log('Document ID:', docid);
            console.log('Payment Status:', status);
            setPopMassage(translateWeAreWaiting());setPopMassageCol("green");
            setPstate(3);
            const end = performance.now();
            trackTiming('API', 'fetchData','API Request',start,end);
            trackEvent("Function","Backend-Call","Pay-Card");
            trackEvent("Naptar","Pay","Card");
            updateStatistics("reservation");
            updateStatistics("cardpay");
            const str = togg ? "fullpay" : "halfpay";
            updateStatistics(str);
            const str2 = checkToggleData("CP");
            if (str2 !== "NO") {
              updateStatistics("chousecpres");
              if (str2 === "TRUE") {updateStatistics("breakfastcp");}
            }
            const str3 = checkToggleData("GP");
            if (str3 !== "NO") {
              updateStatistics("chousegpres");
              if (str3 === "TRUE") {updateStatistics("breakfastgp");}
            }
            const str4 = checkToggleData("MM");
            if (str4 !== "NO") {
              updateStatistics("chousemmres");
              if (str4 === "TRUE") {updateStatistics("breakfastmm");}
            }
            setUseButton(false);
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
                setPopMassage(error.response.data.message);setPopMassageCol("red");
                trackException('Payment Error:'+ error.response.data.message);
                setPstate(3);
                setUseButton(false);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
                setPopMassage(error.request.message);setPopMassageCol("red");
                trackException('Payment Error:'+ error.request.message);
                setPstate(3);
                setUseButton(false);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
                setPopMassage(error.message);setPopMassageCol("red");
                trackException('Payment Error:'+ error.message);
                setPstate(3);
                setUseButton(false);
            }
        });
      }
    } else {
      setTColor("red");
      setTimeout(() => {
        setTColor('black');
      }, 3000);
    }
  };

  useImperativeHandle(ref, () => ({
    submit() {
      handleSubmit({ preventDefault: () => {} });
    }
  }));

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ style: { base: { fontSize: '24px', padding: '10px' } } }}/>
    </form>
  );
});

export default CheckoutForm;