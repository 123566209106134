import React, { useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useBase } from "../../contexts/BaseContext";
import LanguageDropdown from "../../components/LangDropdown";
import { useLanguage } from "../../contexts/LanguageContext";
import NavLinks from "../../components/NavLinks";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from "../../img2/herokep.png";

const Magicmango = () => {
  const {LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,
        LangCryPKitchen, LangCryPTV, LangCryPWiFi, LangCryPBreakfast, LangCryPParking, LangCryPJacuzzi, LangCryPAirConditioning,  LangCryPProceedToBooking,
        LangCryPKitchenDining,LangCryPBedroomDescription,NaptarBack
  } = useLanguage();
  const {updateStatistics} = useAnalize();
  const navigate = useNavigate();
    const {openPopup,setToGal,setToKap,setToPri,changeHousePic,MMPic,MMPicState,loading} = useBase();
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const {trackEvent,trackTiming} = useAnalize();
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'MagicMango-PC', 'Initial Page Load',start,end);
      });
    }, []);
    const changeSlug = (slug) => {
        if (slug === "/") {navigate('/');trackEvent("PC","Navigated","MainPage")}
        else if(slug === "/info") {navigate('/információ');} 
        else if(slug === "/gp") {navigate('/greenpassion');} 
        else if(slug === "/cp") {navigate('/crystalpower');} 
        else if(slug === "/mm") {navigate('/magicmango');} 
        else if(slug === "/fog") {openPopup();trackEvent("PC","Navigated","Reservation")}
        else if(slug === "/gal") {navigate('/');setTimeout(() => {setToGal(true);}, 200);}
        else if(slug === "/kap") {navigate('/');setTimeout(() => {setToKap(true);}, 200);}
        else if(slug === "/pri") {navigate('/információ');setTimeout(() => {setToPri(true);}, 200);}  
    };
    const handleLeftClick = () => {
      changeHousePic("MM","L");
      updateStatistics("imageviewed");
      updateStatistics("housemmwatch");
    }
    const handleRightClick = () => {
      changeHousePic("MM","R");
      updateStatistics("imageviewed");
      updateStatistics("housemmwatch");
    }
    useEffect(() => {
      const intervalId = setInterval(() => {
        updateStatistics("pageTimep5");
      }, 30000);
      
      return () => {
        clearInterval(intervalId); // Clear interval on component unmount
      };
    }, []);
    let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp5");loaded = true}
    }, []);

    useEffect(() => {
      const element = document.querySelector('.cptext-wrapper-3');
      if (MMPicState === 0 && element) {
        element.classList.add('shimmer-text');
        const timer = setTimeout(() => {
          element.classList.remove('shimmer-text');
        }, 3000); // Duration of the animation
  
        return () => clearTimeout(timer);
      }
    }, [MMPicState,loading])

  return (<>
  <Helmet>
      <title>KurukHill - Magic Mango</title>
      <meta name="description" content="Tapasztalja meg a Magic Mango kényelmét a Kuruk Hill-en." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu/magicmango" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Magic Mango" />
      <meta name="twitter:description" content="Tapasztalja meg a Magic Mango kényelmét a Kuruk Hill-en." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu/magicmango" />
    </Helmet>
    <div className="magicmango">
      <div className="herokep">
        <div className="frame" style={{overflow:"hidden"}}>
          <img /*loading="lazy"*/ alt="Loading" src={mainkep} className="Change-Image" style={{height:MMPicState === 0 ? '100vh' : '0vh',backgroundColor:"black"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep1.jpg" className="Change-Image" style={{height:MMPicState === 1 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 1 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep2.jpg" className="Change-Image" style={{height:MMPicState === 2 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 2 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep3.jpg" className="Change-Image" style={{height:MMPicState === 3 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 3 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep4.jpg" className="Change-Image" style={{height:MMPicState === 4 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 4 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep5.jpg" className="Change-Image" style={{height:MMPicState === 5 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 5 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep6.jpg" className="Change-Image" style={{height:MMPicState === 6 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 6 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep7.jpg" className="Change-Image" style={{height:MMPicState === 7 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 7 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep8.jpg" className="Change-Image" style={{height:MMPicState === 8 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 8 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep9.jpg" className="Change-Image" style={{height:MMPicState === 9 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 9 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep10.jpg" className="Change-Image" style={{height:MMPicState === 10 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 10 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep11.jpg" className="Change-Image" style={{height:MMPicState === 11 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 11 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep12.jpg" className="Change-Image" style={{height:MMPicState === 12 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 12 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep13.jpg" className="Change-Image" style={{height:MMPicState === 13 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 13 ? "block" : "none"}}/>
          <img loading="lazy" alt="Loading" src="../hazkepek/changeMangoKep14.jpg" className="Change-Image" style={{height:MMPicState === 14 ? '100vh' : '0vh',backgroundColor:"black",display:MMPicState === 14 ? "block" : "none"}}/>
          <div className="overlap-group">
            <div className="herocontentwrap">
              {MMPicState === 0 && <div className="text-wrapper">Magic Mango</div>}
            </div>
            <div className="navcont">
              <img loading="lazy" src="/img3/klogo3.png" className="logo" onClick={() => changeSlug("/")}/>
              <NavLinks
                changeSlug={changeSlug}
                LangMainTitStart={LangMainTitStart}
                LangMainTitGal={LangMainTitGal}
                LangMainTitCont={LangMainTitCont}
                LangMainTitPri={LangMainTitPri}
                LangMainTitRes={LangMainTitRes}
                LangMainTitInf={LangMainTitInf}
                sts={[false,false,false,false,true,false]}
              />
              <LanguageDropdown/>
            </div>
          </div>
          <div className="cpimg left-arrow" onClick={()=>handleLeftClick()}>
                <img loading="lazy" alt="Bal nyíl" src="/img/left-arrow.png" style={{height:'100%'}} />
            </div>
            <div className="cpimg right-arrow" onClick={()=>handleRightClick()}>
                <img loading="lazy" alt="Jobb nyíl" src="/img/right-arrow.png" style={{height:'100%'}} />
            </div>
        </div>
      </div>
      <div className="div-wrapper">
        <div className="div-3">
          <div className="frame-wrapper">
            <div className="frame-3">
              <div className="magicmangowrap">
                <div className="text-wrapper-4">Magic Mango</div>
                <div className="magicmangovonal" />
              </div>
              <div className="ferohely">
                <div className="text-wrapper-5">2</div>
                <img loading="lazy" className="icon-user" alt="Icon user" src="/img/icon-user.png" />
              </div>
            </div>
          </div>
          <div className="szoveg">
            <p className="p">
              {LangCryPBedroomDescription()}
            </p>
          </div>
          <div className="ikonok">
            <div className="div-4">
              <img loading="lazy" className="img-2" alt="Konyhaikon" src="/img/konyhaikon.svg" />
              <div className="text-wrapper-6">{LangCryPKitchen()}</div>
            </div>
            <div className="div-5">
              <img loading="lazy" className="img-2" alt="Tvikon" src="/img/tvikon.svg" />
              <div className="text-wrapper-6">{LangCryPTV()}</div>
            </div>
            <div className="div-6">
              <img loading="lazy" className="img-3" alt="Wifiikon" src="/img/wifiikon.svg" />
              <div className="text-wrapper-7">{LangCryPWiFi()}</div>
            </div>
            <div className="div-6">
              <img loading="lazy" className="img-3" alt="Reggeliikon" src="/img/reggeliikon.svg" />
              <div className="text-wrapper-7">{LangCryPBreakfast()}</div>
            </div>
          </div>
          <div className="ikonok">
            <div className="div-4">
              <img loading="lazy" className="img-2" alt="Parkolasikon" src="/img/parkolasikon.svg" />
              <div className="text-wrapper-6">{LangCryPParking()}</div>
            </div>
            <div className="div-5">
              <img loading="lazy" className="img-2" alt="Jacuzziikon" src="/img/jacuzziikon.png" />
              <div className="text-wrapper-6">{LangCryPJacuzzi()}</div>
            </div>
            <div className="div-6">
              <img loading="lazy" className="img-3" alt="Legkondiikon" src="/img/legkondiikon.svg" />
              <div className="text-wrapper-7">{LangCryPAirConditioning()}</div>
            </div>
          </div>
          <div className="szoveg">
            <p className="text-wrapper-8">
              {LangCryPKitchenDining()}
            </p>
          </div>
          <div className="gombwrap">
            <div className="gombcont">
              <div className="gombvonal" />
              <div className="gomb">
                <div className="text-wrapper-9" onClick={() => changeSlug("/fog")}>{LangCryPProceedToBooking()}</div>
              </div>
              <div className="gomb" onClick={() => changeSlug("/")} style={{width:"80px",margin:"5px 0px 0px 45px"}}>
                <div className="text-wrapper-9">{NaptarBack()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Magicmango;