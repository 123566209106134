import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {  logPageView, logEvent, logException, logTiming } from './analytics';
import NaptarPage from './components/NaptarPages';
import KepPopup from './components/KepPopup';
import AdminLoginPage from './admin/AdminLoginPage';
import { AdminProvider } from './admin/AdminContext';
import Desktop from './screens/Desktop/Desktop';
import Greenpassion from './screens/Greenpassion/Greenpassion';
import Magicmango from './screens/Magicmango/Magicmango';
import GpesResolution from './screens/GpesResolution/GpesResolution';
import CrystalPower from './screens/CrydtalPower/CrystalPower';
import Iphoneinfo from './screens/Iphoneinfo/Iphoneinfo';
import Iphonegreen from './screens/Iphonegreen/Iphonegreen';
import Iphonecrystal from './screens/Iphonecrystal/Iphonecrystal';
import IphoneProMax from './screens/IphoneProMax/IphoneProMax';
import Iphonemagicmango from './screens/Iphonemagicmango/Iphonemagicmango';


import './App.css';
import { BaseProvider } from './contexts/BaseContext';
import { LanguageProvider } from './contexts/LanguageContext';
import LoadingScreen from './components/LoadingScreen';
import { AnalizeProvider } from './contexts/AnalizeContext';

const App = () => {
  const width = window.innerWidth;
  const isDesktop = width >= 1024;

  useEffect(() => {
    logPageView();
    window.addEventListener('popstate', logPageView);
    
    
    return () => {
      window.removeEventListener('popstate', logPageView);
    };
  }, []);
  

  return (
    <BaseProvider>
    <AnalizeProvider>
      <LanguageProvider>
        <AdminProvider>
        <Helmet>
            <title>KurukHill</title>
            <meta name="description" content="Merüljetek el a kényeztetés páratlan élményében." />
            <meta property="og:title" content="KurukHill" />
            <meta property="og:description" content="Merüljetek el a kényeztetés páratlan élményében." />
            <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
            <meta property="og:url" content="https://www.kurukhill.hu" />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <Router>
            <LoadingScreen />
            <Routes>
              {isDesktop ? (
                <>
                  <Route path="információ" element={<div><KepPopup /><NaptarPage /><Desktop /></div>} />
                  <Route path="greenpassion" element={<div><KepPopup /><NaptarPage /><Greenpassion /></div>} />
                  <Route path="magicmango" element={<div><KepPopup /><NaptarPage /><Magicmango /></div>} />
                  <Route path="/" element={<div><KepPopup /><NaptarPage /><GpesResolution /></div>} />
                  <Route path="crystalpower" element={<div><KepPopup /><NaptarPage /><CrystalPower /></div>} />
                </>
              ) : (
                <>
                  <Route path="információ" element={<div><KepPopup /><NaptarPage /><Iphoneinfo /></div>} />
                  <Route path="greenpassion" element={<div><KepPopup /><NaptarPage /><Iphonegreen /></div>} />
                  <Route path="crystalpower" element={<div><KepPopup /><NaptarPage /><Iphonecrystal /></div>} />
                  <Route path="/" element={<div><KepPopup /><NaptarPage /><IphoneProMax /></div>} />
                  <Route path="magicmango" element={<div><KepPopup /><NaptarPage /><Iphonemagicmango /></div>} />
                </>
              )}
              <Route path="naptar" element={<NaptarPage />} />
              <Route path="admin" element={<AdminLoginPage />} />
            </Routes>
            <div style={{height:"0vh",width:'0vw',overflow:'hidden'}}>
              <h1>Kurukhill</h1>
              <h1>Greenpassion</h1>
              <h1>MagicMango</h1>
              <h1>CrystalPower</h1>
              <h1>Nyaralás</h1>
              <h1>Pihenés</h1>
              <h1>Jakuzzi</h1>
              <h1>Szőlő</h1>
              <h1>Kikapcsolódás</h1>
              <h1>Romantika</h1>
              <h1>Felfrissülés</h1>
              <h1>blablalba</h1>
              <h1>Jakuzzi</h1>
              <h1>Faház</h1>
              <h1>Pároknak</h1>
              <h1>Kellemes pihenés</h1>
              <h1>Reggeli</h1>
              <h1>Játékok</h1>
              <h1>Háromszög alakú kunyhó</h1>
              <h1>Feelinges</h1>
              <h1>Zalakaros</h1>
              <h1>Badacsonyi kilátás</h1>
              <h1>Szőlősbirtok</h1>
              <h1>Dombon</h1>
              <h1>Erdővel körülvéve</h1>
              <h1>Nomád falu</h1>
              <h1>Romantikus</h1>
              <h1>Pihenés</h1>
              <h1>Felfrissülés</h1>
              <h1>Kikapcsolódás</h1>
              <h1>Nyaralás</h1>
              <h1>Privát jakuzzi</h1>
              <h1>Luxus faház</h1>
              <h1>Szőlőhegy</h1>
              <h1>Exkluzív szállás</h1>
              <h1>Békés környezet</h1>
              <h1>Panorámás kilátás</h1>
              <h1>Természetközeli</h1>
              <h1>Nyugodt légkör</h1>
              <h1>Kényelmes szállás</h1>
              <h1>Privát szféra</h1>
              <h1>Szőlőtőkék</h1>
              <h1>Hangulatos</h1>
              <h1>Autentikus élmény</h1>
              <h1>Nyugalom</h1>
              <h1>Barátságos vendéglátás</h1>
              <h1>Intim környezet</h1>
              <h1>Friss levegő</h1>
              <h1>Kerti játékok</h1>
              <h1>Kényelmes ágy</h1>
              <h1>Modern berendezés</h1>
              <h1>Felszerelt konyha</h1>
              <h1>Kerti bútorok</h1>
              <h1>Szabadtéri medence</h1>
              <h1>Túrázási lehetőségek</h1>
              <h1>Kerékpározás</h1>
              <h1>Borospince</h1>
              <h1>Szőlőhegyi túra</h1>
              <h1>Kültéri grillezés</h1>
              <h1>Éjszakai égbolt</h1>
              <h1>Borkóstolás</h1>
              <h1>Fűtött jakuzzi</h1>
              <h1>Szőlőszem szedés</h1>
              <h1>Ébredés madárcsicsergéssel</h1>
              <h1>Élővilág</h1>
              <h1>Romantikus vacsora</h1>
              <h1>Naplemente</h1>
              <h1>Téli túrák</h1>
              <h1>Havas táj</h1>
              <h1>Kandalló</h1>
              <h1>Meleg takarók</h1>
              <h1>Hálózsák</h1>
              <h1>Csillagok</h1>
              <h1>Természetjárás</h1>
              <h1>Vadregényes táj</h1>
              <h1>Őszi kirándulás</h1>
              <h1>Erdei séta</h1>
              <h1>Madárles</h1>
              <h1>Kulturális programok</h1>
              <h1>Helyi gasztronómia</h1>
              <h1>Helyi borok</h1>
              <h1>Szőlőskert</h1>
              <h1>Páros masszázs</h1>
              <h1>Wellness</h1>
              <h1>Relaxáció</h1>
              <h1>Kültéri dézsafürdő</h1>
              <h1>Nyári éjszakák</h1>
              <h1>Szúnyogháló</h1>
              <h1>Franciaágy</h1>
              <h1>Vidéki hangulat</h1>
              <h1>Autóval megközelíthető</h1>
              <h1>Könnyű parkolás</h1>
              <h1>Szálláshely</h1>
              <h1>Mini bár</h1>
              <h1>Szállás Zalakaros</h1>
              <h1>Élményfürdő</h1>
              <h1>Balaton közelsége</h1>
              <h1>Gyógyfürdő</h1>
              <h1>Szőlőültetvény</h1>
              <h1>Romantikus este</h1>
              <h1>Napozóágy</h1>
              <h1>Szőlőskert látogatás</h1>
              <h1>Kényelmes pihenés</h1>
              <h1>Természet szerelmeseinek</h1>
              <h1>Páros kikapcsolódás</h1>
              <h1>Erdő mélyén</h1>
              <h1>Faház Zalakaros</h1>
              <h1>Prémium szolgáltatás</h1>
              <h1>Elegáns környezet</h1>
            </div>
          </Router>
        </AdminProvider>
      </LanguageProvider>
      </AnalizeProvider>
    </BaseProvider>
  );
};

export default App;
