import React from 'react';
import { useAnalize } from '../contexts/AnalizeContext';

const NavLinks = ({ changeSlug, LangMainTitStart, LangMainTitGal, LangMainTitCont, LangMainTitPri, LangMainTitRes, LangMainTitInf ,sts}) => {
  const {trackEvent} = useAnalize();
  return (
    <div className="navlinkek-n">
      <NavItem text={LangMainTitStart()} onClick={() => {changeSlug("/");trackEvent("PC","Navigated","MainPage");}} width="77px" st={sts[0]}/>
      <NavItem text={LangMainTitGal()} onClick={() => {changeSlug("/gal");trackEvent("PC","Navigated","MainPage-Galery");}} st={sts[1]}/>
      <NavItem text={LangMainTitCont()} onClick={() =>{ changeSlug("/kap");trackEvent("PC","Navigated","MainPage-Contact");}} st={sts[2]}/>
      <NavItem text={LangMainTitPri()} onClick={() => {changeSlug("/pri");trackEvent("PC","Navigated","InfoPage-Prices");}} st={sts[3]}/>
      <NavItem text={LangMainTitRes()} onClick={() => {changeSlug("/fog");trackEvent("PC","Navigated","Reservation");}} st={sts[4]}/>
      <NavItem text={LangMainTitInf()} onClick={() => {changeSlug("/info");trackEvent("PC","Navigated","InfoPage");}} st={sts[5]}/>
    </div>
  );
};

const NavItem = ({ text, onClick, width = "71px",st = false }) => {
  return (
    <div>
        {st ? 
        <div className="navitem-n" onClick={onClick}>
            <div className="text-wrapper-n">{text}</div>
            <div className="navvonal-n-dea"></div>
        </div>
        :
        <div className="navitem-n" onClick={onClick}>
            <div className="text-wrapper-n">{text}</div>
            <div className="navvonal-n"></div>
        </div>}
    </div>
  );
};

export default NavLinks;