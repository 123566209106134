import React, { useState, useEffect } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, getDay, parseISO, getDate, getDaysInMonth } from 'date-fns';
import DaySegment from '../components/DaySegment';
import { CalendarContainer, Header } from '../styles/CalendarStyles';
import axios from 'axios';
import { useBase } from '../contexts/BaseContext';
import TransformDiv from '../components/TransformDiv';
import { CalendarContainerMobile } from '../styles/CalendarStylesMobile';
import { CalendarContainerTablet } from '../styles/CalendarStylesTablet';

const AdminSuperNaptar = ({ year, month }) => {
    const {predefinedToken,selectedRangeCPAdmin, setSelectedRangeCPAdmin, setSelectedRangeGPAdmin, setSelectedRangeMMAdmin,doneAdmin,selectedRangeAdmin, setSelectedRangeAdmin,selectedRange2Admin, setSelectedRange2Admin,selectedRange3Admin, setSelectedRange3Admin,device} = useBase();
    const [currentDate, setCurrentDate] = useState(new Date(year, month - 1));
    const weekDays = ["Hé", "Ke", "Sze", "Csü", "Pé", "Szo", "Va"];
    
    const [selectedSegm,setSelectedSegm] = useState("");
    const [reservedGreenPassion, setReservedGreenPassion] = useState([]);
    const [reservedMagicMango, setReservedMagicMango] = useState([]);
    const [reservedCrystalPower, setReservedCrystalPower] = useState([]);
    const [pMonthDays,setpMonthDays] = useState([false,false]);
    const [pMonthDays2,setpMonthDays2] = useState([false,false]);
    const [pMonthDays3,setpMonthDays3] = useState([false,false]);
    const [sDays,setSDays] = useState([]);
    const [sDays2,setSDays2] = useState([]);
    const [sDays3,setSDays3] = useState([]);
    const [eDays,setEDays] = useState([]);
    const [eDays2,setEDays2] = useState([]);
    const [eDays3,setEDays3] = useState([]);

    useEffect(() => {
        const fetchE = async () => {
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/getEnds?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getEnds?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getEnds?houseId=3&month=${monthString}`, config)
                ]);
                setEDays(greenPassionResponse.data);
                setEDays2(magicMangoResponse.data);
                setEDays3(crystalPowerResponse.data);
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
            }
        };
        fetchE();
    }, [currentDate]);
    useEffect(() => {


    }, [sDays,eDays]);
    useEffect(() => {
        if(doneAdmin) {
            setSelectedRangeCPAdmin(selectedRange3Admin);
            setSelectedRangeGPAdmin(selectedRangeAdmin);
            setSelectedRangeMMAdmin(selectedRange2Admin);
            
        }
    }, [doneAdmin]);

    useEffect(() => {
        const fetchS = async () => {
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/getStarts?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getStarts?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getStarts?houseId=3&month=${monthString}`, config)
                ]);
                setSDays(greenPassionResponse.data);
                setSDays2(magicMangoResponse.data);
                setSDays3(crystalPowerResponse.data);
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
            }
        };
        fetchS();
    }, [currentDate]);

    useEffect(() => {
        const fetchReservedDates = async () => {
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/bookedDates?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/bookedDates?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/bookedDates?houseId=3&month=${monthString}`, config)
                ]);
                const formatDates = (dates) => dates.map(date => format(parseISO(date), 'yyyy-MM-dd'));

                setReservedGreenPassion(formatDates(greenPassionResponse.data));
                setReservedMagicMango(formatDates(magicMangoResponse.data));
                setReservedCrystalPower(formatDates(crystalPowerResponse.data));
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
            }
        };
        fetchReservedDates();
    }, [currentDate]);

    useEffect(() => {
        const fetchReservedDates = async () => {
            const monthString = format(currentDate, 'yyyy-MM');
            const config = {
                headers: {
                    Authorization: `Bearer ${predefinedToken}`
                }
            };
            try {
                const [greenPassionResponse, magicMangoResponse, crystalPowerResponse] = await Promise.all([
                    axios.get(`https://kurukhill.hu:41808/reservation/getPrevAndNext?houseId=1&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getPrevAndNext?houseId=2&month=${monthString}`, config),
                    axios.get(`https://kurukhill.hu:41808/reservation/getPrevAndNext?houseId=3&month=${monthString}`, config)
                ]);

                setpMonthDays(greenPassionResponse.data);
                setpMonthDays2(magicMangoResponse.data);
                setpMonthDays3(crystalPowerResponse.data);
            } catch (error) {
                console.error("Error fetching reserved dates:", error);
            }
        };
        fetchReservedDates();
    }, [currentDate]);

    useEffect(() => {
        if (selectedRangeAdmin.start === null && selectedRangeAdmin.end === null && 
            selectedRange2Admin.start === null && selectedRangeAdmin.end === null && 
            selectedRange3Admin.start === null && selectedRangeAdmin.end === null  
        ) {

        }
    }, [selectedRangeAdmin,selectedRange2Admin,selectedRange3Admin]);

    const isDateInList = (date, list) => {
        return list.includes(date);
    };

    const GetSelRange = (type) =>{
        if (type === "GreenPassion") {
            return selectedRangeAdmin;
        }
        if (type === "MagicMango") {
            return selectedRange2Admin;
        }
        if (type === "CrystalPower") {
            return selectedRange3Admin;
        }
    }
    const adjustDate = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return format(result, 'yyyy-MM-dd');
    };

    const isRangeValid = (start, end, reservedDays) => {
        const dayRange = eachDayOfInterval({ start: parseISO(start), end: parseISO(end) });
        return !dayRange.some(day => reservedDays.includes(format(day, 'yyyy-MM-dd')));
    };
    function addoneAdminDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      function minusOneDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() - 1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

    const handleSegmentSelect = (day, segmentType) => {
        const segmentId = `${format(day, 'yyyy-MM-dd')}-${segmentType}`;
        const reservedSegmentDays = {
            GreenPassion: reservedGreenPassion,
            MagicMango: reservedMagicMango,
            CrystalPower: reservedCrystalPower
        }[segmentType];
    
        
        if (segmentType === "GreenPassion") {
            const formattedDay = format(day, 'yyyy-MM-dd');
            const isReserved = reservedGreenPassion.includes(formattedDay);
                const selrange = GetSelRange(segmentType);

                const prevDay = adjustDate(day, -1); // Előző nap
                const nextDay = adjustDate(day, 1); // Következő nap

                const isPrevReserved = isDateInList(prevDay, reservedGreenPassion);
                const isNextReserved = isDateInList(nextDay, reservedGreenPassion);

                const dayOfMonth = getDate(day);
                const daysInMonth = getDaysInMonth(day);

                let previousAndNextMonthDays = [true,false];
                if (segmentType === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
                if (segmentType === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
                if (segmentType === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
                const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

                // Determine isStart2 and isEnd2 with the original logic
                let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
                let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

                // Adjust logic for the first and last day of the month
                if (dayOfMonth === 1) {
                     if (isReserved && !isPrevMonthLastDayReserved) {
                        isEnd2 = false;
                        isStart2 = true;
                     }else {
                        isEnd2 = false;
                        isStart2 = false;
                        if ( isReserved && !isNextReserved) {
                            isEnd2 = true;
                        }
                    }
                    
                    } else if (dayOfMonth === daysInMonth) {
                    if (isReserved && !isNextMonthFirstDayReserved) {
                        isEnd2 = true;
                        isStart2 = false;
                    } else {
                        isEnd2 = false;
                        isStart2 = false;
                        if (isReserved &&!isPrevReserved) {
                            isStart2 = true;
                        }
                    }
                }

                if (segmentType === "GreenPassion") {
                        if (sDays.includes(formattedDay)) {
                            isStart2 = true;
                            isEnd2 = false;
                        }
                        if (eDays.includes(formattedDay)) {
                            isEnd2 = true;
                            isStart2 = false;
                        }
                        if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                            isStart2 = false;
                            isEnd2 = false;
                        }
                }
                if (segmentType === "MagicMango") {
                    if (isDateInList(formattedDay,sDays2)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays2)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (segmentType === "CrystalPower") {
                    if (isDateInList(formattedDay,sDays3)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays3)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                const isStart = selrange.start === segmentId || isStart2;
                const isEnd = selrange.end === segmentId ||isEnd2;
            if (selectedRangeAdmin.start && !selectedRangeAdmin.end && (segmentType !== selectedSegm)) {
                setSelectedRangeAdmin({ start: null, end: null });
                return;
            }
            if (selectedRangeAdmin.start && selectedRangeAdmin.end && (segmentType === selectedSegm)) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRangeAdmin({ start: null, end: null });
                return;
            }
            if (reservedSegmentDays.includes(format(day, 'yyyy-MM-dd'))) {
                //return; // Prevent selection of reserved days
                
                if ((!selectedRangeAdmin.start || selectedRangeAdmin.end) && !isEnd) {
                    return;
                 }
                 if ((selectedRangeAdmin.start && !selectedRangeAdmin.end) && !isStart) {
                    return;
                 }
            }
            if (!selectedRangeAdmin.start || selectedRangeAdmin.end) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRangeAdmin({ start: segmentId, end: null });
                setSelectedSegm(segmentType);
            } else if (segmentId !== selectedRangeAdmin.start) {
                // If there is a
                //if (segmentType === selectedSegm) {
                const startDay = selectedRangeAdmin.start.split('-')[0]+"-"+selectedRangeAdmin.start.split('-')[1]+"-"+selectedRangeAdmin.start.split('-')[2];
                const selectedSecDay = segmentId.split('-')[0] +"-"+segmentId.split('-')[1]+"-"+segmentId.split('-')[2]
                const check = addoneAdminDay(startDay) === selectedSecDay;
                if (segmentId >= selectedRangeAdmin.start) {
                    const newStartDay = addoneAdminDay(startDay);
                    if (isRangeValid(startDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRangeAdmin({ ...selectedRangeAdmin, end: segmentId });
                    }else if (isStart2 &&check) {
                        setSelectedRangeAdmin({ ...selectedRangeAdmin, end: segmentId });
                    }else if (isStart2 && isRangeValid(addoneAdminDay(startDay), minusOneDay(format(day, 'yyyy-MM-dd')), reservedSegmentDays)) {
                        setSelectedRangeAdmin({ ...selectedRangeAdmin, end: segmentId });
                    }else if (isRangeValid(newStartDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRangeAdmin({ ...selectedRangeAdmin, end: segmentId });
                    } else {
                        //alert("The selection includes reserved days. Please select a different range.");
                        //setSelectedRangeAdmin({ start: null, end: null });
                        setSelectedRangeAdmin({ start: null, end: null });
                            setSelectedSegm("");
                    }
                } else {

                    // If the selected day is before the start within the same segment type, reset the start.
                    setSelectedRangeAdmin({ start: segmentId, end: null });
                }
                } else {
                    setSelectedRangeAdmin({ start: null, end: null });
                            setSelectedSegm("");
                }
            }
            else if (segmentType === "MagicMango") {
                const formattedDay = format(day, 'yyyy-MM-dd');
                const isReserved = reservedMagicMango.includes(formattedDay);
                const selrange = GetSelRange(segmentType);

                const prevDay = adjustDate(day, -1); // Előző nap
                const nextDay = adjustDate(day, 1); // Következő nap

                const isPrevReserved = isDateInList(prevDay, reservedMagicMango);
                const isNextReserved = isDateInList(nextDay, reservedMagicMango);

                const dayOfMonth = getDate(day);
                const daysInMonth = getDaysInMonth(day);

                let previousAndNextMonthDays = [true,false];
                if (segmentType === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
                if (segmentType === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
                if (segmentType === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
                const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

                // Determine isStart2 and isEnd2 with the original logic
                let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
                let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

                // Adjust logic for the first and last day of the month
                if (dayOfMonth === 1) {
                     if (isReserved && !isPrevMonthLastDayReserved) {
                        isEnd2 = false;
                        isStart2 = true;
                     }else {
                        isEnd2 = false;
                        isStart2 = false;
                        if ( isReserved && !isNextReserved) {
                            isEnd2 = true;
                        }
                    }
                    
                    } else if (dayOfMonth === daysInMonth) {
                    if (isReserved && !isNextMonthFirstDayReserved) {
                        isEnd2 = true;
                        isStart2 = false;
                    } else {
                        isEnd2 = false;
                        isStart2 = false;
                        if (isReserved &&!isPrevReserved) {
                            isStart2 = true;
                        }
                    }
                }

                if (segmentType === "GreenPassion") {
                    if (sDays.includes(formattedDay)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (eDays.includes(formattedDay)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (segmentType === "MagicMango") {
                    if (isDateInList(formattedDay,sDays2)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays2)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                if (segmentType === "CrystalPower") {
                    if (isDateInList(formattedDay,sDays3)) {
                        isStart2 = true;
                        isEnd2 = false;
                    }
                    if (isDateInList(formattedDay,eDays3)) {
                        isEnd2 = true;
                        isStart2 = false;
                    }
                    if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                        isStart2 = false;
                        isEnd2 = false;
                    }
                }
                const isStart = selrange.start === segmentId || isStart2;
                const isEnd = selrange.end === segmentId ||isEnd2;
                if (selectedRange2Admin.start && !selectedRange2Admin.end && (segmentType !== selectedSegm)) {
                    setSelectedRange2Admin({ start: null, end: null });
                    return;
                }
                if (selectedRange2Admin.start && selectedRange2Admin.end && (segmentType === selectedSegm)) {
                    // If there is no start or if the end is already set, restart the selection.
                    setSelectedRange2Admin({ start: null, end: null });
                    return;
                }
                if (reservedSegmentDays.includes(format(day, 'yyyy-MM-dd'))) {
                    //return; // Prevent selection of reserved days
                    
                    if ((!selectedRange2Admin.start || selectedRange2Admin.end) && !isEnd) {
                        return;
                     }
                     if ((selectedRange2Admin.start && !selectedRange2Admin.end) && !isStart) {
                        return;
                     }
                }
                if (!selectedRange2Admin.start || selectedRange2Admin.end) {
                    // If there is no start or if the end is already set, restart the selection.
                    setSelectedRange2Admin({ start: segmentId, end: null });
                    setSelectedSegm(segmentType);
                } else if (segmentId !== selectedRange2Admin.start) {
                    // If there is a
                    //if (segmentType === selectedSegm) {
                    const startDay = selectedRange2Admin.start.split('-')[0]+"-"+selectedRange2Admin.start.split('-')[1]+"-"+selectedRange2Admin.start.split('-')[2];
                    
                    const selectedSecDay = segmentId.split('-')[0] +"-"+segmentId.split('-')[1]+"-"+segmentId.split('-')[2]
                    const check = addoneAdminDay(startDay) === selectedSecDay;
                    if (segmentId >= selectedRange2Admin.start) {
                        const newStartDay = addoneAdminDay(startDay);
                    if (isRangeValid(startDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange2Admin({ ...selectedRange2Admin, end: segmentId });
                    }else if (isStart2 && check) {
                        setSelectedRange2Admin({ ...selectedRange2Admin, end: segmentId });
                    }else if (isStart2 && isRangeValid(addoneAdminDay(startDay), minusOneDay(format(day, 'yyyy-MM-dd')), reservedSegmentDays)) {
                        setSelectedRange2Admin({ ...selectedRange2Admin, end: segmentId });
                    }else if (isRangeValid(newStartDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange2Admin({ ...selectedRange2Admin, end: segmentId });
                    } else {
                        //alert("The selection includes reserved days. Please select a different range.");
                        //setSelectedRangeAdmin({ start: null, end: null });
                        setSelectedRange2Admin({ start: null, end: null });
                            setSelectedSegm("");
                    }
                    } else {
    
                        // If the selected day is before the start within the same segment type, reset the start.
                        setSelectedRange2Admin({ start: segmentId, end: null });
                    }
                } else {
                    setSelectedRange2Admin({ start: null, end: null });
                            setSelectedSegm("");
                }
        }
        else if (segmentType === "CrystalPower") {
            const formattedDay = format(day, 'yyyy-MM-dd');
            const isReserved = reservedCrystalPower.includes(formattedDay);
            const selrange = GetSelRange(segmentType);

            const prevDay = adjustDate(day, -1); // Előző nap
            const nextDay = adjustDate(day, 1); // Következő nap

            const isPrevReserved = isDateInList(prevDay, reservedCrystalPower);
            const isNextReserved = isDateInList(nextDay, reservedCrystalPower);

            const dayOfMonth = getDate(day);
            const daysInMonth = getDaysInMonth(day);

            let previousAndNextMonthDays = [true,false];
            if (segmentType === "GreenPassion") {previousAndNextMonthDays = pMonthDays}
            if (segmentType === "MagicMango") {previousAndNextMonthDays = pMonthDays2}
            if (segmentType === "CrystalPower") {previousAndNextMonthDays = pMonthDays3}
            const [isPrevMonthLastDayReserved, isNextMonthFirstDayReserved] = previousAndNextMonthDays;

            // Determine isStart2 and isEnd2 with the original logic
            let isStart2 = isReserved && (!isPrevReserved && isNextReserved);
            let isEnd2 = isReserved && (isPrevReserved && !isNextReserved);

            // Adjust logic for the first and last day of the month
            if (dayOfMonth === 1) {
                 if (isReserved && !isPrevMonthLastDayReserved) {
                    isEnd2 = false;
                    isStart2 = true;
                 }else {
                    isEnd2 = false;
                    isStart2 = false;
                    if ( isReserved && !isNextReserved) {
                        isEnd2 = true;
                    }
                }
                
                } else if (dayOfMonth === daysInMonth) {
                if (isReserved && !isNextMonthFirstDayReserved) {
                    isEnd2 = true;
                    isStart2 = false;
                } else {
                    isEnd2 = false;
                    isStart2 = false;
                    if (isReserved &&!isPrevReserved) {
                        isStart2 = true;
                    }
                }
            }

            if (segmentType === "GreenPassion") {
                if (sDays.includes(formattedDay)) {
                    isStart2 = true;
                    isEnd2 = false;
                }
                if (eDays.includes(formattedDay)) {
                    isEnd2 = true;
                    isStart2 = false;
                }
                if (sDays.includes(formattedDay) && eDays.includes(formattedDay)) {
                    isStart2 = false;
                    isEnd2 = false;
                }
            }
            if (segmentType === "MagicMango") {
                if (isDateInList(formattedDay,sDays2)) {
                    isStart2 = true;
                    isEnd2 = false;
                }
                if (isDateInList(formattedDay,eDays2)) {
                    isEnd2 = true;
                    isStart2 = false;
                }
                if (isDateInList(formattedDay,sDays2) && isDateInList(formattedDay,eDays2)) {
                    isStart2 = false;
                    isEnd2 = false;
                }
            }
            if (segmentType === "CrystalPower") {
                if (isDateInList(formattedDay,sDays3)) {
                    isStart2 = true;
                    isEnd2 = false;
                }
                if (isDateInList(formattedDay,eDays3)) {
                    isEnd2 = true;
                    isStart2 = false;
                }
                if (isDateInList(formattedDay,sDays3) && isDateInList(formattedDay,eDays3)) {
                    isStart2 = false;
                    isEnd2 = false;
                }
            }
            const isStart = selrange.start === segmentId || isStart2;
            const isEnd = selrange.end === segmentId ||isEnd2;
            if (selectedRange3Admin.start && selectedRange3Admin.end && (segmentType === selectedSegm)) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRange3Admin({ start: null, end: null });
                return;
            }
            if (reservedSegmentDays.includes(format(day, 'yyyy-MM-dd'))) {
                //return; // Prevent selection of reserved days
                
                if ((!selectedRange3Admin.start || selectedRange3Admin.end) && !isEnd) {
                    return;
                 }
                 if ((selectedRange3Admin.start && !selectedRange3Admin.end) && !isStart) {
                    return;
                 }
            }
            if (!selectedRange3Admin.start || selectedRange3Admin.end) {
                // If there is no start or if the end is already set, restart the selection.
                setSelectedRange3Admin({ start: segmentId, end: null });
                setSelectedSegm(segmentType);
            } else if (segmentId !== selectedRange3Admin.start)  {
                // If there is a
                //if (segmentType === selectedSegm) {
                const startDay = selectedRange3Admin.start.split('-')[0]+"-"+selectedRange3Admin.start.split('-')[1]+"-"+selectedRange3Admin.start.split('-')[2];
                const selectedSecDay = segmentId.split('-')[0] +"-"+segmentId.split('-')[1]+"-"+segmentId.split('-')[2]
                const check = addoneAdminDay(startDay) === selectedSecDay;
                if (segmentId >= selectedRange3Admin.start) {
                    const newStartDay = addoneAdminDay(startDay);
                    
                    if (isRangeValid(startDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange3Admin({ ...selectedRange3Admin, end: segmentId });
                    }else if (isStart2 && check) {
                        setSelectedRange3Admin({ ...selectedRange3Admin, end: segmentId });
                    }else if (isStart2 && isRangeValid(addoneAdminDay(startDay), minusOneDay(format(day, 'yyyy-MM-dd')), reservedSegmentDays)) {
                        setSelectedRange3Admin({ ...selectedRange3Admin, end: segmentId });
                    }else if (isRangeValid(newStartDay, format(day, 'yyyy-MM-dd'), reservedSegmentDays)) {
                        setSelectedRange3Admin({ ...selectedRange3Admin, end: segmentId });
                    } else {
                        //alert("The selection includes reserved days. Please select a different range.");
                        //setSelectedRangeAdmin({ start: null, end: null });
                        setSelectedRange3Admin({ start: null, end: null });
                            setSelectedSegm("");
                    }
                } else {

                    // If the selected day is before the start within the same segment type, reset the start.
                    setSelectedRange3Admin({ start: segmentId, end: null });
                }
            } else {
                setSelectedRange3Admin({ start: null, end: null });
                        setSelectedSegm("");
            }
        }
    };
    

    const daysInMonth = eachDayOfInterval({
        start: startOfMonth(currentDate),
        end: endOfMonth(currentDate)
    });

    const firstDayOfMonth = getDay(startOfMonth(currentDate));
    const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1; // Adjust for Monday start; note that getDay() returns 0 for Sunday

    const getMonth = (str) => {
        const mo = str.split("-")[0];
        if (mo === "January") {return str.split("-")[1]+" Január"}
        else if (mo === "February") {return str.split("-")[1]+" Február"}
        else if (mo === "March") {return str.split("-")[1]+" Március"}
        else if (mo === "April") {return str.split("-")[1]+" Április"}
        else if (mo === "May") {return str.split("-")[1]+" Május"}
        else if (mo === "June") {return str.split("-")[1]+" Június"}
        else if (mo === "July") {return str.split("-")[1]+" Július"}
        else if (mo === "August") {return str.split("-")[1]+" Augusztus"}
        else if (mo === "September") {return str.split("-")[1]+" Szeptember"}
        else if (mo === "October") {return str.split("-")[1]+" Október"}
        else if (mo === "November") {return str.split("-")[1]+" November"}
        else if (mo === "December") {return str.split("-")[1]+" December"}
        else {return str.split("-")[0]}
    }
    const leftarr = "<";
    const rightarr = ">";
    return (
        <div className='naptarHolder'>
            <TransformDiv/>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1vh 2vw', alignItems: 'center' }}>
                <button className='SwitchButt' onClick={() => setCurrentDate(subMonths(currentDate, 1))}>{leftarr}</button>
                <span>{getMonth(format(currentDate, 'MMMM-yyyy'))}</span>
                <button className='SwitchButt' onClick={() => setCurrentDate(addMonths(currentDate, 1))}>{rightarr}</button>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', textAlign: 'center', padding: '10px' }}>
                {weekDays.map(day => <Header key={day}>{day}</Header>)}
            </div>
            {device === "PC" && <CalendarContainer>
                {Array.from({ length: offset }, (_, i) => (
                    <div key={`empty-${i}`} style={{ opacity: 0 }}></div> // Empty divs for days before start of the month
                ))}
                {daysInMonth.map(day => (
                    <DaySegment
                        key={format(day, 'yyyy-MM-dd')}
                        day={day}
                        onSegmentSelect={handleSegmentSelect}
                        selectedRange={selectedRangeAdmin}
                        selectedRange2={selectedRange2Admin}
                        selectedRange3={selectedRange3Admin}
                        selectedSegm={selectedSegm}
                        pMonthDays={pMonthDays}
                        pMonthDays2={pMonthDays2}
                        pMonthDays3={pMonthDays3}
                        sDays={sDays}
                        sDays2={sDays2}
                        sDays3={sDays3}
                        eDays={eDays}
                        eDays2={eDays2}
                        eDays3={eDays3}
                        reservedDays={{
                            GreenPassion: reservedGreenPassion,
                            MagicMango: reservedMagicMango,
                            CrystalPower: reservedCrystalPower
                        }}
/>
                ))}
            </CalendarContainer>}
            {device === "PHONE" && <CalendarContainerMobile>
                {Array.from({ length: offset }, (_, i) => (
                    <div key={`empty-${i}`} style={{ opacity: 0 }}></div> // Empty divs for days before start of the month
                ))}
                {daysInMonth.map(day => (
                    <DaySegment
                        key={format(day, 'yyyy-MM-dd')}
                        day={day}
                        onSegmentSelect={handleSegmentSelect}
                        selectedRange={selectedRangeAdmin}
                        selectedRange2={selectedRange2Admin}
                        selectedRange3={selectedRange3Admin}
                        selectedSegm={selectedSegm}
                        pMonthDays={pMonthDays}
                        pMonthDays2={pMonthDays2}
                        pMonthDays3={pMonthDays3}
                        sDays={sDays}
                        sDays2={sDays2}
                        sDays3={sDays3}
                        eDays={eDays}
                        eDays2={eDays2}
                        eDays3={eDays3}
                        reservedDays={{
                            GreenPassion: reservedGreenPassion,
                            MagicMango: reservedMagicMango,
                            CrystalPower: reservedCrystalPower
                        }}
/>
                ))}
            </CalendarContainerMobile>}
            {device === "TABLET" && <CalendarContainerTablet>
                {Array.from({ length: offset }, (_, i) => (
                    <div key={`empty-${i}`} style={{ opacity: 0 }}></div> // Empty divs for days before start of the month
                ))}
                {daysInMonth.map(day => (
                    <DaySegment
                        key={format(day, 'yyyy-MM-dd')}
                        day={day}
                        onSegmentSelect={handleSegmentSelect}
                        selectedRange={selectedRangeAdmin}
                        selectedRange2={selectedRange2Admin}
                        selectedRange3={selectedRange3Admin}
                        selectedSegm={selectedSegm}
                        pMonthDays={pMonthDays}
                        pMonthDays2={pMonthDays2}
                        pMonthDays3={pMonthDays3}
                        sDays={sDays}
                        sDays2={sDays2}
                        sDays3={sDays3}
                        eDays={eDays}
                        eDays2={eDays2}
                        eDays3={eDays3}
                        reservedDays={{
                            GreenPassion: reservedGreenPassion,
                            MagicMango: reservedMagicMango,
                            CrystalPower: reservedCrystalPower
                        }}
/>
                ))}
            </CalendarContainerTablet>}
        </div>
    );
};

export default AdminSuperNaptar;
