import React, { useState, useEffect } from "react";
import './style.css';
import { useBase } from "../contexts/BaseContext";
import { useAnalize } from "../contexts/AnalizeContext";

const KepPopup = () => {
  const { on2, closePopup2, im, setIm } = useBase();
  const [transition, setTransition] = useState(false);
  const { trackEvent, updateStatistics } = useAnalize();
  const handleNextImage = () => {
    setTransition(true);
    setIm((prevIm) => (prevIm === 18 ? 1 : prevIm + 1));
    setTimeout(() => {
      setTransition(false);
    }, 300); // Match the duration of the CSS transition
    trackEvent('Gallery', 'Watched', 'Image');
    updateStatistics("imageviewed");
  };

  const handlePreviousImage = () => {
    setTransition(true);
    setIm((prevIm) => (prevIm === 1 ? 18 : prevIm - 1));
    setTimeout(() => {
      setTransition(false);
    }, 300); // Match the duration of the CSS transition
    trackEvent("Gallery", "Watched", "Image");
    updateStatistics("imageviewed");
  };

  return (
    <div>
      {on2 && (
        <div id="popup-container" className="popup-container">
          <div className="popup-header">
            <button className="close-buttonkep" onClick={closePopup2}>X</button>
          </div>
          <button className="arrow-button left-arrow2" onClick={handlePreviousImage}>{"<"}</button>
          <img loading="lazy" src="/img3/kep-1b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 1 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-2b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 2 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-3b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 3 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img/kep-4b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 4 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-5b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 5 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-6b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 6 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-7b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 7 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-8b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 8 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-10b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 9 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-9b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 10 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-11b.JPG" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 11 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-12b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 12 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img3/kep-13b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 13 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img/kep-14b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 14 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img/kep-9b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 15 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img/kep-10b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 16 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img/kep-11b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 17 ? 'block' : 'none'}}/>
              <img loading="lazy" src="/img/kep-12b.jpg" className={`keppopup ${transition ? 'fade' : ''}`} style={{display:im === 18 ? 'block' : 'none'}}/>
          <button className="arrow-button right-arrow2" onClick={handleNextImage}>{">"}</button>
        </div>
      )}
    </div>
  );
};

export default KepPopup;