import React, { useEffect, useRef, useState }from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useBase } from "../../contexts/BaseContext";
import { useLanguage } from "../../contexts/LanguageContext";
import LanguageDropdown from "../../components/LangDropdown";
import HambiMenu from "../../components/HambiMenu";
import GreenKep from "../../img2/crystalkep3.png";
import MagicKep from "../../img2/crystalkep2.png";
import LeafMap from "../../components/LeafMap";
import { Helmet } from "react-helmet";
import { useAnalize } from "../../contexts/AnalizeContext";
import mainkep from "../../img2/hero-3.png";

const IphoneProMax = () => {
  const {openPopup,setIm,openPopup2,toGal,toKap,setToKap,setToGal,setToPri,setToASZF,loading} = useBase();

  const navigate = useNavigate();
    const {langId,setLangId,
           LangMainLux,LangMainTitCont,LangMainTitGal,LangMainTitInf,LangMainTitPri,LangMainTitRes,LangMainTitStart,LangMainMerul1,LangMainMerul2,LangBookNow,LangOurHouses,LangHouseDescription,LangOurHousesDetailed,LangOurDream,LangExperience,LangGallery,LangContact,LangPhone,LangTermsAndConditions,LangPrivacyPolicy,LangImprint,LangAllRightsReserved
           ,LangCryPKitchen,LangCryPAirConditioning,LangCryPJacuzzi,LangCryPParking,LangCryPBreakfast,LangCryPWiFi,LangCryPTV} = useLanguage();
    const galeriaRef = useRef(null);
    const kapRef = useRef(null);
    const fogRef = useRef(null);
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const {trackEvent,trackTiming,updateStatistics} = useAnalize();
    let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiewp1");loaded = true}
    }, []);
    useEffect(() => {
      const start = performance.now();
      window.addEventListener('load', () => {
        const end = performance.now();
        trackTiming('Page Load', 'Main-Phone', 'Initial Page Load',start,end);
      });
    }, []);
    const changeSlug = (slug) => {
      if (slug === "/") {navigate('/');trackEvent("Phone","Navigated","MainPage")}
      else if(slug === "/info") {navigate('/információ');trackEvent("Phone","Navigated","InfoPage")} 
      else if(slug === "/gp") {navigate('/greenpassion');trackEvent("Phone","Navigated","GreenPassion")} 
      else if(slug === "/cp") {navigate('/crystalpower');trackEvent("Phone","Navigated","CrystalPower")} 
      else if(slug === "/mm") {navigate('/magicmango');trackEvent("Phone","Navigated","MagicMango")} 
      else if(slug === "/fog") {fogRef.current.scrollIntoView({ behavior: "smooth" });trackEvent("Phone","Navigated","MainPage-Reservation")}
      else if(slug === "/gal") {galeriaRef.current.scrollIntoView({ behavior: "smooth" });trackEvent("Phone","Navigated","MainPage-Galery")}
      else if(slug === "/kap") {kapRef.current.scrollIntoView({ behavior: "smooth" });trackEvent("Phone","Navigated","MainPage-Contact")}
      else if(slug === "/pri") {navigate('/információ');setTimeout(() => {setToPri(true);}, 200);trackEvent("Phone","Navigated","InfoPage-Prices")}
      else if(slug === "/ASZF") {navigate('/információ');setTimeout(() => {setToASZF(true);}, 50);trackEvent("Phone","Navigated","InfoPage-ASZF")}  
    };
    const openIm = (n) => {
      setIm(n);
      openPopup2();
      trackEvent("Galery","Opened","Image");
      updateStatistics("imageviewed");
    }
    const [showTooltip, setShowTooltip] = useState([false,false,false,false,false,false,false]);
    const [showTooltip2, setShowTooltip2] = useState([false,false,false,false,false,false,false]);
    const [showTooltip3, setShowTooltip3] = useState([false,false,false,false,false,false,false]);

    const containerRef = useRef(null);
  const galeriaSlideRef = useRef(null);
  const kapcsSlideRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry], observer) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target); // Unobserve after animation
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    if (galeriaSlideRef.current) {
      observer.observe(galeriaSlideRef.current);
    }
    if (kapcsSlideRef.current) {
      observer.observe(kapcsSlideRef.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      if (galeriaRef.current) {
        observer.unobserve(galeriaRef.current);
      }
      if (kapcsSlideRef.current) {
        observer.unobserve(kapcsSlideRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      document.querySelector('.herocontentwrap-3').classList.add('animate');
    }
  }, [loading]);

  const handleToolTipClick = (n,t) => {
    if (n === 1 && arraysEqual(showTooltip,[false,false,false,false,false,false,false])) {
      const newShowTooltip = [false,false,false,false,false,false,false];
      newShowTooltip[t-1] = true;
      setShowTooltip(newShowTooltip);
      setTimeout(() => {
        const updatedShowTooltip = [...showTooltip];
        updatedShowTooltip[t-1] = false;
        setShowTooltip(updatedShowTooltip);
      }, 1000); // Hide tooltip after 3 seconds
    }
    else if (n === 2 && arraysEqual(showTooltip2,[false,false,false,false,false,false,false])) {
      const newShowTooltip = [false,false,false,false,false,false,false];
      newShowTooltip[t-1] = true;
      setShowTooltip2(newShowTooltip);
      setTimeout(() => {
        const updatedShowTooltip = [...showTooltip2];
        updatedShowTooltip[t-1] = false;
        setShowTooltip2(updatedShowTooltip);
      }, 1000); // Hide tooltip after 3 seconds
    }
    else if (n === 3 && arraysEqual(showTooltip3,[false,false,false,false,false,false,false])) {
      const newShowTooltip = [false,false,false,false,false,false,false];
      newShowTooltip[t-1] = true;
      setShowTooltip3(newShowTooltip);
      setTimeout(() => {
        const updatedShowTooltip = [...showTooltip3];
        updatedShowTooltip[t-1] = false;
        setShowTooltip3(updatedShowTooltip);
      }, 1000); // Hide tooltip after 3 seconds
    }
    
  };
  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  useEffect(() => {
    if (toGal) {galeriaRef.current.scrollIntoView({ behavior: "smooth" }); setToGal(false)}
  }, [toGal]);
  useEffect(() => {
    if (toKap) {kapRef.current.scrollIntoView({ behavior: "smooth" }); setToKap(false)}
  }, [toKap]);
  useEffect(() => {
    const video = document.querySelector('.video');

    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    };

    video.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      video.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateStatistics("pageTimep1");
    }, 30000);
    
    return () => {
      clearInterval(intervalId); // Clear interval on component unmount
    };
  }, []);
  return ( <>
    <Helmet>
      <title>KurukHill - Főoldal</title>
      <meta name="description" content="Kuruk Hill - Luxus a szőlőben." />
      <meta property="og:image" content="https://www.kurukhill.hu/logo.png" />
      <meta property="og:url" content="https://www.kurukhill.hu" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="KurukHill - Főoldal" />
      <meta name="twitter:description" content="Kuruk Hill - Luxus a szőlőben." />
      <meta name="twitter:image" content="https://www.kurukhill.hu/logo.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32-black.png" />
      <link rel="canonical" href="https://www.kurukhill.hu" />
    </Helmet>
    <div className="iphone-pro-max">
      <div className="div-45">
        <div className="herocontainer">
          <div className="hero-8">
          <img loading="lazy" alt="Loading" src={mainkep} className="Change-Image-Info" style={{height:'100vh',width:"100vw"}}/>
          <img loading="lazy" alt="Loading" src="/img3/klogo3.png" className="logo-7" onClick={() => changeSlug("/")}/>
          </div>
          <div className="herocontentwrap-3">
            <div className="kuruk-hill-head-9">Kuruk Hill</div>
            <div className="text-wrapper-102">{LangMainLux()}</div>
            <p className="mer-ljetek-el-a-k-2">
                {LangMainMerul1()}
                <br />
                {LangMainMerul2()}
            </p>
            <div className="gomb-4" onClick={() => changeSlug("/fog")}>
              <div className="text-wrapper-103" >{LangBookNow()}</div>
            </div>
          </div>
          <HambiMenu changeSlug={changeSlug} t={"5px"} r={"10px"}/>
        </div>
        <div className="hazainkwrap-2">
          <div className="hazainkheadingwrap" ref={containerRef}>
            <div className="hazainkhead">{LangOurHouses()}</div>
            <div className="headingvonal" />
          </div>
          <div className="hazainkcontentwrap-2">
            <p className="szoveg-6">
              {LangHouseDescription()}
            </p>
            <p className="szoveg-7">
              {LangOurHousesDetailed()}
            </p>
            <p className="szoveg-7">
              {LangOurDream()}
            </p>
            <p className="szoveg-8">
              {LangExperience()}
            </p>
          </div>
          <div className="szolodiszdiv">
            <div className="diszvonal-2" />
            <div className="szolo-2" />
            <div className="diszvonal-2" />
          </div>
        </div>
        <div ref={fogRef} className="hazakcontainer-2">
          <div className="div-47">
            <div className="div-48-mm" onClick={() => changeSlug("/mm")}/>
            <div className="div-wrapper-5">
              <div className="div-49">
                <div className="text-wrapper-104">Magic Mango</div>
                <div className="ferohely-9">
                  <div className="text-wrapper-105">2</div>
                  <img loading="lazy" className="icon-user-9" alt="Icon user" src="/img/icon-user-3.png" />
                </div>
              </div>
            </div>
            <div className="szimbolumokholder" style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width: "100%"}}>
              <div className="szimbolumoks" style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width: "70%"}}>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Konyhaikon" src="/img/konyhaikon.svg" onClick={()=>handleToolTipClick(1,1)}/>
                      {showTooltip[0] && <div className="tooltips">{LangCryPKitchen()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Legkondiikon" src="/img/legkondiikon.svg" onClick={()=>handleToolTipClick(1,2)}/>
                      {showTooltip[1] && <div className="tooltips">{LangCryPAirConditioning()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Jacuzziikon" src="/img/jacuzziikon.svg" onClick={()=>handleToolTipClick(1,3)}/>
                      {showTooltip[2] && <div className="tooltips">{LangCryPJacuzzi()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Parkolasikon" src="/img/parkolasikon.svg" onClick={()=>handleToolTipClick(1,4)}/>
                      {showTooltip[3] && <div className="tooltips">{LangCryPParking()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Reggeliikon" src="/img/reggeliikon.svg" onClick={()=>handleToolTipClick(1,5)}/>
                      {showTooltip[4] && <div className="tooltips">{LangCryPBreakfast()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Wifiikon" src="/img/wifiikon.svg" onClick={()=>handleToolTipClick(1,6)}/>
                      {showTooltip[5] &&  <div className="tooltips">{LangCryPWiFi()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Tvikon" src="/img/tvikon.svg" onClick={()=>handleToolTipClick(1,7)}/>
                      {showTooltip[6] && <div className="tooltips">{LangCryPTV()}</div>}
                    </div>
                  </div>
                  <img loading="lazy" className="frame-5" alt="Frame" src="/img/frame-3-1.svg" onClick={() => changeSlug("/mm")} style={{width: "15%"}}/>
                </div>
          </div>
          <div className="div-47">
            <div className="div-48" onClick={() => changeSlug("/cp")}/>
            <div className="div-wrapper-5">
              <div className="div-49">
                <div className="text-wrapper-104">Crystal Power</div>
                <div className="ferohely-9">
                  <div className="text-wrapper-105">2</div>
                  <img loading="lazy" className="icon-user-9" alt="Icon user" src="/img/icon-user-3.png" />
                </div>
              </div>
            </div>
            <div className="szimbolumokholder" style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width: "100%"}}>
              <div className="szimbolumoks" style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width: "70%"}}>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Konyhaikon" src="/img/konyhaikon.svg" onClick={()=>handleToolTipClick(2,1)}/>
                      {showTooltip2[0] && <div className="tooltips">{LangCryPKitchen()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Legkondiikon" src="/img/legkondiikon.svg" onClick={()=>handleToolTipClick(2,2)}/>
                      {showTooltip2[1] && <div className="tooltips">{LangCryPAirConditioning()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Jacuzziikon" src="/img/jacuzziikon.svg" onClick={()=>handleToolTipClick(2,3)}/>
                      {showTooltip2[2] && <div className="tooltips">{LangCryPJacuzzi()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Parkolasikon" src="/img/parkolasikon.svg" onClick={()=>handleToolTipClick(2,4)}/>
                      {showTooltip2[3] && <div className="tooltips">{LangCryPParking()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Reggeliikon" src="/img/reggeliikon.svg" onClick={()=>handleToolTipClick(2,5)}/>
                      {showTooltip2[4] && <div className="tooltips">{LangCryPBreakfast()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Wifiikon" src="/img/wifiikon.svg" onClick={()=>handleToolTipClick(2,6)}/>
                      {showTooltip2[5] &&  <div className="tooltips">{LangCryPWiFi()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Tvikon" src="/img/tvikon.svg" onClick={()=>handleToolTipClick(2,7)}/>
                      {showTooltip2[6] && <div className="tooltips">{LangCryPTV()}</div>}
                    </div>
                  </div>
                  <img loading="lazy" className="frame-5" alt="Frame" src="/img/frame-3-1.svg" onClick={() => changeSlug("/cp")} style={{width: "15%"}}/>
                </div>
          </div>
          <div className="div-47">
            <div className="div-48-gp" onClick={() => changeSlug("/gp")}/>
            <div className="div-wrapper-5">
              <div className="div-49">
                <div className="text-wrapper-104">Green Passion</div>
                <div className="ferohely-9">
                  <div className="text-wrapper-105">2</div>
                  <img loading="lazy" className="icon-user-9" alt="Icon user" src="/img/icon-user-3.png" />
                </div>
              </div>
            </div>
            <div className="szimbolumokholder" style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width: "100%"}}>
              <div className="szimbolumoks" style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width: "70%"}}>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Konyhaikon" src="/img/konyhaikon.svg" onClick={()=>handleToolTipClick(3,1)}/>
                      {showTooltip3[0] && <div className="tooltips">{LangCryPKitchen()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Legkondiikon" src="/img/legkondiikon.svg" onClick={()=>handleToolTipClick(3,2)}/>
                      {showTooltip3[1] && <div className="tooltips">{LangCryPAirConditioning()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Jacuzziikon" src="/img/jacuzziikon.svg" onClick={()=>handleToolTipClick(3,3)}/>
                      {showTooltip3[2] && <div className="tooltips">{LangCryPJacuzzi()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Parkolasikon" src="/img/parkolasikon.svg" onClick={()=>handleToolTipClick(3,4)}/>
                      {showTooltip3[3] && <div className="tooltips">{LangCryPParking()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Reggeliikon" src="/img/reggeliikon.svg" onClick={()=>handleToolTipClick(3,5)}/>
                      {showTooltip3[4] && <div className="tooltips">{LangCryPBreakfast()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Wifiikon" src="/img/wifiikon.svg" onClick={()=>handleToolTipClick(3,6)}/>
                      {showTooltip3[5] &&  <div className="tooltips">{LangCryPWiFi()}</div>}
                    </div>
                    <div className="icon-wrapper">
                      <img loading="lazy" className="img-2" alt="Tvikon" src="/img/tvikon.svg" onClick={()=>handleToolTipClick(3,7)}/>
                      {showTooltip3[6] && <div className="tooltips">{LangCryPTV()}</div>}
                    </div>
                  </div>
                  <img loading="lazy" className="frame-5" alt="Frame" src="/img/frame-3-1.svg" onClick={() => changeSlug("/gp")} style={{width: "15%"}}/>
                </div>
          </div>
        </div>
        <div ref={galeriaRef} className="galeriawrap-4">
          <div className="kepsorozat-2">
            <div className="kep-15" onClick={() => openIm(1)}/>
            <div className="kep-16" onClick={() => openIm(2)}/>
          </div>
          <div className="kepsorozat-3">
            <div className="kep-17" onClick={() => openIm(3)}/>
            <div className="kep-18" onClick={() => openIm(4)}/>
          </div>
          <div className="kepsorozat-4">
            <div className="kep-19" onClick={() => openIm(5)}/>
            <div className="kep-20" onClick={() => openIm(6)}/>
          </div>
          <div className="kepsorozat-5">
            <div className="kep-21" onClick={() => openIm(7)}/>
            <div className="kep-22" onClick={() => openIm(8)}/>
          </div>
          <div className="kepsorozat-6">
            <div className="kep-23" onClick={() => openIm(9)}/>
            <div className="kep-24" onClick={() => openIm(10)}/>
          </div>
          <div className="kepsorozat-7">
            <div className="kep-25" onClick={() => openIm(11)}/>
            <div className="kep-26" onClick={() => openIm(12)}/>
          </div>
          <div className="kepsorozat-8">
            <div className="kep-27" onClick={() => openIm(13)}/>
            <div className="kep-28" onClick={() => openIm(14)}/>
          </div>
          <div className="videodiv">
              <video className="video" autoPlay loop muted playsInline style={{height:'160%',width:'100%',marginTop:'23%'}}>
                <source src="/vids/kvid2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
          </div>
          <div className="galeriaheadwrap" ref={galeriaSlideRef}>
            <div className="div-50" />
            <div className="text-wrapper-106">{LangGallery()}</div>
          </div>
        </div>
        <div ref={kapRef} className="footerwrap-2">
          <div className="text-wrapper-108">Kuruk Hill</div>
          <p className="text-wrapper-109">
          {LangPhone()}: <a href={`tel:${"+36 70 942 0610"}`} style={{zIndex:'100',color: 'inherit', textDecoration: 'none'}}>+36 70 942 0610</a>
          </p>
          <p className="text-wrapper-110">
                    <span className="span">E-mail: </span>
                    <a href="mailto:info@kurukhill.hu" rel="noopener noreferrer" target="_blank" className="custom-link">
                      <span className="text-wrapper-10">info@kurukhill.hu</span>
                    </a>
                  </p>
          <p className="element-zalakros-szent-2">
            8749 Zalakaros
            <br />
            Szent Orbán utca 42.
          </p>
          <div className="div-51">
            <div className="text-wrapper-111" onClick={() => changeSlug("/ASZF")}>{LangTermsAndConditions()}</div>
            <a href="/docs/Adatvedelmi_Tajekoztato_Kurukhill.docx" download="Adatvedelmi_Tajekoztato_Kurukhill.docx"><div className="text-wrapper-112">{LangPrivacyPolicy()}</div></a>
            <a href="/docs/Impresszum.docx" download="Impresszum.docx"><div className="text-wrapper-112">{LangImprint()}</div></a>
          </div>
          <div className="licensz-wrapper">
            <p className="licensz-2">
              <span className="text-wrapper-113">©2023 </span>
              <a href="https://gmamedia.co/" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-113">GMA Media|</span>
              </a>
              <span className="text-wrapper-113"> {LangAllRightsReserved()}</span>
            </p>
          </div>
          <div className="socialmediadiv" style={{width:'300px'}} >
                    <a href="https://www.instagram.com/kuruk_hill?igsh=a3JuazRvd28yY3B6" className="w-inline-block" style={{fontSize:"0px"}}>insta<img alt="Loading" src="/img3/insta.png" href="https://www.instagram.com/" style={{height: '35px',transform:'translateY(-7px)'}}/></a>
                    <a href="https://www.facebook.com/kurukhill" className="w-inline-block" style={{fontSize:"0px"}}>facebook<img alt="Loading" src="/img3/facebook.png" href="https://www.facebook.com/" style={{height: '50px'}}/></a>
                    <a href="https://www.tiktok.com/@kuruk.hill?_t=8ndkj9hINhQ&_r=1" className="w-inline-block" style={{fontSize:"0px"}}>tiktok<img alt="Loading" src="/img3/tiktok.png" href="https://www.tiktok.com/" style={{height: '51px',transform:'translateY(1px)'}}/></a>
                  </div>
          <div className="terkepwrap">
            <LeafMap/>
          </div>
          <div className="kartyakdiv">
            <img loading="lazy" className="kartyakslatt" alt="Kartyakslatt" src="/img/kartyak.png" />
          </div>
          <div className="kapcsolatwrap-3" ref={kapcsSlideRef}>
            <div className="div-50" />
            <div className="text-wrapper-114">{LangContact()}</div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default IphoneProMax;