import React, { useEffect, useState} from "react";
import './style.css';
import { useAnalize } from "../contexts/AnalizeContext";
import { useBase } from "../contexts/BaseContext";


const LoadingScreen = () => {
    const {loading,setLoading} = useBase();
    const [bg, setBG] = useState("#5d7329");
    const {updateStatistics} = useAnalize();
    let loaded = false;
  
    useEffect(() => {
        if(!loaded) {updateStatistics("wiew");loaded = true}
        const timer = setTimeout(() => changeBack(), 3000); // Simulate a loading time
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
          updateStatistics("pageTime");
        }, 30000);
        
        return () => {
          clearInterval(intervalId); // Clear interval on component unmount
        };
      }, []);

      useEffect(() => {
        const handleClick = () => {
            updateStatistics("clicks");
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [updateStatistics]);

    const changeBack = () => {
        setBG("transparent");
        const timer = setTimeout(() => setLoading(false), 1000); // Simulate a loading time
        return () => clearTimeout(timer);
    }
  
    if (!loading) return null;
  
    return (
        <div>
            {loading && <div className="loading-screen" style={{backgroundColor:bg}}>
                <div className="logo-container">
                    {bg === "#5d7329" && <img loading="lazy" src="/img3/klogo3.png" alt="Logo" className="pulsing-logo" />}
                </div>
            </div> }
        </div>
    );
  };
  
  export default LoadingScreen;
