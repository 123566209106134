import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useAnalize } from '../contexts/AnalizeContext';

const LanguageDropdown = () => {
  const {langId, setLangId,isOpen, setIsOpen} = useLanguage();
  const {trackEvent} = useAnalize();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    trackEvent("Language","Toggled","Lang");
  };

  const handleLanguageChange = (id) => {
    setLangId(id);
    setIsOpen(false);
    trackEvent("Language","Changed To",id);
  };

  return (
    <div className="language-dropdown">
      {!isOpen && <div className="dropdown-toggle" onClick={toggleDropdown}>
        {langId === "HU" && <img loading="lazy" className="img" alt="Magyarzaszlo" src="/img/magyarzaszlo.svg" />}
        {langId === "DE" && <img loading="lazy" className="img" alt="Nemetflag" src="/img/nemetflag.svg" />}
        {langId === "EN" && <img loading="lazy" className="img" alt="Angolflag" src="/img/angolflag.svg" />}
        {langId === "HU" && " HU"}
        {langId === "DE" && " DE"}
        {langId === "EN" && " EN"}
      </div>}
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => handleLanguageChange("HU")}>
            <img loading="lazy" className="img" alt="Magyarzaszlo" src="/img/magyarzaszlo.svg" />
            HU
          </div>
          <div className="dropdown-item" onClick={() => handleLanguageChange("DE")}>
            <img loading="lazy" className="img" alt="Nemetflag" src="/img/nemetflag.svg" />
            DE
          </div>
          <div className="dropdown-item" onClick={() => handleLanguageChange("EN")}>
            <img loading="lazy" className="img" alt="Angolflag" src="/img/angolflag.svg" />
            EN
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;