// AdminLoginPage.js
import React, { useState } from 'react';
import { useAdmin } from './AdminContext';
import './Admin.css';
import AdminPage from './AdminPage';

const AdminLoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login,isLoggedIn } = useAdmin();

    const handleSubmit = (e) => {
        e.preventDefault();
        
        login(username,password);
    };

    return (
        <div>
            {isLoggedIn ? 
            <AdminPage/>
            :
            <div className="login-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>Admin Login</h2>
                    <div className="input-group">
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>}
        </div>
    );
};

export default AdminLoginPage;